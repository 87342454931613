import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, Snackbar, Tooltip, FormControl, InputLabel, MenuItem, Select, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { useService } from 'contexts/ServiceContextProvider';

import NewSecretModal from 'components/Modals/CreateNewSecret';
import DeleteSecretModal from '../components/Modals/DeleteSecret';
import DeleteStorageModal from '../components/Modals/DeleteStorage';
import DeleteResourceModal from '../components/Modals/DeleteResource';
import NewResourceModal from '../components/Modals/CreateNewResource';
import TextEditor from "../components/Custom/TextEditor";
import {useTranslation} from "react-i18next";

const ProjectDetails = () => {
    const { t } = useTranslation();

    const { UpdateProjectStatus, getProjectStatus, GetBoardDetails, getLoggedUserPermissions, UpdateImportantInformation, AddASecret, DeleteSecret, AddAResource, DeleteResource, AddFileToStorage, DeleteStorageFile } = useService();

    const [myBoardDetails, setMyBoardDetails] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { getLoggedUser } = useAuthorization();
    const loggedUserID = getLoggedUser();
    const workspaceContext = useWorkspaces();
    const [avoidMisfire, setAvoidMisfire] = useState(false);
    const myWorkspaces = workspaceContext.workspaces;

    const [openSecretModal, setOpenSecretModal] = useState(false);
    const [openResourceModal, setOpenResourceModal] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const [editedInformation, setEditedInformation] = useState('');
    const [, setOriginalInformation] = useState('');

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [secretToDelete, setSecretToDelete] = useState(null);

    const [, setSelectedFile] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState(null);

    const [openStorageModal, setOpenStorageModal] = useState(false);
    const [storageFileToDelete, setStorageFileToDelete] = useState(null);

    const [openResourceDeleteModal, setOpenResourceDeleteModal] = useState(false);
    const [resourceToDelete, setResourceToDelete] = useState(null);
    const [accessType, setAccessType] = useState(0);

    const [status, setStatus] = useState('');
    const [visibility, setVisibility] = useState(true);
    const [projectID, setProjectID] = useState(true);

    const [secretVisibility, setSecretVisibility] = useState({});


    const getFileType = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        const fileExtensionMapping = {
            'pdf': 'Document',
            'doc': 'Document',
            'docx': 'Document',
            'xls': 'Document',
            'xlsx': 'Document',
            'ppt': 'Document',
            'pptx': 'Document',
            'txt': 'Document',
            'jpg': 'Image',
            'jpeg': 'Image',
            'png': 'Image',
            '7z': 'Archive',
            'rar': 'Archive',
            'zip': 'Archive',
        };
        return fileExtensionMapping[extension] || 'Other';
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        event.target.value = ''; // Reset file input field value

        // Check if file size is within limit (5MB)
        if (file && file.size <= 5 * 1024 * 1024) {
            setSelectedFile(file);
            const response = await AddFileToStorage(myBoardDetails.id, file);

            if ( response?.status === 201) {

                const newFile = { "id": response.data.id, "file": "/media" + response.data.file.split('/media')[1], "filename": response.data.file.split('/').pop(), "file_type": getFileType(response.data.file.split('/').pop()) }

                const updatedBoardDetails = { ...myBoardDetails };

                // Add the newFile to the storage list in the updated board details
                updatedBoardDetails.storage.push(newFile);

                // Now updatedBoardDetails contains the new file in the storage list
                setMyBoardDetails(updatedBoardDetails);
                setEditedInformation(myBoardDetails.important_information);

                setSnackbarMessage(t("project_details.file_uploaded_success"));
            } else {
                setSnackbarMessage(t("project_details.file_uploaded_err"));
            }
        } else {
            setSelectedFile(null);
            setSnackbarMessage(t("project_details.file_uploaded_exceed"));
        }
        setSnackbarOpen(true);
    };

    const handleOpenResourceDeleteModal = (resourceID) => {
        setResourceToDelete(resourceID);
        setOpenResourceDeleteModal(true);
    };

    const handleCloseResourceDeleteModal = () => {
        setResourceToDelete(null);
        setOpenResourceDeleteModal(false);
    };

    const handleOpenStorageModal = (fileID) => {
        setStorageFileToDelete(fileID);
        setOpenStorageModal(true);
    };

    const handleCloseStorageModal = () => {
        setStorageFileToDelete(null);
        setOpenStorageModal(false);
    };

    const handleOpenConfirmationModal = (secretID) => {
        setSecretToDelete(secretID);
        setOpenConfirmationModal(true);
    };

    const handleCloseConfirmationModal = () => {
        setSecretToDelete(null);
        setOpenConfirmationModal(false);
    };

    const handleEditClick = () => {
        setOriginalInformation(myBoardDetails.important_information);
        setEditedInformation(myBoardDetails.important_information);
        setEditMode(!editMode);
    };

    const handleSaveClick = (json) => {
        // Save the edited information
        // For demonstration purposes, let's just log it
        myBoardDetails.important_information = json;
        UpdateImportantInformation(myBoardDetails.id, json);
        // Exit edit mode
        setEditMode(false);
    };

    const handleResourceModalOpen = () => {
        setOpenResourceModal(true);
    };

    const handleResourceModalClose = () => {
        setOpenResourceModal(false);
    };

    const handleSecretModalOpen = () => {
        setOpenSecretModal(true);
    };

    const handleSecretModalClose = () => {
        setOpenSecretModal(false);
    };

    const handleCreateResource = async (title, hyperlink) => {
        setAvoidMisfire(true);
        try {
            const response = await AddAResource(myBoardDetails.id, title, hyperlink);
            if (response) {

                const newResource = response.data;
                const updatedResources = [...myBoardDetails.resources, newResource];
                setMyBoardDetails({ ...myBoardDetails, resources: updatedResources });
            }

            handleResourceModalClose();
        } catch (error) {
            console.error('Creating resource Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleResourceModalClose();
    };

    const handleDeleteResource = async () => {
        setAvoidMisfire(true);
        try {
            const response = DeleteResource(myBoardDetails.id, resourceToDelete);
            if (response) {
                const updatedResources = myBoardDetails.resources.filter(resource => resource.id !== resourceToDelete);
                setMyBoardDetails({ ...myBoardDetails, resources: updatedResources });
            } else {
                console.error("Failed to delete storage file.");
            }
        } catch (error) {
            console.error('Deleting resource Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleCloseResourceDeleteModal();
    };
    const handleCreateSecret = async (title, secret, visibility) => {
        setAvoidMisfire(true);
        try {
            const response = await AddASecret(myBoardDetails.id, title, secret, visibility);
            const newSecret = { "id": response.data.id, "title": title, "secret": secret, "visibility": visibility }
            const updatedSecrets = [...myBoardDetails.secret_section, newSecret];
            setMyBoardDetails({ ...myBoardDetails, secret_section: updatedSecrets });

        } catch (error) {
            console.error('Creating secret Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleSecretModalClose();
    };

    const handleDeleteSecret = async () => {
        setAvoidMisfire(true);
        try {
            const response = DeleteSecret(myBoardDetails.id, secretToDelete);
            if (response) {
                const updatedSecret = myBoardDetails.secret_section.filter(secret => secret.id !== secretToDelete);
                setMyBoardDetails({ ...myBoardDetails, secret_section: updatedSecret });
            } else {
                console.error("Failed to delete storage file.");
            }
        } catch (error) {
            console.error('Deleting secret Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleCloseConfirmationModal();
    };

    const handleDeleteStorage = async () => {
        setAvoidMisfire(true);
        try {
            const response = DeleteStorageFile(myBoardDetails.id, storageFileToDelete);
            if (response) {
                const updatedStorage = myBoardDetails.storage.filter(storage => storage.id !== storageFileToDelete);
                setMyBoardDetails({ ...myBoardDetails, storage: updatedStorage });
            } else {
                console.error("Failed to delete storage file.");
            }
        } catch (error) {
            console.error('Deleting file Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000); // Adjust the delay time as needed
        }
        handleCloseStorageModal();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        async function fetchData() {
            if (myWorkspaces[0]) {
                const userPerms = await getLoggedUserPermissions(myWorkspaces[0].id, loggedUserID);
                if (userPerms.is_owner) {
                    setAccessType(2)
                } else if (userPerms.is_admin || myBoardDetails.project_owner === loggedUserID) {
                    setAccessType(1)
                }
            }
        }

        fetchData();
    }, [myWorkspaces, loggedUserID]);

    useEffect(() => {
        async function fetchData() {
            const currentPath = window.location.pathname;
            const pathParts = currentPath.split('/');
            const projectId = pathParts[pathParts.length - 2];
            setProjectID(projectId);

            const [boardDetail] = await GetBoardDetails(projectId);
            setMyBoardDetails(boardDetail);
            setEditedInformation(boardDetail.important_information);
            setOriginalInformation(boardDetail.important_information);
     

            const projectStatus = await getProjectStatus(projectId);
            setStatus(projectStatus.data.status);
            setVisibility(projectStatus.data.visibility);
        }

        fetchData();
    }, []);

    // Function to handle copying the secret to the clipboard and show Snackbar
    const copyToClipboard = (secret) => {
        setSnackbarMessage(t("project_details.copy_secret"))
        navigator.clipboard.writeText(secret);
        setSnackbarOpen(true);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleVisibilityChange = (event) => {
        setVisibility(event.target.value === 'true');
    };

    const handleSaveStatusVisibility = async () => {
        try {
            await UpdateProjectStatus(projectID, { status, visibility });
            setSnackbarMessage(t("project_details.status_visibily_update"));
        } catch (error) {
            setSnackbarMessage(t("project_details.status_visibily_update_fail"));
        } finally {
            setSnackbarOpen(true);
        }
    };

    const toggleSecretVisibility = (id) => {
        setSecretVisibility(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleDescription = async (json) => {
        try {
            setEditedInformation(json);
            handleSaveClick(json);
        } catch (error) {
            console.error("Error updating task description:", error);
        }
    };

    return (
        <>
            <div style={{ display: "flex", direction: "row", alignItems: "center", justifyContent: "center", marginBottom: '20px', gap: '20px' }}>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel
                        sx={{
                            color: '#FFF',
                            '&.Mui-focused': {
                                color: '#FFF',
                            }
                        }}
                    >{t('project.status')}</InputLabel>
                    <Select
                        sx={{
                            color: '#000',
                            width: '200px',
                            bgcolor: status === 'completed' ? '#228B22' : status === 'ongoing' ? '#1E90FF' : status === 'archived' ? '#A3A3A3' : '#FF8C00',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'black',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'black',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'black',
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'black',
                            }
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    bgcolor: '#333',
                                    '& .MuiMenuItem-root': {
                                        color: '#FFF',
                                    },
                                },
                            },
                        }}
                        value={status}
                        onChange={handleStatusChange}
                        disabled={accessType < 1}
                    >
                        <MenuItem value="not_started">{t('project.stat_notstart')}</MenuItem>
                        <MenuItem value="ongoing">{t('project.stat_ongoing')}</MenuItem>
                        <MenuItem value="completed">{t('project.stat_completed')}</MenuItem>
                        <MenuItem value="archived">Archived</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel
                        sx={{
                            color: '#FFF',
                            '&.Mui-focused': {
                                color: '#FFF',
                            }
                        }}
                    >{t('project_details.visibility')}</InputLabel>
                    <Select
                        sx={{
                            color: '#000',
                            width: '200px',
                            bgcolor: visibility.toString() === 'true' ? '#90EE90' : '#FFB6C1',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'black',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'black',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'black',
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'black',
                            }
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    bgcolor: '#333',
                                    '& .MuiMenuItem-root': {
                                        color: '#FFF',
                                    },
                                },
                            },
                        }}
                        value={visibility.toString()}
                        onChange={handleVisibilityChange}
                        disabled={accessType < 1}
                    >
                        <MenuItem value="true">{t('project_details.public')}</MenuItem>
                        <MenuItem value="false">{t('project_details.private')}</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleSaveStatusVisibility} disabled={accessType < 1} sx={{ height: '56px', bgcolor: '#333' }}>
                    {t('project_details.save_stat_vis')}
                </Button>
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', gap: '20px', padding: '20px' }}>

                {/* Resource Card */}
                <Card sx={{ backgroundColor: '#252224' }}>
                    <CardContent>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h5" component="div" gutterBottom>
                                {t('project_details.resources')}
                            </Typography>
                            <Button onClick={() => handleResourceModalOpen()} style={{ color: 'burlywood' }}>
                                {t('project_details.add_resource')} <AddIcon fontSize='large' sx={{ color: "#D6D4D4" }} />
                            </Button>
                        </div>
                        <Typography variant="body2" sx={{ marginBottom: "10px" }}>
                            {t('project_details.resource_info')}
                        </Typography>
                        <div>
                            <ul>
                                {myBoardDetails && myBoardDetails.resources &&
                                    myBoardDetails.resources.map((resource) => (
                                        <li key={resource.id}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <a href={resource.hyperlink} target={"_blank"}>{resource.title}</a>
                                                <IconButton sx={{ marginRight: 0, marginLeft: "auto" }} color="error" onClick={() => handleOpenResourceDeleteModal(resource.id)} disabled={accessType === 0}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </CardContent>
                </Card>

                {/* Important Information Card */}
                <Card sx={{ backgroundColor: '#252224' }}>
                    <CardContent style={{ color:"white" }}>
                        <Typography variant="h5" component="div" gutterBottom>
                            {t('project_details.important_info')}
                        </Typography>
                        {editMode ? (
                            <div key={"editable"}>
                                <TextEditor
                                    handleDescription={handleDescription}
                                    handleCancel={handleEditClick}
                                    value={editedInformation}
                                    editable={true}
                                />
                            </div>


                        ) : (
                            <div key={"read_only"}>
                                <TextEditor
                                    handleDescription={handleDescription}
                                    handleCancel={handleEditClick}
                                    value={myBoardDetails.important_information}
                                    editable={false}
                                />
                            </div>


                        )}
                        {!editMode && (
                            <Button size="large" onClick={handleEditClick}>{t('project_details.edit_info')}</Button>
                        )}
                    </CardContent>
                </Card>

                {/* Password/Secret Section Card */}
                <Card sx={{ backgroundColor: '#252224' }}>
                    <CardContent>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h5" component="div" gutterBottom>
                                {t('project_details.password_sect')}
                            </Typography>
                            <Button onClick={() => handleSecretModalOpen()} style={{ color: 'burlywood' }}>
                                {t('project_details.add_secret')} <AddIcon fontSize='large' sx={{ color: "#D6D4D4" }} />
                            </Button>
                        </div>
                        <Typography variant="body2" sx={{ marginBottom: "10px" }}>
                            {t('project_details.secret_info')}
                        </Typography>
                        <div>
                            <ul>
                                {myBoardDetails && myBoardDetails.secret_section && accessType !== undefined &&
                                    myBoardDetails.secret_section.map((secret) => {

                                        const hasPermission = accessType === 2 ||
                                            (accessType === 1 && (secret.type_of_visibility === "admin" || secret.type_of_visibility === "user")) ||
                                            (accessType === 0 && secret.type_of_visibility === "user");

                                        return (
                                            <li key={secret.id}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ color: "white", cursor: hasPermission ? "pointer" : "default" }} onClick={hasPermission ? () => copyToClipboard(secret.secret) : null}>
                                                        {secret.title} : {hasPermission && secretVisibility[secret.id] ? <span style={{ color: "#5e72e4" }}>{secret.secret}</span> : "********"}
                                                    </span>
                                                    {hasPermission && (
                                                        <IconButton sx={{ marginRight: 0, marginLeft: "auto", color: "white"  }}  onClick={() => toggleSecretVisibility(secret.id)}>
                                                            {secretVisibility[secret.id] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                        </IconButton>
                                                    )}
                                                    <Tooltip
                                                        title={!hasPermission ? t('project_details.secrets_permission') : ""}
                                                    >
                                                        <span>
                                                            <IconButton sx={{ marginRight: 0, marginLeft: "auto" }} color={hasPermission ? "error" : "disabled"} onClick={() => handleOpenConfirmationModal(secret.id)} disabled={!hasPermission}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </li>
                                        );
                                    })}
                            </ul>

                            {/* Snackbar for displaying copy success notification */}
                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={snackbarOpen}
                                autoHideDuration={3000}
                                onClose={() => setSnackbarOpen(false)}
                                message={snackbarMessage}
                                sx={{ '& .MuiSnackbarContent-root': { backgroundColor: 'white', color: 'black' } }} />
                        </div>
                    </CardContent>
                </Card>

                {/* Cloud Storage for Files Card */}
                <Card sx={{ backgroundColor: '#252224' }}>
                    <CardContent>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h5" component="div" gutterBottom>
                                {t('project_details.cloud_storage')}
                            </Typography>
                            <div>
                                <label htmlFor="file-upload">
                                    <Button component="span" style={{ color: 'burlywood' }}>
                                        {t('project_details.add_file')} <AddIcon fontSize='large' sx={{ color: "#D6D4D4" }} />
                                    </Button>
                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.jpg,.jpeg,.png,.zip,.7z,.rar"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }} />
                            </div>
                        </div>
                        <Typography variant="body2" sx={{ marginBottom: "10px" }}>
                            {t('project_details.files_info')}
                        </Typography>
                        <div>
                            <ul>
                                {myBoardDetails && myBoardDetails.storage &&
                                    (myBoardDetails.storage.map((file) => (
                                        <li key={file.id}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Typography>  {file.filename} : <a href={process.env.REACT_APP_BACKEND_URL + file.file}>{file.file_type}</a></Typography>
                                                <Tooltip
                                                    title={accessType === 0 ? t('project_details.files_permission') : ""}
                                                >
                                                    <span style={{ marginRight: 0, marginLeft: "auto" }}>
                                                        <IconButton
                                                            color={accessType !== 0 ? "error" : "disabled"}
                                                            onClick={() => handleOpenStorageModal(file.id)}
                                                            disabled={accessType === 0}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        </li>
                                    )))}
                            </ul>
                        </div>
                    </CardContent>
                </Card>
                <DeleteSecretModal openConfirmationModal={openConfirmationModal} handleCloseConfirmationModal={handleCloseConfirmationModal} handleDeleteSecret={handleDeleteSecret} avoidMisfire={avoidMisfire} />
                <DeleteStorageModal openStorageModal={openStorageModal} handleCloseStorageModal={handleCloseStorageModal} handleDeleteStorage={handleDeleteStorage} avoidMisfire={avoidMisfire} />
                <DeleteResourceModal openResourceDeleteModal={openResourceDeleteModal} handleCloseResourceDeleteModal={handleCloseResourceDeleteModal} handleDeleteResource={handleDeleteResource} avoidMisfire={avoidMisfire} />
                <NewSecretModal openSecretModal={openSecretModal} handleSecretModalClose={handleSecretModalClose} handleCreateSecret={handleCreateSecret} accessType={accessType} avoidMisfire={avoidMisfire} />
                <NewResourceModal openResourceModal={openResourceModal} handleResourceModalClose={handleResourceModalClose} handleCreateResource={handleCreateResource} avoidMisfire={avoidMisfire} />
            </div>
        </>
    );
};

export default ProjectDetails;
