import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const CustomCookieConsent = () => {
  const { t } = useTranslation();
  return (
    <CookieConsent
      location="top"
      buttonText={t("login.ok")}
      cookieName="workflowCookie"
      style={{ 
        background: "black",
        textAlign: "center",
        fontSize: "13px",
        fontWeight: "normal",
        fontFamily: "Inter, sans-serif",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
      buttonStyle={{
        color: "#4e503b", 
        fontSize: "13px",
        fontFamily: "Inter, sans-serif",
        alignSelf: "center",
      }}
      expires={750}
      containerClasses="custom-cookie-consent-container"
    >
      {t("login.consent")}
    </CookieConsent>
  );
};

export default CustomCookieConsent;
