import React, { useState, useEffect } from 'react';
import {
    Box,
    IconButton,
    Typography,
    Avatar,
    Paper,
    Divider,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useService } from 'contexts/ServiceContextProvider';
import { useAuthorization } from 'contexts/AuthContextProvider';
import { format } from 'date-fns';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { MentionsInput, Mention } from 'react-mentions';


const mentionStyle = {
    control: {
        fontSize: 14,
        padding: '12px',
        backgroundColor: '#1e1e1e',
        borderRadius: '4px',
        color: '#FFF',
        minWidth: '600px',
        minHeight: '60px',
    },
    highlighter: {
        overflow: 'hidden',
    },
    input: {
        margin: 0,
        color: '#FFF',
        minHeight: '60px',
        padding: '10px',
    },
    suggestions: {
        container: {
            position: 'relative',
        },
        list: {
            backgroundColor: '#333',
            border: '1px solid #222',
            fontSize: 14,
            color: 'white',
            position: 'absolute',
            top: '20px', 
        },
        item: {
            padding: '5px 15px',
            borderBottom: '1px solid #222',
            color: 'white',
            '&focused': {
                backgroundColor: '#222',
                color: 'white',
            },
        },
    },
};

const renderCommentWithMentions = (text) => {
    const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g; 

    return text.replace(mentionRegex, (match, display, id) => {
        return `<a href="/workflow/profile/${id}">@${display}</a>`; 
    });
};

export default function Comments({ task_id }) {
    const { GetTaskComments, PostTaskComment, getWorkspaceWorkersForComments } = useService();
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [replyComment, setReplyComment] = useState({});
    const { getLoggedUser } = useAuthorization();
    const [workspaceWorkers, setWorkspaceWorkers] = useState([]);

    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;

    useEffect(() => {
        const fetchWorkspaceWorkers = async () => {
            const workspaceWorkersData = await getWorkspaceWorkersForComments(myWorkspaces[0].id);
            setWorkspaceWorkers(workspaceWorkersData);
            //console.log(workspaceWorkersData)
        };

        fetchWorkspaceWorkers();
    }, [task_id]);

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const fetchedComments = await GetTaskComments(task_id);
                const sortedComments = fetchedComments.sort(
                    (a, b) => new Date(a.created_at) - new Date(b.created_at)
                );
                setComments(sortedComments);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        fetchComments();
    }, [task_id]);


    const extractMentionedIds = (text) => {
        const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g;
        const mentionedIds = [];
        let match;

        while ((match = mentionRegex.exec(text)) !== null) {
            mentionedIds.push(match[2]);
        }

        return mentionedIds;
    };


    const handlePostComment = async () => {
        if (newComment.trim() === '') return;

       
        const mentionedIds = extractMentionedIds(newComment);
        //console.log(mentionedIds);


        try {
            const commentData = {
                text: newComment,
                parent_comment: null,
                user_id: getLoggedUser(),
                mentioned_users: mentionedIds,
            };
            const postedComment = await PostTaskComment(task_id, commentData);
            setComments([...comments, postedComment]);
            setNewComment('');
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    const handlePostReply = async (parentId) => {
        const replyText = replyComment[parentId]?.trim();
        if (!replyText) return;

        const mentionedIds = extractMentionedIds(replyText);
        //console.log(mentionedIds);

        try {
            const replyData = {
                text: replyText,
                parent_comment: parentId, // Indicates that this is a reply
                user_id: getLoggedUser(),
                mentioned_users: mentionedIds,
            };
            const postedReply = await PostTaskComment(task_id, replyData);

            const updatedComments = comments.map((comment) =>
                comment.id === parentId
                    ? { ...comment, replies: [...comment.replies, postedReply] }
                    : comment
            );
            setComments(updatedComments);
            setReplyComment({ ...replyComment, [parentId]: '' });
        } catch (error) {
            console.error('Error posting reply:', error);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handlePostComment();
        }
    };

    const handleReplyKeyPress = (event, parentId) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handlePostReply(parentId);
        }
    };

    const mentionData = workspaceWorkers.map(worker => ({
        id: worker.id,
        display: worker.full_name,
        image: worker.image,
    }));



    return (
        <Box sx={{ marginTop: '20px' }}>
            <Typography variant="h5">Comments</Typography>
            <Divider sx={{ marginBottom: '10px' }} />
            {comments.length > 0 ? (
                comments.map((comment) => (
                    <Box key={comment.id} sx={{ marginBottom: '20px' }}>
                        <Comment
                            comment={comment}
                            onReply={(text) =>
                                setReplyComment({ ...replyComment, [comment.id]: text })
                            }
                            replyText={replyComment[comment.id] || ''}
                            onPostReply={() => handlePostReply(comment.id)}
                            onReplyKeyPress={(e) => handleReplyKeyPress(e, comment.id)}
                            mentionData={mentionData}
                        />
                        <Divider />
                    </Box>
                ))
            ) : (
                <Typography>No comments yet.</Typography>
            )}

            <Typography variant="h6">Leave a comment</Typography>
            <Paper sx={{ padding: '16px', marginBottom: '20px', marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                <MentionsInput
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    style={mentionStyle}
                    onKeyPress={handleKeyPress}
                    placeholder="Write your comment..."
                >
                    <Mention
                        trigger="@"
                        data={mentionData}
                        style={{ backgroundColor: '#3F4174' }}
                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    src={`${process.env.REACT_APP_BACKEND_URL}${suggestion.image}`} 
                                    sx={{ width: 30, height: 30, marginRight: '8px' }}
                                />
                                <Typography variant="body2">{highlightedDisplay}</Typography>
                            </Box>
                        )}
                        displayTransform={(id, display) => `@${display}`}
                    />
                </MentionsInput>
                <IconButton
                    sx={{ marginLeft: '10px' }}
                    onClick={handlePostComment}
                >
                    <SendIcon sx={{ color: '#FFF' }} />
                </IconButton>
            </Paper>
        </Box>
    );
}

const Comment = ({ comment, onReply, replyText, onPostReply, onReplyKeyPress, mentionData }) => (
    <Box sx={{ padding: '10px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ marginRight: '10px' }}>{comment.user.full_name[0]}</Avatar>
            <Box>
                <Typography variant="subtitle1">
                    {comment.user.full_name} - {format(new Date(comment.created_at), 'dd/MM/yyyy HH:mm')}
                </Typography>
                <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: renderCommentWithMentions(comment.text) }}
                />
            </Box>
        </Box>

        {comment.replies && comment.replies.length > 0 && (
            <Box sx={{ marginLeft: '40px', marginTop: '10px' }}>
                {comment.replies.map((reply) => (
                    <Box key={reply.id} sx={{ padding: '10px 0' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ marginRight: '10px' }}>{reply.user.full_name[0]}</Avatar>
                            <Box>
                                <Typography variant="subtitle2">
                                    {reply.user.full_name} - {format(new Date(reply.created_at), 'dd/MM/yyyy HH:mm')}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    dangerouslySetInnerHTML={{ __html: renderCommentWithMentions(reply.text) }}
                                />
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        )}

        <Box sx={{ marginLeft: '40px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>

            <MentionsInput
                value={replyText}
                onChange={(e) => onReply(e.target.value)}
                style={mentionStyle}
                onKeyPress={onReplyKeyPress}
                placeholder="Reply..."
            >
                <Mention
                    trigger="@"
                    data={mentionData}
                    style={{ backgroundColor: '#3F4174' }}
                    renderSuggestion={(suggestion, search, highlightedDisplay) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                                src={`${process.env.REACT_APP_BACKEND_URL}${suggestion.image}`} 
                                sx={{ width: 30, height: 30, marginRight: '8px' }}
                            />
                            <Typography variant="body2">{highlightedDisplay}</Typography>
                        </Box>
                    )}
                    displayTransform={(id, display) => `@${display}`}
                />
            </MentionsInput>
            <IconButton
                sx={{ marginLeft: '10px' }}
                onClick={onPostReply}
            >
                <SendIcon sx={{ color: '#FFF' }} />
            </IconButton>
        </Box>
    </Box>
);
