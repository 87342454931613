import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import {useTranslation} from "react-i18next";

const RefutalNotificationModal = ({ open, handleAcknowledge }) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={() => {}} sx={{marginLeft:"120px"}}>
            <Box sx={notificationModalStyle}>
                <Typography variant="h6" component="h2" sx={{ color: '#ffffff', textAlign: 'center' }}>
                    {t("calendar.vacation_notif")}
                </Typography>
                <Typography variant="body1" sx={{ color: '#ffffff', mt: 2, textAlign: 'center' }}>
                    {t("calendar.vacation_refuted")}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button variant="contained" sx={{ backgroundColor: '#ffffff', color: '#1c1c1c' }} onClick={handleAcknowledge}>
                        {t("calendar.vacation_acknowledge")}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const notificationModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#1c1c1c',
    border: '2px solid #2f2f2f',
    boxShadow: 24,
    p: 4,
};

export default RefutalNotificationModal;
