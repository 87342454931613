import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Divider,
    Tooltip
} from '@mui/material';
import WorkspaceOwnerIcon from '@mui/icons-material/Token';
import WorkspaceAdminIcon from '@mui/icons-material/BrightnessAuto';
import WorkspaceWorkerIcon from '@mui/icons-material/Person';
import {useTranslation} from "react-i18next";

const PlatformInformation = ({ userStatData }) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ backgroundColor: '#252224', mb: 2, marginRight: "30px" }}>
            <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>{t("profile.platform_info")}</Typography>
                <Divider sx={{ marginBottom: "15px", backgroundColor: "white" }} />
                {userStatData && userStatData.workspace_roles.map(([workspace, role]) => (
                    <div key={workspace}>
                        <Typography variant="body1" sx={{ color: '#FFF', marginBottom: "15px" }} component="div">
                            <strong>{t("profile.workspace")}</strong>
                            <Typography component="span">
                                {workspace}
                                <Tooltip title={role === 'Owner' ? t("profile.workspace_owner") : role === 'Admin' ? t("profile.workspace_admin") : t("profile.workspace_worker")}>
                                    {role === 'Owner' ? (
                                        <WorkspaceOwnerIcon sx={{ color: "#9ec3e1", fontSize: 25, marginLeft: 1 }} />
                                    ) : role === 'Admin' ? (
                                        <WorkspaceAdminIcon sx={{ color: "#9ec3e1", fontSize: 25, marginLeft: 1 }} />
                                    ) : (
                                        <WorkspaceWorkerIcon sx={{ color: "#9ec3e1", fontSize: 25, marginLeft: 1 }} />
                                    )}
                                </Tooltip>
                            </Typography>
                        </Typography>

                        {role === "Owner" && userStatData.projects.owned[workspace] !== 0 &&
                            <Typography variant="body2" sx={{ color: '#FFF', marginBottom: 1 }}>
                                {t("profile.project_owner", {count: userStatData.projects.owned[workspace]})}
                            </Typography>
                        }
                        {role === "Worker" && userStatData.projects.worker[workspace] !== 0 &&
                            <Typography variant="body2" sx={{ color: '#FFF', marginBottom: 1 }}>
                                {t("profile.project_worker", {count: userStatData.projects.worker[workspace]})}
                            </Typography>
                        }
                    </div>
                ))}
            </CardContent>
        </Card>
    );
};

export default PlatformInformation;
