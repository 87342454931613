import React, { useState, useEffect, useRef } from "react";
import { Timeline } from "vis-timeline/standalone";
import { DataSet } from "vis-data";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";
import { useService } from "contexts/ServiceContextProvider";
import { useParams } from "react-router-dom";
import { useAuthorization } from 'contexts/AuthContextProvider';
import { useSidebar } from 'contexts/SidebarContextProvider';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";
import { ConvertToMyTimeZone } from 'components/Custom/TimezoneConvertion';
import {useTranslation} from "react-i18next";

const TodayVisTimeline = () => {
  const { t } = useTranslation();

  const [timeline, setTimeline] = useState(null);
  const { fetchWorkspaceCalendar, getUserTimeZone } = useService();
  const { workspaceId } = useParams();
  const timelineRef = useRef(null);
  const { getLoggedUser } = useAuthorization();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [openModal, setOpenModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [myUserTimezone, setMyUserTimezone] = useState(null);
  const { isSidebarOpen } = useSidebar();

  const generateColor = (index) => {
    const hue = (index * 137.508) % 360;
    return `hsl(${hue}, 70%, 80%)`;
  };

  useEffect(() => {
    const initializeTimeline = async () => {
      try {
        if (workspaceId) {
          const [eventsResponse, timezoneResponse] = await Promise.all([
            fetchEvents(workspaceId),
            getUserTimeZone(getLoggedUser())
          ]);
          setMyUserTimezone(timezoneResponse);

          //console.log(eventsResponse.working_hours)

          const { timelineGroups, timelineItems } = prepareTimelineData(eventsResponse.working_hours, timezoneResponse);
          const Events = { ...eventsResponse.working_hours, timelineGroups, timelineItems };

          const uniqueUsers = Array.from(
            new Set(Events.timelineItems.map((item) => item.fullName))
          );

          setUsers(uniqueUsers);
          setSelectedUsers(new Set(uniqueUsers));
          renderTimeline(Events.timelineGroups, Events.timelineItems);
        }
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    };

    initializeTimeline();

    return () => {
      if (timeline) {
        timeline.destroy();
        setTimeline(null);
      }
    };
  }, [workspaceId]);


  useEffect(() => {
    if (timeline && workspaceId) {
      fetchEvents(workspaceId)
        .then((data) => {
          const { timelineGroups, timelineItems } = prepareTimelineData(data.working_hours, myUserTimezone);
          renderTimeline(timelineGroups, timelineItems);
        })
        .catch((error) => {
          console.error("Error during fetchEvents:", error);
        });
    }
  }, [selectedUsers, myUserTimezone]);

  const fetchEvents = async (workspaceId) => {
    try {
      const data = await fetchWorkspaceCalendar(workspaceId);
      if (!data) {
        throw new Error("No data returned from fetchWorkspaceCalendar");
      }
      return data;
    } catch (error) {
      console.error("Error fetching events:", error);
      return { timelineGroups: [], timelineItems: [] };
    }
  };

  const prepareTimelineData = (data, loggedUserTimezone) => {
    const timelineGroups = [];
    const timelineItems = [];
    const userColorMap = new Map();
    const today = new Date();
    const currentDay = today.toLocaleDateString('en-GB', { weekday: 'long' });

    const filteredData = data.filter(item => item.day === currentDay);

    filteredData.forEach((workingHours, index) => {
      const { start, end, title, timezone, timezone_offset } = workingHours;
      const fullName = title.split("'s Working Hours")[0];

      if (!userColorMap.has(fullName)) {
        userColorMap.set(fullName, generateColor(userColorMap.size));
      }

      const userColor = userColorMap.get(fullName);

      let group = timelineGroups.find((g) => g.content === fullName);
      if (!group) {
        group = {
          id: timelineGroups.length,
          content: fullName,
        };
        timelineGroups.push(group);
      }
      const startDateTime = ConvertToMyTimeZone(timezone, timezone_offset, `${today.toISOString().split('T')[0]}T${start}:00`, loggedUserTimezone.timezone, loggedUserTimezone.timezone_offset);
      const endDateTime = ConvertToMyTimeZone(timezone, timezone_offset, `${today.toISOString().split('T')[0]}T${end}:00`, loggedUserTimezone.timezone, loggedUserTimezone.timezone_offset);
      const formattedStart = startDateTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      const formattedEnd = endDateTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

      const timezoneInfo = `${timezone || 'Unknown'} ${timezone_offset || ''}`;

      if (startDateTime && endDateTime) {
        timelineItems.push({
          id: `${fullName}-${index}`,
          group: group.id,
          content: `${fullName} (${formattedStart} - ${formattedEnd} ${timezoneInfo})`,
          start: startDateTime.toISOString(),
          end: endDateTime.toISOString(),
          style: `background-color: ${userColor}; color: #000; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);`,
          title: `${fullName}\nStarts @ ${formattedStart}\nEnds @ ${formattedEnd} (${timezoneInfo})`,
          fullName,
        });
      } else {
        console.error(`Failed to convert time for user ${fullName} with start: ${start}, end: ${end}, timezone: ${timezone}, timezone_offset: ${timezone_offset}`);
      }
    });

    return { timelineGroups, timelineItems };
  };



  const renderTimeline = (groups, items) => {
    const container = timelineRef.current;
    if (!container) {
      console.error("Timeline container not found during rendering");
      return;
    }

    const filteredItems = items.filter((item) =>
      selectedUsers.has(item.fullName)
    );

    const filteredGroups = groups.filter((group) =>
      filteredItems.some((item) => item.group === group.id)
    );

    const itemsDataset = new DataSet(filteredItems);
    const groupsDataset = new DataSet(filteredGroups);

    const options = {
      stack: true,
      horizontalScroll: true,
      zoomKey: "ctrlKey",
      orientation: "top",
      start: new Date(new Date().setHours(8, 0, 0, 0)).toISOString(),
      end: new Date(new Date().setHours(20, 0, 0, 0)).toISOString(),
      min: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
      max: new Date(new Date().setHours(23, 59, 59, 999)).toISOString()
    };

    if (timeline) {
      try {
        timeline.destroy();
      } catch (error) {
        console.error("Error destroying timeline:", error);
      }
    }

    try {
      const newTimeline = new Timeline(
        container,
        itemsDataset,
        groupsDataset,
        options
      );
      setTimeline(newTimeline);
    } catch (error) {
      console.error("Error creating timeline:", error);
    }
  };

  const handleUserSelection = (event) => {
    const updatedSelectedUsers = new Set(selectedUsers);
    if (event.target.checked) {
      updatedSelectedUsers.add(event.target.name);
    } else {
      updatedSelectedUsers.delete(event.target.name);
    }
    setSelectedUsers(updatedSelectedUsers);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const filteredUsers = users.filter((user) =>
    user.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedUsers = filteredUsers.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Typography
        variant="h4"
        sx={{ display: "flex", justifyContent: "center", marginBottom: "20px", marginTop: "10px" }}
      >
        {t("calendar.calendar_for_today")}
      </Typography>

      <div
        ref={timelineRef}
        style={{
          width: "95%",
          margin: "0 auto",
          backgroundColor: "white",
        }}
      ></div>

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenModal}
        sx={{ margin: "20px auto" }}
      >
        {t("calendar.filter")}
      </Button>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="100px" style={{ marginLeft: isSidebarOpen() ? '240px' : '0px' }}>
        <DialogTitle>{t("calendar.user_select")}</DialogTitle>
        <DialogContent>
          <TextField
            label={t("calendar.search_users")}
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ marginBottom: "20px" }}
          />

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedUsers.size > 0 &&
                        selectedUsers.size < filteredUsers.length
                      }
                      checked={selectedUsers.size === filteredUsers.length}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSelectedUsers(new Set(filteredUsers));
                        } else {
                          setSelectedUsers(new Set());
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{t("calendar.user")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user) => (
                  <TableRow key={user}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.has(user)}
                        onChange={handleUserSelection}
                        name={user}
                      />
                    </TableCell>
                    <TableCell>{user}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredUsers.length}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            sx={{
              '& .MuiTablePagination-selectLabel': {
                alignSelf: 'flex-end',
              },
              '& .MuiTablePagination-displayedRows': {
                alignSelf: 'flex-end',
              },
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TodayVisTimeline;
