import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    TextField,
    MenuItem
} from '@mui/material';
import { Timezone_Offsets, Timezones, Countries } from 'components/Custom/Timezones';
import {useTranslation} from "react-i18next";

const WorkflowInformation = ({
    user, isEditable, handleInputChange, handleSave, loading
}) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ backgroundColor: '#252224', mb: 1 }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="primary">{t("profile.timezone")}</Typography>
                    </Grid>
                    <Grid item sm={10}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                name="timezone"
                                value={user.timezone || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    style: { color: 'white' }
                                }}
                            >
                                {Timezones.map((timezone) => (
                                    <MenuItem key={timezone} value={timezone}>
                                        {timezone}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : (
                            <Typography>{user.timezone}</Typography>
                        )}
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="primary">{t("profile.timezone_offset")}</Typography>
                    </Grid>
                    <Grid item sm={10}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                name="timezone_offset"
                                value={user.timezone_offset || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    style: { color: 'white' }
                                }}
                            >
                                {Timezone_Offsets.map((offset) => (
                                    <MenuItem key={offset} value={offset}>
                                        {offset}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : (
                            <Typography>{user.timezone_offset}</Typography>
                        )}
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="primary">{t("profile.location")}</Typography>
                    </Grid>
                    <Grid item sm={10}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                select
                                variant="outlined"
                                name="location"
                                value={user.location || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    style: { color: 'white' }
                                }}
                            >
                                {Countries.map((country) => (
                                    <MenuItem key={country} value={country}>
                                        {country}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : (
                            <Typography>{user.location}</Typography>
                        )}
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="primary">{t("profile.job")}</Typography>
                    </Grid>
                    <Grid item sm={10}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="job"
                                value={user.job || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    style: { color: 'white' }
                                }}
                            />
                        ) : (
                            <Typography>{user.job}</Typography>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default WorkflowInformation;
