import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Container,
    Grid,
    Box,
    IconButton,
    Typography,
    Divider,
    Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useService } from 'contexts/ServiceContextProvider';
import { useAuthorization } from 'contexts/AuthContextProvider';
import { useProjects } from 'contexts/ProjectsContextProvider';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';

import ProfileCard from 'components/Profile/ProfileCard';
import PersonalInformation from 'components/Profile/PersonalInformation';
import WorkflowInformation from 'components/Profile/WorkflowInformation';
import PlatformInformation from 'components/Profile/PlatformInformation';
import RecentProjectStatusWrapper from 'components/Profile/RecentProject';
import ResetPasswordModal from 'components/Profile/ResetPassword';
import WorkingHours from 'components/Profile/WorkingHours';

import SkeletonProfileCard from 'components/Profile/Skeletons/SkeletonProfileCard';
import SkeletonPersonalInformation from 'components/Profile/Skeletons/SkeletonPersonalInformation';
import SkeletonWorkflowInformation from 'components/Profile/Skeletons/SkeletonWorkflowInformation';
import SkeletonPlatformInformation from 'components/Profile/Skeletons/SkeletonPlatformInformation';
import SkeletonRecentProjectStatusWrapper from 'components/Profile/Skeletons/SkeletonRecentProject';
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import { useTranslation } from "react-i18next";

const ProfilePage = () => {
    const { t } = useTranslation();
    const [user, setUser] = useState('');
    const [userStatData, setUserStatData] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const { updateUserWorkflowWorkingHours, getWorkingHours, updateUserProfileImage, updateUserPassword, getUserProfile, updateUserPersonalInfo, updateUserWorkflowInfo, getUserStatData, getUserTimeZone, getLoggedUserPermissions, calculateBoardStats } = useService();

    const [myUserTimezone, setMyUserTimezone] = useState();
    const { getLoggedUser } = useAuthorization();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;
    const [myUserPerms, setMyUserPerms] = useState(null);
    const [parsedData, setParsedData] = useState(null);
    const [hasAccessToLastProject, setHasAccessToLastProject] = useState(null);
    const [workingHours, setWorkingHours] = useState([]);
    const [isPersonalInfoEditable, setIsPersonalInfoEditable] = useState(false);
    const [isWorkflowInfoEditable, setIsWorkflowInfoEditable] = useState(false);
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [personalInfoChanged, setPersonalInfoChanged] = useState(false);
    const [workflowInfoChanged, setWorkflowInfoChanged] = useState(false);

    const [loadingUserProfileCard, setLoadingUserProfileCard] = useState(true);
    const [loadingPlatformInformation, setLoadingPlatformInformation] = useState(true);
    const [loadingRecentProjectStatusWrapper, setLoadingRecentProjectStatusWrapper] = useState(true);
    const [loadingPersonalInformation, setLoadingPersonalInformation] = useState(true);
    const [loadingWorkflowInformation, setLoadingWorkflowInformation] = useState(true);

    const [isWorkflowHoursEditable, setIsWorkflowHoursEditable] = useState(false);


    const projectsContext = useProjects();
    const myProjects = projectsContext.projects;

    const { showSnackbar } = useSnackbar();

    const getUserIDFromNavBar = () => {
        const path = window.location.pathname;
        const parts = path.split('/');
        const user_id = parts[parts.length - 1];
        return user_id;
    }

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const user_id = getUserIDFromNavBar();
                const userProfile = await getUserProfile(user_id, myWorkspaces[0]?.id);
                setUser(userProfile);
                setIsEditable(getLoggedUser() === user_id);
                const myTime = await getUserTimeZone(getLoggedUser());
                setMyUserTimezone(myTime);

                setLoadingUserProfileCard(false);
                setLoadingPersonalInformation(false);
                setLoadingWorkflowInformation(false)
            } catch (error) {
                showSnackbar(t("profile.fetch_user_fail"), 'error');
                console.log(error)
            }
        };
        if (myWorkspaces[0]?.id) {
            fetchUserProfile();
        }
    }, [myWorkspaces]);

    useEffect(() => {
        const fetchUserPerms = async () => {
            if (myWorkspaces[0]?.id) {
                try {
                    const userPerms = await getLoggedUserPermissions(myWorkspaces[0].id, getLoggedUser());
                    setMyUserPerms(userPerms);

                    const MyWorkTime = await getWorkingHours(myWorkspaces[0].id, getUserIDFromNavBar());


                    setWorkingHours(MyWorkTime.working_hours_events || []);
                } catch (error) {
                    showSnackbar(t("profile.fetch_user_perms_fail"), 'error');
                }
            }
        };
        fetchUserPerms();
    }, [myWorkspaces]);

    useEffect(() => {
        const fetchUserStatData = async () => {
            try {
                const stats = await getUserStatData(getUserIDFromNavBar());
                setUserStatData(stats);
                setLoadingPlatformInformation(false);
            } catch (error) {
                showSnackbar(t("profile.fetch_user_stats_fail"), 'error');
            }
        };
        fetchUserStatData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value,
        });
        if (isPersonalInfoEditable) {
            setPersonalInfoChanged(true);
        } else if (isWorkflowInfoEditable) {
            setWorkflowInfoChanged(true);
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        try {
            await updateUserProfileImage(getUserIDFromNavBar(), file);
            showSnackbar(t("profile.profile_image_update"), 'success');
        } catch (error) {
            showSnackbar(t("profile.profile_image_update_fail"), 'error');
        }
    };

    useEffect(() => {
        if (!isPersonalInfoEditable && personalInfoChanged) {
            handleSavePersonalInfo();
            setPersonalInfoChanged(false);
        }
    }, [isPersonalInfoEditable]);

    useEffect(() => {
        if (!isWorkflowInfoEditable && workflowInfoChanged) {
            handleSaveWorkflowInfo();
            setWorkflowInfoChanged(false);
        }
    }, [isWorkflowInfoEditable]);

    const handleSavePersonalInfo = async () => {
        setLoading(true);
        const personalInfo = {
            full_name: user.full_name || '',
            username: user.username || '',
            email: user.email || '',
            phone: user.phone || '',
            address: user.address || ''
        };
        try {
            await updateUserPersonalInfo(getUserIDFromNavBar(), personalInfo);
            setLoading(false);
            showSnackbar(t("profile.personal_info_update"), 'success');
        } catch (error) {
            setLoading(false);
            showSnackbar(t("profile.personal_info_update_fail"), 'error');
        }
    };

    const handleSaveWorkflowInfo = async () => {
        setLoading(true);
        const workflowInfo = {
            timezone: user.timezone,
            timezone_offset: user.timezone_offset,
            location: user.location,
            job: user.job || ''
        };
        try {
            await updateUserWorkflowInfo(getUserIDFromNavBar(), workflowInfo);
            setLoading(false);
            showSnackbar(t("profile.workflow_info_update"), 'success');

        } catch (error) {
            setLoading(false);
            showSnackbar(t("profile.workflow_info_update_fail"), 'error');
        }
    };

    const handleSaveWorkingHours = async (updated_working_hours) => {
        setLoading(true);
        try {
            await updateUserWorkflowWorkingHours(myWorkspaces[0].id, updated_working_hours);
            setLoading(false);
            showSnackbar(t("profile.workspace_work_hours_update"), 'success');
            setIsWorkflowHoursEditable(false);

            setWorkingHours(updated_working_hours);

        } catch (error) {
            setLoading(false);
            showSnackbar(t("profile.workspace_work_hours_update_fail"), 'error');
        }
    };

    const handleSavePassword = async (password, confirmPassword) => {
        setLoading(true);
        if (password !== confirmPassword) {
            showSnackbar(t("profile.password_not_match"), 'error');
            setLoading(false);
            return;
        }
        if (password && (password.length < 8 || !isNaN(password))) {
            showSnackbar(t("profile.password_rules"), 'error');
            setLoading(false);
            return;
        }
        try {
            await updateUserPassword({ new_password: password });
            setLoading(false);
            showSnackbar(t("profile.password_update"), 'success');
            setResetPasswordModalOpen(false);
        } catch (error) {
            setLoading(false);
            showSnackbar(t("profile.password_update_fail"), 'error');
        }
    };

    useEffect(() => {
        const fetchUserLastProject = async () => {
            if (user.last_project_accessed) {
                try {
                    const response = await calculateBoardStats(user.last_project_accessed);
                    setParsedData(response.data);
                    setHasAccessToLastProject(true);
                    setLoadingRecentProjectStatusWrapper(false);
                } catch (error) {
                    showSnackbar(t("profile.project_fetch_data"), 'error');
                    setHasAccessToLastProject(false);
                }
            }
        };
        fetchUserLastProject();
    }, [user.last_project_accessed]);

    useEffect(() => {
        if (myUserPerms && myProjects) {
            setHasAccessToLastProject(filteredProjects.some(project => project.id === user.last_project_accessed));
        }
    }, [myUserPerms, myProjects]);

    const filteredProjects = myProjects.filter(project => {
        return (
            project.owner === getLoggedUser() ||
            project.workers.includes(getLoggedUser()) ||
            project.visibility ||
            myUserPerms?.is_admin ||
            myUserPerms?.is_owner
        );
    });

    const handleWorkingHoursChange = (index, field, value) => {
        const newWorkingHours = [...workingHours];
        newWorkingHours[index][field] = value;
        setWorkingHours(newWorkingHours);
        setWorkflowInfoChanged(true);
    };

    const addWorkingHoursSlot = () => {
        setWorkingHours([...workingHours, { day: '', start: '', end: '' }]);
    };

    const removeWorkingHoursSlot = (index) => {
        if (workingHours) {
            const newWorkingHours = workingHours.filter((_, i) => i !== index);
            setWorkingHours(newWorkingHours);
            handleSaveWorkflowInfo(true);
        }
    };

    const calculateTotalWorkingHours = (workingHourGroups = null) => {
        let totalMinutes = 0;
        let validInputs = true;
        let hours_to_calculate = {};

        if (workingHourGroups) {
            const formattedHours = workingHourGroups.reduce((acc, group) => {
                group.days.forEach(day => {
                    acc.push({
                        day,
                        start: group.start,
                        end: group.end
                    });
                });
                return acc;
            }, []);

            hours_to_calculate = formattedHours;


        } else {
            hours_to_calculate = workingHours;
        }

        hours_to_calculate.forEach(slot => {
            const { start, end } = slot;
            if (!start || !end) {
                validInputs = false;
                return;
            }
            const [startHour, startMinute] = start.split(':').map(Number);
            const [endHour, endMinute] = end.split(':').map(Number);
            const startTime = startHour * 60 + startMinute;
            const endTime = endHour * 60 + endMinute;
            totalMinutes += endTime - startTime;
        });
        if (!validInputs) {
            return "Incomplete hours";
        }
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}h ${minutes}m`;
    };

    const handleOpenResetPasswordModal = () => {
        setResetPasswordModalOpen(true);
    };

    const handleCloseResetPasswordModal = () => {
        setResetPasswordModalOpen(false);
    };

    return (
        <Box sx={{ backgroundColor: '#3D393B', width: '100%', minHeight: '100vh' }}>
            <Container sx={{ maxWidth: '100%', padding: '0 !important' }}>
                <Grid container spacing={4} sx={{ width: '100%', margin: 0, }}>
                    {/* PROFILE CARD */}
                    <Grid item xs={12} lg={4}>
                        {loadingUserProfileCard ? <SkeletonProfileCard /> : (
                            <ProfileCard
                                user={user}
                                workingHours={workingHours}
                                isEditable={isEditable}
                                selectedFile={selectedFile}
                                handleFileChange={handleFileChange}
                                myUserTimezone={myUserTimezone}
                            />
                        )}
                        {loadingPlatformInformation ? <SkeletonPlatformInformation /> : (
                            <PlatformInformation userStatData={userStatData} />
                        )}
                        {loadingRecentProjectStatusWrapper ? <SkeletonRecentProjectStatusWrapper /> : (
                            <RecentProjectStatusWrapper hasAccessToLastProject={hasAccessToLastProject} parsedData={parsedData} />
                        )}
                        {isEditable && (
                            <Box mr={4} mb={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenResetPasswordModal}
                                    sx={{ mt: 1 }}
                                    fullWidth
                                >
                                    {t('profile.reset_password')}
                                </Button>
                            </Box>
                        )}
                    </Grid>
                    {/* PERSONAL INFORMATION */}
                    <Grid item xs={12} lg={8}>
                        <Card sx={{ backgroundColor: '#252224', mb: 4, marginRight: "30px" }}>
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h5" color="#FFF" gutterBottom>{t('profile.personal_info')}</Typography>
                                    {isEditable && (
                                        <IconButton onClick={() => setIsPersonalInfoEditable(!isPersonalInfoEditable)}>
                                            <EditIcon color="primary" />
                                        </IconButton>
                                    )}
                                </Box>
                                <Divider sx={{ marginBottom: "15px", backgroundColor: "white" }} />
                                {loadingPersonalInformation ? <SkeletonPersonalInformation /> : (
                                    <PersonalInformation
                                        user={user}
                                        isEditable={isPersonalInfoEditable}
                                        handleInputChange={handleInputChange}
                                        handleSave={handleSavePersonalInfo}
                                        loading={loading}
                                    />
                                )}
                            </CardContent>
                        </Card>

                        <Card sx={{ backgroundColor: '#252224', mb: 4, marginRight: "30px" }}>
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h5" color="#FFF" gutterBottom>{t('profile.workflow_info')}</Typography>
                                    {isEditable && (
                                        <IconButton onClick={() => setIsWorkflowInfoEditable(!isWorkflowInfoEditable)}>
                                            <EditIcon color="primary" />
                                        </IconButton>
                                    )}
                                </Box>
                                <Divider sx={{ marginBottom: "15px", backgroundColor: "white" }} />
                                {loadingWorkflowInformation ? <SkeletonWorkflowInformation /> : (
                                    <WorkflowInformation
                                        user={user}
                                        isEditable={isWorkflowInfoEditable}
                                        handleInputChange={handleInputChange}
                                        handleSave={handleSaveWorkflowInfo}
                                        loading={loading}
                                    />
                                )}
                            </CardContent>
                        </Card>

                        <Card sx={{ backgroundColor: '#252224', mb: 4, marginRight: "30px" }}>
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h5" color="#FFF" gutterBottom>{t('profile.work_hours')}</Typography>
                                    {isEditable && (
                                        <IconButton
                                            onClick={() => {
                                                setIsWorkflowHoursEditable(!isWorkflowHoursEditable);
                                            }}
                                        >
                                            <EditIcon color="primary" />
                                        </IconButton>
                                    )}
                                </Box>
                                <Divider sx={{ marginBottom: "15px", backgroundColor: "white" }} />
                                {loadingWorkflowInformation ? <SkeletonWorkflowInformation /> : (
                                    <WorkingHours
                                        isEditable={isWorkflowHoursEditable}
                                        workingHours={workingHours || []}
                                        setWorkingHours={setWorkingHours}
                                        handleWorkingHoursChange={handleWorkingHoursChange}
                                        addWorkingHoursSlot={addWorkingHoursSlot}
                                        removeWorkingHoursSlot={removeWorkingHoursSlot}
                                        calculateTotalWorkingHours={calculateTotalWorkingHours}
                                        handleSave={handleSaveWorkingHours}
                                        loading={loading}
                                    />
                                )}
                            </CardContent>
                        </Card>




                    </Grid>
                </Grid>
            </Container>

            <ResetPasswordModal
                open={resetPasswordModalOpen}
                onClose={handleCloseResetPasswordModal}
                onSave={handleSavePassword}
                loading={loading}
            />
        </Box>
    );
};

export default ProfilePage;
