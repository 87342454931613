import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Grid, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { useAuthorization } from 'contexts/AuthContextProvider';
import { useProjects } from 'contexts/ProjectsContextProvider';
import { useTranslation } from "react-i18next";

const NewProjectPage = () => {
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectImage, setProjectImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [visibility, setVisibility] = useState(true); 
  const [status, setStatus] = useState('ongoing'); // Default to "Ongoing"
  const [nameError, setNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const { accessToken } = useAuthorization();
  const { t } = useTranslation();

  const workspaceContext = useWorkspaces();
  const myWorkspaces = workspaceContext.workspaces;

  const projectsContext = useProjects();
  const myProjects = projectsContext.projects;
  const { setProjects } = useProjects();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setProjectImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleVisibilityChange = (event) => {
    setVisibility(event.target.checked);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;

    if (!projectName) {
      setNameError(t('project.name_err'));
      valid = false;
    } else {
      setNameError('');
    }

    if (!projectDescription) {
      setDescriptionError(t('project.desc_err'));
      valid = false;
    } else {
      setDescriptionError('');
    }

    if (!valid) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}workflow/workspaces/projects/create/`,
        { name: projectName, workspace: myWorkspaces[0].id, image: projectImage, desc: projectDescription, visibility: visibility, status: status },
        { headers: { "Authorization": "Bearer " + accessToken, 'Content-Type': 'multipart/form-data' } }
      );

      if (response.status === 201) {
        const updatedProjectsSet = [response.data, ...myProjects];
        setProjects(updatedProjectsSet);
        window.location.href = `/workflow/projects/${response.data.id}`;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>{t('project.create_title')}</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('project.name')}
              variant="outlined"
              value={projectName}
              onChange={(event) => setProjectName(event.target.value)}
              error={!!nameError}
              helperText={nameError}
              inputProps={{ style: { color: '#FFF' } }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('project.desc')}
              variant="outlined"
              multiline
              rows={4}
              value={projectDescription}
              onChange={(event) => setProjectDescription(event.target.value)}
              error={!!descriptionError}
              helperText={descriptionError}
              InputProps={{ style: { color: 'white' } }}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              accept="image/*"
              id="project-image-input"
              type="file"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
            <label htmlFor="project-image-input">
              <Button variant="contained" component="span">
                {t('project.img')}
              </Button>
            </label>
            <Typography>{t('project.rec_image_size')}</Typography>
            {previewImage && (
              <img src={previewImage} alt="Preview" style={{ marginTop: '10px', maxWidth: '100%', maxHeight: '200px' }} />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={visibility} onChange={handleVisibilityChange} color="success" />}
              label={t('project.public')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel sx={{ color: '#FFF' }}>Status</InputLabel>
              <Select
                value={status}
                onChange={handleStatusChange}
                sx={{ color: '#FFF' }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: '#333',
                      '& .MuiMenuItem-root': {
                        color: '#FFF',
                      },
                    },
                  },
                }}
              >
                <MenuItem value="not_started">{t('project.stat_notstart')}</MenuItem>
                <MenuItem value="ongoing">{t('project.stat_ongoing')}</MenuItem>
                <MenuItem value="completed">{t('project.stat_completed')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              {t('project.create_submit')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default NewProjectPage;
