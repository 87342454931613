import React, { forwardRef } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { ConvertToMyTimeZone } from 'components/Custom/TimezoneConvertion.js';
import BadgeWrapper from 'components/Custom/StyledBadge';
import {useTranslation} from "react-i18next";

const UserOnlineStatus = forwardRef(({ lastOnline, workingHours, userTimezone, userTimezoneOffset, loggedInUserTimezone, loggedInUserTimezoneOffset, children, showMessage, showBadgeOnly }, ref) => {
  const { t } = useTranslation();

  const userLastOnline = ConvertToMyTimeZone(userTimezone, userTimezoneOffset, lastOnline, loggedInUserTimezone, loggedInUserTimezoneOffset);
  if (!userLastOnline) {
    return null;
  }

  const now = ConvertToMyTimeZone(userTimezone, userTimezoneOffset, new Date(), loggedInUserTimezone, loggedInUserTimezoneOffset);
  const timeDifferenceInMinutes = (now - userLastOnline) / 60000;

  const isWorkingHours = () => {
    if (!workingHours || workingHours.length === 0) {
      return false;
    }
    const dayOfWeek = now.toLocaleString('en-US', { weekday: 'long' });

    // Filter working hours for the current day
    const todayWorkingHours = workingHours.filter(wh => wh.day === dayOfWeek);
    if (todayWorkingHours.length === 0) {
      return false;
    }

    // Check if the current time falls within any of the working hours periods for the day
    return todayWorkingHours.some(wh => {
      const { start, end } = wh;
      const [startHour, startMinute] = start.split(':').map(Number);
      const [endHour, endMinute] = end.split(':').map(Number);
      const startTime = new Date(now).setHours(startHour, startMinute, 0, 0);
      const endTime = new Date(now).setHours(endHour, endMinute, 0, 0);
      return now >= startTime && now <= endTime;
    });

  };

  let status;
  let tooltipTitle;
  if (timeDifferenceInMinutes < 15.00) {
    if (isWorkingHours()) {
      status = 'green';
      tooltipTitle = t("modal.status_online");
    } else {
      status = 'purple';
      tooltipTitle = t("modal.status_overtime");
    }
  } else if (isWorkingHours()) {
    status = 'orange';
    tooltipTitle = t("modal.status_offline_working_hours");
  } else {
    status = 'red';
    tooltipTitle = t("modal.status_offline");
  }

  const lastSeenMessage = () => {
    const daysAgo = Math.floor(timeDifferenceInMinutes / (60 * 24));
    const hoursAgo = Math.floor((timeDifferenceInMinutes % (60 * 24)) / 60);
    const minutesAgo = Math.floor(timeDifferenceInMinutes % 60);

    if (daysAgo > 0) {
      return t("modal.last_seen_days", {var1: daysAgo, var2: daysAgo === 1 ? '' : 's', var3: hoursAgo, var4: hoursAgo === 1 ? '' : 's'})
    } else if (hoursAgo > 0) {
      return t("modal.last_seen_hours", {var1: hoursAgo, var2: hoursAgo === 1 ? '' : 's', var3: minutesAgo, var4: minutesAgo === 1 ? '' : 's'});
    } else {
      return t("modal.last_seen_minutes", {var1: minutesAgo, var2: minutesAgo === 1 ? '' : 's'});
    }
  };

  const message = status === 'green' || status === 'purple' ? t("modal.status_online") : lastSeenMessage();

  return (
    <>
      {showBadgeOnly ? (
        <BadgeWrapper status={status}>
          <Tooltip title={tooltipTitle} arrow>
            <Box ref={ref} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {children}
            </Box>
          </Tooltip>
        </BadgeWrapper>
      ) : (
        <Box ref={ref} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" color="#FFF" sx={{ marginRight: '12px' }}>
            {message}
          </Typography>
          <BadgeWrapper status={status}>
            {children}
          </BadgeWrapper>
        </Box>
      )}
    </>
  );
});

export default UserOnlineStatus;
