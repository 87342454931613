import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import {useTranslation} from "react-i18next";

export default function NewListModal({ openListModal, handleListModalClose, handleListNameChange, nameForList, handleCreateList, avoidMisfire }) {
    const [titleError, setTitleError] = useState(false);
    const { t } = useTranslation();

    const handleTitleChecker = (event) => {
        const newValue = event.target.value.toLowerCase();
        // Check if the new value is "to do", "doing", or "done"
        if (newValue === "to do" || newValue === "doing" || newValue === "done") {
            setTitleError(true); 
        } else {
            setTitleError(false); 
            handleListNameChange(event);
            
        }
       
    };

    return (
        <Dialog open={openListModal} onClose={handleListModalClose} sx={{

            '& .MuiPaper-root': {
                background: '#252224'
            }
        }}>
            <DialogTitle>{t("modal.list_title")}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    value={nameForList || ''}
                    onChange={(event) => handleTitleChecker(event)}  
                    error={titleError}
                    helperText={titleError && t("project_page.list_title_err")}
                    InputLabelProps={{
                        style: { color: '#FFF' }
                      }}
                      InputProps={{ 
                        style: { color: 'white' } 
                      }}
                    
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleListModalClose} color="primary">
                    {t("calendar.event_cancel")}
                </Button>
                <Button onClick={handleCreateList} disabled={avoidMisfire} color="primary">
                    {t("core.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
            
