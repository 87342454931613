import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';

import {
  CircularProgress,

} from '@mui/material';
const ProjectsContext = createContext();

export const useProjects = () => useContext(ProjectsContext);


export const ProjectsProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const { accessToken } = useAuthorization();
  const [loading, setLoading] = useState(true);

  const [currentProject, setCurrentProject] = useState(null);

  const pathname = window.location.pathname;

  useEffect(() => {
    if (projects && projects.length > 0 && pathname.includes('/workflow/projects/')) {
      const id = pathname.split('/').pop();
      projects.forEach(project => {
        if (project.id === id) {
          setCurrentProject(project.name)
        }
      });
    } else {
      setCurrentProject(null)
    }

  }, [projects]);


  const workspaceContext = useWorkspaces();
  const myWorkspaces = workspaceContext.workspaces;

  const getProjects = async () => {
    if (myWorkspaces && myWorkspaces.length > 0) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/workflow/workspaces/${myWorkspaces[0].id}/projects/`,
          { headers: { "Authorization": "Bearer " + accessToken } }
        );

        setProjects(response.data);
      } catch (error) {
        console.error('Fetching Projects failed:', error.message);
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      getProjects();
    };
    fetchData();
  }, [accessToken, myWorkspaces]);

  const toggleFavoriteProject = async (projectId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/workflow/workspaces/${myWorkspaces[0].id}/projects/toggle_favorite/${projectId}/`,
        {},
        { headers: { "Authorization": "Bearer " + accessToken } }
      );
    } catch (error) {
      console.error('Error toggling favorite:', error.message);
    }
  };


  const handleToggleFavorite = async (projectId) => {
    toggleFavoriteProject(projectId);
  };


  const handleProjectChange = (id) => {
    projects.forEach(project => {
      if (project.id === id) {
        setCurrentProject(project.name)
        window.location.href = `/workflow/projects/${project.id}`;

      }
    });


  };

  if (loading) {
    <CircularProgress />
  }
  return (
    <ProjectsContext.Provider value={{ projects, setProjects, currentProject, setCurrentProject, handleProjectChange, handleToggleFavorite, }}>
      {children}
    </ProjectsContext.Provider>
  );
};