import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import {useTranslation} from "react-i18next";

export default function DeleteListModal({ openDeleteListModal, handleDeleteListModalClose, handleDeleteList, avoidMisfire }) {
    const { t } = useTranslation();

    return (
        <Dialog open={openDeleteListModal} onClose={handleDeleteListModalClose} sx={{

            '& .MuiPaper-root': {
                background: '#252224'
            }
        }}>
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent sx={{ color: 'white' }}>
                {t("modal.list_delete")}<br />
                {t("modal.list_no_return")}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteListModalClose} color="primary">
                    {t("calendar.event_cancel")}
                </Button>
                <Button onClick={handleDeleteList} disabled={avoidMisfire} color="primary">
                    {t("modal.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}