import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import { Suspense } from 'react';
import CustomFallback from 'components/Custom/CustomFallBack';
import WorkflowLayout from 'layouts/Workflow';
import { WorkspacesProvider } from 'contexts/WorkspacesContextProvider';
import { SidebarProvider } from 'contexts/SidebarContextProvider';
import { AuthorizationProvider } from 'contexts/AuthContextProvider';
import { ProjectsProvider } from 'contexts/ProjectsContextProvider';
import { ServiceProvider } from 'contexts/ServiceContextProvider';
import { NotificationProvider } from 'contexts/NotificationContextProvider';
import { SnackbarProvider } from 'contexts/SnackbarContextProvider';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    allVariants: {
      color: "#C0C0C0"
    },
    fontFamily: 'Inter, sans-serif',
  },
  paper: {
    backgroundColor: "#252224",
    color: "#0063B2",
  },

  palette: {
    primary: {
      main: '#0063B2'
    },
    secondary: {
      main: '#8AC3DD'
    }
  }
});


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/workflow/*" element={<WorkflowLayout/>} />
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default function WrappedApp() {
  return (
    <Suspense fallback={<CustomFallback />}>
      <AuthorizationProvider>
        <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <ServiceProvider>
            <SidebarProvider>
              <NotificationProvider>
                <WorkspacesProvider>
                  <ProjectsProvider>
                    <App />
                  </ProjectsProvider>
                </WorkspacesProvider>
              </NotificationProvider>
            </SidebarProvider>
          </ServiceProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </AuthorizationProvider>
    </Suspense >
  )
}
