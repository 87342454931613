import React from 'react';
import { CardContent } from '@mui/material';
import ContentLoader from 'react-content-loader';

const SkeletonPersonalInformation = () => (
  <CardContent>
    <ContentLoader
      speed={2}
      width="100%"
      height={200}
      backgroundColor="#3D393B"
      foregroundColor="#4A494B"
    >
      <rect x="0" y="20" rx="5" ry="5" width="100%" height="20" />
      <rect x="0" y="60" rx="5" ry="5" width="100%" height="20" />
      <rect x="0" y="100" rx="5" ry="5" width="100%" height="20" />
      <rect x="0" y="140" rx="5" ry="5" width="100%" height="20" />
    </ContentLoader>
  </CardContent>
);

export default SkeletonPersonalInformation;