import React, { forwardRef } from 'react';
import { styled } from '@mui/system';
import Badge from '@mui/material/Badge';

const StyledBadge = styled(Badge)(({ theme, status }) => {
  const backgroundColor = {
    green: '#44b700',
    orange: '#FFA500',
    red: '#FF0000',
    purple: '#b700ff',
  }[status] || '#44b700';

  return {
    '& .MuiBadge-badge': {
      backgroundColor,
      color: backgroundColor,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: status === 'green' ? 'ripple 1.2s infinite ease-in-out' : 'none',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  };
});

const BadgeWrapper = forwardRef(({ status, children }, ref) => {
  return (
    <StyledBadge
      ref={ref}
      status={status}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
    >
      {children}
    </StyledBadge>
  );
});

export default BadgeWrapper;
