import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import {useTranslation} from "react-i18next";

const MoreButtonList = ({ handleNavigateToProfile, workspaceId}) => {
  const { logout } = useAuthorization();
    const { t } = useTranslation();

  return (
    <List>
        <ListItemButton component={Link} to={`${workspaceId}/calendar/overview`}>
            <ListItemIcon>
                <CalendarTodayIcon sx={{ color: "#C0C0C0" }} />
            </ListItemIcon>
            <ListItemText primary={t("calendar.calendar")} />
        </ListItemButton>

      <ListItemButton component={Link} to="notifications">
        <ListItemIcon>
          <CircleNotificationsIcon sx={{ color: "#C0C0C0" }} />
        </ListItemIcon>
        <ListItemText primary={t("notifications.notifications")} />
      </ListItemButton>

      <ListItemButton component={Link} onClick={handleNavigateToProfile}>
        <ListItemIcon>
          <AccountBoxIcon sx={{ color: "#C0C0C0" }} />
        </ListItemIcon>
        <ListItemText primary={t("sidebar.profile")} />
      </ListItemButton>

      <ListItemButton component={Link} to="settings">
        <ListItemIcon>
          <SettingsIcon sx={{ color: "#C0C0C0" }} />
        </ListItemIcon>
        <ListItemText primary={t("sidebar.settings")} />
      </ListItemButton>

      <ListItemButton component={Link} to="/about">
        <ListItemIcon>
          <ExitToAppIcon sx={{ color: "#C0C0C0" }} />
        </ListItemIcon>
        <ListItemText primary={t("sidebar.logout")} onClick={() => { logout() }} />
      </ListItemButton>
    </List>
  );
};

export default MoreButtonList;