import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button
} from '@mui/material';
import {useTranslation} from "react-i18next";

const ResetPasswordModal = ({ open, onClose, onSave, loading }) => {
    const { t } = useTranslation();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        if (name === 'password') {
            setPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };

    const handleSavePassword = () => {
        onSave(password, confirmPassword);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="reset-password-modal"
            aria-describedby="reset-password-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: '#252224',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <Typography id="reset-password-modal" variant="h6" component="h2" sx={{ color: 'white' }}>
                    {t("profile.reset_password")}
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="password"
                    type="password"
                    label={t("profile.password_new")}
                    value={password}
                    onChange={handlePasswordChange}
                    sx={{ mt: 2, input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' }, '&:hover fieldset': { borderColor: 'white' }, '&.Mui-focused fieldset': { borderColor: 'white' } } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    name="confirmPassword"
                    type="password"
                    label={t("profile.password_confirm")}
                    value={confirmPassword}
                    onChange={handlePasswordChange}
                    sx={{ mt: 2, input: { color: 'white' }, label: { color: 'white' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' }, '&:hover fieldset': { borderColor: 'white' }, '&.Mui-focused fieldset': { borderColor: 'white' } } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSavePassword}
                    sx={{ mt: 2 }}
                    fullWidth
                    disabled={loading}
                >
                    {t("profile.password_save")}
                </Button>
            </Box>
        </Modal>
    );
};

export default ResetPasswordModal;
