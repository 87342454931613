import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
    Box
} from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {useTranslation} from "react-i18next";

const TaskDatesDialog = ({ open, onClose, task, handleSaveDates }) => {
    const { t } = useTranslation();

    const [startDate, setStartDate] = useState(task.start_date || null);
    const [dueDate, setDueDate] = useState(task.due_date || null);
    const [endDate, setEndDate] = useState(task.end_date || null);

    const [isStartDateNull, setIsStartDateNull] = useState(!task.start_date);
    const [isDueDateNull, setIsDueDateNull] = useState(!task.due_date);
    const [isEndDateNull, setIsEndDateNull] = useState(!task.end_date);

    const handleSave = () => {
        handleSaveDates({
            start_date: isStartDateNull ? null : startDate,
            due_date: isDueDateNull ? null : dueDate,
            end_date: isEndDateNull ? null : endDate
        });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{ '& .MuiPaper-root': { background: '#252224', display: "flex", textAlign: "-webkit-center" } }}
        >
            <DialogTitle sx={{ width: "400px", color: "white" }}>{t("core.dates")}</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isStartDateNull}
                                    onChange={(e) => setIsStartDateNull(e.target.checked)}
                                    sx={{ color: 'white' }}
                                />
                            }
                            label={t("core.no_start_date")}
                            sx={{ color: 'white' }}
                        />

                        <DateTimePicker
                            label={t("calendar.event_start_date")}
                            value={isStartDateNull ? null : dayjs(startDate)}
                            onChange={(newValue) => setStartDate(newValue)}
                            style={{ color: "white" }}
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: "#FFF",
                                },

                                '& .MuiSvgIcon-root': {
                                    color: "#FFF",
                                },
                            }}
                            
                            disabled={isStartDateNull}

                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isDueDateNull}
                                    onChange={(e) => setIsDueDateNull(e.target.checked)}
                                    sx={{ color: 'white' }}
                                />
                            }
                            label={t("core.no_due_date")}
                            sx={{ color: 'white' }}
                        />
                        <DateTimePicker
                            label={t("core.due_date")}
                            value={isDueDateNull ? null : dayjs(dueDate)}
                            onChange={(newValue) => setDueDate(newValue)}
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: "#FFF",
                                },

                                '& .MuiSvgIcon-root': {
                                    color: "#FFF",
                                },
                            }}
                            disabled={isDueDateNull}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isEndDateNull}
                                    onChange={(e) => setIsEndDateNull(e.target.checked)}
                                    sx={{ color: 'white' }}
                                />
                            }
                            label={t("core.no_end_date")}
                            sx={{ color: 'white' }}
                        />
                        <DateTimePicker
                            label={t("calendar.event_end_date")}
                            value={isEndDateNull ? null : dayjs(endDate)}
                            onChange={(newValue) => setEndDate(newValue)}
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: "#FFF",
                                },

                                '& .MuiSvgIcon-root': {
                                    color: "#FFF",
                                },
                            }}
                            disabled={isEndDateNull}
                        />
                    </Box>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ color: 'white' }}>{t("calendar.event_cancel")}</Button>
                <Button onClick={handleSave} sx={{ color: 'white' }}>{t("core.save")}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TaskDatesDialog;
