import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import routes from "routes.jsx";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/workflow") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div 
        style={{ 
          backgroundColor: 'grey',
          backgroundPosition: "center center",
          backgroundSize: "cover",
          minHeight: "100vh"
        }}>
        <div className="main-content" ref={mainContent}>
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/cert_app/index" replace />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Admin;
