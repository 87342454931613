import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import {useTranslation} from "react-i18next";

export default function ArchiveTaskModal({ openArchiveTaskModal, handleArchiveTaskModalClose, handleArchiveTask, avoidMisfire, listID }) {
    const { t } = useTranslation();

    return (
        <Dialog open={openArchiveTaskModal} onClose={handleArchiveTaskModalClose} sx={{

            '& .MuiPaper-root': {
                background: '#252224'
            }
        }}>
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent sx={ { color: 'white' }}>
                {t("modal.archive_task")}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleArchiveTaskModalClose} color="primary">
                    {t("calendar.event_cancel")}
                </Button>
                <Button onClick={handleArchiveTask} disabled={avoidMisfire} color="primary">
                    {t("modal.archive")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}