import React from 'react';
import { Menu, MenuItem, Typography, Button, ListItemText } from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Groups3Icon from '@mui/icons-material/Groups3';
import EventNoteIcon from '@mui/icons-material/EventNote';
import {useTranslation} from "react-i18next";

const NotificationMenu = ({
    anchorEl,
    open,
    onClose,
    unreadNotifications,
    handleNotificationClick,
    FormatTimestamp,
    handleWorkspaceInvite
}) => {
    const { t } = useTranslation();

    const getIconAndColor = (notification) => {
        let icon, color;

        switch (notification.option_select) {
            case 'Vacation-Accepted':
                icon = <EventAvailableIcon />;
                color = 'green';
                break;
            case 'Vacation-Declined':
                icon = <EventBusyIcon />;
                color = 'red';
                break;
            case 'Vacation-Refuted':
                icon = <EventRepeatIcon />;
                color = 'orange';
                break;
            default:
                if (notification.type === 'Event' && !notification.option_select) {
                    icon = <Groups3Icon />;
                    color = 'blue';
                } else if (notification.type === 'Vacation' && !notification.option_select) {
                    icon = <EventNoteIcon />;
                    color = 'black';
                } else {
                    switch (notification.type) {
                        case 'Workspace':
                            icon = <WorkspacesIcon />;
                            color = 'black';
                            break;
                        case 'Project':
                            icon = <AccountTreeIcon />;
                            color = 'black';
                            break;
                        case 'Task':
                            icon = <AssignmentIcon />;
                            color = 'black';
                            break;
                        default:
                            icon = <MarkEmailUnreadIcon />;
                            color = 'black';
                            break;
                    }
                }
        }

        return { icon, color };
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
        >
            {[...unreadNotifications].slice(0, 5).map((notification) => {
                const { icon, color } = getIconAndColor(notification);
    
                return (
                    <MenuItem key={notification.id} onClick={() => handleNotificationClick(notification)}>
                        <div style={{ marginRight: '5px', color }}>
                            {icon}
                        </div>
                        <ListItemText
                            sx={{ color }}
                            primary={<Typography variant="body1" style={{ color }}>{notification.message}</Typography>}
                            secondary={
                                <Typography variant="body2" style={{ color: '#67B5D9' }}>
                                    <AccessTimeIcon fontSize="inherit" /> {FormatTimestamp(notification.timestamp)}
                                </Typography>
                            }
                        />
                        {notification.option_select === 'Workspace-Invite' && (
                            <>
                                <Button
                                    sx={{ width: '185px', height: '35px', color: 'green', marginRight: '25px', marginTop: '35px' }}
                                    onClick={() => handleWorkspaceInvite(notification, true)}
                                >
                                    Accept <CheckIcon sx={{ marginLeft: '5px' }} fontSize="small" />
                                </Button>
                                <Button
                                    sx={{ width: '185px', height: '35px', color: 'red', marginRight: '25px', marginTop: '35px' }}
                                    onClick={() => handleWorkspaceInvite(notification, false)}
                                >
                                    Decline <CloseIcon sx={{ marginLeft: '5px' }} fontSize="small" />
                                </Button>
                            </>
                        )}
                        {notification.option_select === 'Workspace-Invite-Accepted' && (
                            <Button sx={{ width: '185px', height: '35px', color: 'green', marginRight: '25px', marginTop: '35px' }}>
                                Accepted <CheckIcon sx={{ marginLeft: '5px' }} fontSize="small" />
                            </Button>
                        )}
                        {notification.option_select === 'Workspace-Invite-Declined' && (
                            <Button sx={{ width: '185px', height: '35px', color: 'red', marginRight: '25px', marginTop: '35px' }}>
                                Declined <CloseIcon sx={{ marginLeft: '5px' }} fontSize="small" />
                            </Button>
                        )}
                        <MarkEmailUnreadIcon sx={{ color: 'red', marginLeft: '10px' }} />
                    </MenuItem>
                );
            })}
            {unreadNotifications.length === 0 && (
                <Typography variant="body1" style={{ color: '#000', textAlign: 'center', paddingTop: '10px', marginLeft: '50px', marginRight: '50px' }}>
                    {t("topbar.no_notifs")}
                </Typography>
            )}
            <a href="/workflow/notifications">
                <Button sx={{ display: 'flex', width: '100%', paddingTop: '15px', paddingBottom: '5px', justifyContent: 'flex-end' }}>
                    {t("topbar.view_all_notifs")} <AddIcon fontSize="medium" sx={{ color: '#D6D4D4' }} />
                </Button>
            </a>
        </Menu>
    );
    
};

export default NotificationMenu;
