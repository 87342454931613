import React, { useRef, useState } from 'react';
import { Card, CardMedia, CardContent, Typography, CardActions, IconButton, Menu, MenuItem, Tooltip, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import default_image from 'assets/img/baseBG.png';
import {useTranslation} from "react-i18next";




const ProjectCard = ({ project, myUserPerms, loggedUserID, setCurrentProject, handleProjectChange, favoriteThisProject, handleEditProject, handleArchiveProject, handleDeleteProject, isDragging }) => {
    const { t } = useTranslation();

    const cardRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleProjectItemClick = (event, project) => {
        if (event.button === 1) {
            event.preventDefault();
            window.open(`/workflow/projects/${project.id}`, '_blank');
        } else if (event.button === 0) {
            if (!isDragging && !menuOpen) {
                event.preventDefault();
                setCurrentProject(project.name);
                handleProjectChange(project.id);
                window.location.href = `/workflow/projects/${project.id}`
            }
        }
    };

    const handleMenuClick = (event) => {
        event.stopPropagation(); // Prevent the card click handler from being triggered
        if (!isDragging) {
            setAnchorEl(event.currentTarget);
            setMenuOpen(true);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const canEditOrDelete = myUserPerms.is_admin || myUserPerms.is_owner || project.owner === loggedUserID;

    const getStatusColor = (status) => {
        switch (status) {
            case 'completed':
                return '#228B22';
            case 'ongoing':
                return '#1E90FF';
            case 'not_started':
                return '#FF8C00';
            case 'archived':
                return '#A3A3A3';
            default:
                return '#1E90FF';
        }
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case 'completed':
                return t('project.stat_completed');
            case 'ongoing':
                return t('project.stat_ongoing');
            case 'not_started':
                return t('project.stat_notstart');
            case 'archived':
                return t('project.stat_archived');
        }
    };

    return (
        <Card sx={{ backgroundColor: "#252224", minWidth: 350, maxWidth: 350 }} ref={cardRef}>
            <div onAuxClick={(event) => handleProjectItemClick(event, project)} onClick={(event) => event.button === 0 && handleProjectItemClick(event, project)}>
                <CardMedia
                    sx={{ height: 140, width: 350 }}
                    component="img"
                    image={project.image || default_image}
                    alt={project.name}
                />
                <CardContent>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Tooltip title={project.name}>
                            <Typography
                                fontWeight={"bold"}
                                gutterBottom
                                variant="h6"
                                color="#E3E3E3"
                                component="div"
                                sx={{
                                    maxWidth: '250px', // Adjust the maximum width as needed
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {project.name}
                            </Typography>
                        </Tooltip>
                        {canEditOrDelete && (
                            <div>
                                <IconButton
                                    aria-label="more"
                                    aria-controls="project-menu"
                                    aria-haspopup="true"
                                    onClick={handleMenuClick}
                                >
                                    <MoreVertIcon sx={{ color: "#E3E3E3" }} />
                                </IconButton>
                                <Menu
                                    id="project-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => { handleEditProject(project); handleClose(); }}>
                                        {t('project.edit')}
                                    </MenuItem>
                                    {project.status === "archived" && (myUserPerms.is_admin || myUserPerms.is_owner)&&(
                                    <MenuItem
                                        onClick={() => {
                                            project.status === "archived" ?  handleDeleteProject(project): handleArchiveProject(project);
                                            handleClose();
                                        }}
                                    >
                                        {project.status === "archived" ?  t("core.delete_project"): t("core.archive_project")}
                                    </MenuItem>
                                    )}

                                </Menu>
                            </div>
                        )}
                    </div>
                    <Typography variant="body2" color="#C0C0C0" sx={{ maxHeight: "40px", minHeight: "60px", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
                        {project.desc}
                    </Typography>
                </CardContent>
            </div>
            <CardActions sx={{ backgroundColor: getStatusColor(project.status) }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <IconButton type="button" onClick={() => favoriteThisProject(project.id)}>
                        {project.favorites ? <Star sx={{ color: "yellow" }} /> : <StarBorder sx={{ color: "yellow" }} />}
                    </IconButton>
                    <Typography variant="h6" sx={{ color: '#FFF', alignContent: "center" }}>
                        {getStatusLabel(project.status)}
                    </Typography>
                    <Tooltip title={project.visibility ? t("core.public_project") : t("core.private_project")}>
                        <IconButton sx={{ color: "white" }}>
                            {project.visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </CardActions>
        </Card>
    );
};

export default ProjectCard;
