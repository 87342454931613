import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Paper, Grid, Tabs, Tab } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";
import StorageIcon from '@mui/icons-material/Storage';
import GroupIcon from '@mui/icons-material/Group';
import EuroIcon from '@mui/icons-material/Euro';
import {useTranslation} from "react-i18next";

const PurchasePlanModal = ({ open, onClose, plan, extras, onAddExtras, handleOpenCheckOut,currentWorkspace }) => {
    const { t } = useTranslation();

    const [selectedExtras, setSelectedExtras] = useState([]);
    const [value, setValue] = useState(0);
    const [newMembersTotal, setNewMembersTotal] = useState(plan ? plan.amount_members : 0);
    const [newStorageTotal, setNewStorageTotal] = useState(plan ? plan.storage : 0);
    const [newPriceTotal, setNewPriceTotal] = useState(plan ? plan.price : 0);

    useEffect(() => {
        if (plan) {
            setSelectedExtras([]);
        }
    }, [plan]);

    useEffect(() => {
        if (selectedExtras.length > 0) {
            let totalMembers = plan.amount_members;
            let totalStorage = plan.storage;
            let totalPrice = plan.price;

            selectedExtras.forEach(({ extra, quantity }) => {
                totalMembers += extra.amount_members * quantity;
                totalStorage += extra.storage * quantity;
                totalPrice += extra.price * quantity;
            });

            setNewMembersTotal(totalMembers);
            setNewStorageTotal(totalStorage);
            setNewPriceTotal(totalPrice);
        } else {
            setNewMembersTotal(plan.amount_members);
            setNewStorageTotal(plan.storage);
            setNewPriceTotal(plan.price);
        }
    }, [selectedExtras, plan]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleConfirmAddExtras = () => {
        handleOpenCheckOut(plan, selectedExtras);
        onClose();
    };

    const handleIncrementExtra = (extra) => {
        setSelectedExtras((prevSelected) => {
            const existingExtra = prevSelected.find(e => e.extra.id === extra.id);
            if (existingExtra) {
                // Only increment if there is no other extra with features already selected
                const hasOtherFeatureExtra = prevSelected.some(e => e.extra.features.length > 0 && e.extra.id !== extra.id);
                if (!hasOtherFeatureExtra) {
                    return prevSelected.map(e =>
                        e.extra.id === extra.id ? { ...e, quantity: e.quantity + 1 } : e
                    );
                }
                // Prevent adding if another feature extra is already selected
                return prevSelected;
            } else {
                // Only add if there is no other extra with features already selected
                const hasOtherFeatureExtra = prevSelected.some(e => e.extra.features.length > 0);
                if (!hasOtherFeatureExtra || extra.features.length === 0) {
                    return [...prevSelected, { extra, quantity: 1 }];
                }
                // Prevent adding if another feature extra is already selected
                return prevSelected;
            }
        });
    };

    const handleDecrementExtra = (extra) => {
        setSelectedExtras((prevSelected) => {
            const existingExtra = prevSelected.find(e => e.extra.id === extra.id);
            if (existingExtra && existingExtra.quantity > 1) {
                return prevSelected.map(e =>
                    e.extra.id === extra.id ? { ...e, quantity: e.quantity - 1 } : e
                );
            } else {
                return prevSelected.filter(e => e.extra.id !== extra.id);
            }
        });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '80%',
                    maxWidth: '800px',
                    backgroundColor: 'white',
                    padding: 4,
                    borderRadius: '8px',
                    overflow: 'auto', // Allows content to scroll
                    maxHeight: '80vh', // Limits the height and allows scrolling if content overflows
                }}
            >
                <Tabs value={value} onChange={handleChange} aria-label="purchase plan tabs">
                    <Tab label={t("custom.plan_details")} />
                    <Tab label={t("custom.plan_extras")} />
                    <Tab label={t("custom.plan_checkout")} />
                </Tabs>
                <Box sx={{ padding: 2 }}>
                    {value === 0 && (
                        <Box>
                            <Typography variant="h5">{t("custom.plan_details")}</Typography>
                            {plan && (
                                <Paper elevation={3} sx={{ padding: 2, marginBottom: 2, color: 'black' }}>
                                    <Typography variant="h6">{plan.name}</Typography>
                                    <Typography variant="body1">{t("workspace_settings.plan_members")} {plan.amount_members}</Typography>
                                    <Typography variant="body1">{t("workspace_settings.plan_storage")} {plan.storage} GB</Typography>
                                    <Typography variant="body1">{t("workspace_settings.plan_price")} {plan.price}€</Typography>
                                    <Typography variant="body2" gutterBottom>{plan.description}</Typography>
                                </Paper>
                            )}
                        </Box>
                    )}
                    {value === 1 && (
                        <Box>
                            <Typography variant="h5">{t("custom.plan_extras")}</Typography>
                            {extras && extras.length > 0 && (
                                <Grid container spacing={2}>
                                    {extras
                                        .filter(extra => {
                                            // Get the features of the selected plan and the current extra
                                            const planFeatures = new Set(plan.features.map(feature => feature.id));
                                            const extraFeatures = new Set(extra.features.map(feature => feature.id));

                                            // Check if any feature in the extra is already in the plan
                                            const hasCommonFeatures = [...extraFeatures].some(featureId => planFeatures.has(featureId));

                                            // Only include the extra if it doesn't share any features with the plan
                                            return !hasCommonFeatures;
                                        })
                                        .map(extra => {
                                            const selectedExtra = selectedExtras.find(e => e.extra.id === extra.id);
                                            const quantity = selectedExtra ? selectedExtra.quantity : 0;

                                            return (
                                                <Grid item xs={12} key={extra.id}>
                                                    <Paper
                                                        elevation={3}
                                                        sx={{
                                                            padding: 2,
                                                            backgroundColor: quantity > 0 ? 'lightgrey' : 'white'
                                                        }}
                                                    >
                                                        <Typography variant="body1">{extra.name}</Typography>
                                                        <Typography variant="body2">{t("workspace_settings.plan_price")} {extra.price}€</Typography>
                                                        {extra.amount_members > 0 && <Typography variant="body2">{t("custom.extra_members")} {extra.amount_members}</Typography>}
                                                        {extra.storage > 0 && <Typography variant="body2">{t("custom.extra_storage")} {extra.storage} GB</Typography>}

                                                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => handleDecrementExtra(extra)}
                                                                disabled={quantity === 0}
                                                            >
                                                                -
                                                            </Button>
                                                            <Typography variant="body2" sx={{ margin: '0 8px' }}>
                                                                {quantity}
                                                            </Typography>
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => handleIncrementExtra(extra)}
                                                                disabled={quantity === 1 && extra.features.length>0}
                                                            >
                                                                +
                                                            </Button>
                                                        </Box>
                                                    </Paper>
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            )}
                        </Box>
                    )}
                    {value === 2 && (
                        <Box>
                            <Typography variant="h5">{t("custom.plan_checkout")}</Typography>
                            <Typography variant="body1">{t("custom.confirm_checkout")}</Typography>
                            {currentWorkspace?.active_plan?.price > 0 &&(
                                <Typography variant="body1" sx={{ color: 'red', mt: 2 }}><InfoIcon/> If you buy a new plan, the old plan will be Canceled.</Typography>
                            )}
                        </Box>
                    )}
                </Box>
                <Box sx={{display:'flex', justifyContent:'space-between'}}>
                    <Button variant="contained" color="secondary" onClick={onClose} sx={{ marginTop: 2 }}>{t("calendar.event_cancel")}</Button>
                    {value === 2 && (
                        <>
                            <Button variant="contained" sx={{mt: 2 }} color="primary" onClick={handleConfirmAddExtras}>{t("custom.confirm_checkout_button")}</Button>
                        </>
                    )}
                </Box>

                <Box sx={{marginTop:2}}>
                    <Typography variant="h5">{t("custom.plan_updated")}</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            gap: 2,
                            padding: 2
                        }}
                    >
                        <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center', gap: 1, backgroundColor:'#ff9d00' }}>
                            <GroupIcon sx={{ color:'#ffc500' }} />
                            <Typography variant="body1" sx={{color:'black'}}>{t("custom.members")}: <b>{newMembersTotal}</b></Typography>
                        </Paper>

                        <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center', gap: 1, backgroundColor:'#02ae4c' }}>
                            <StorageIcon sx={{ color:'#00e777' }}/>
                            <Typography variant="body1"  sx={{color:'black'}}>{t("custom.storage")}: <b>{newStorageTotal} GB</b></Typography>
                        </Paper>

                        <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center', gap: 1, backgroundColor:'#0576c7' }}>
                            <EuroIcon sx={{ color:'#64b6f6' }}/>
                            <Typography variant="body1"  sx={{color:'black'}}>{t("custom.price")}: <b>{newPriceTotal}€</b></Typography>
                        </Paper>
                    </Box>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>{t("custom.updated_features")}:</Typography>
                    <>
                        {selectedExtras && selectedExtras.length > 0 || plan && plan.features.length > 0 ? (
                            <>
                                {selectedExtras && selectedExtras.length > 0 && (
                                    <>

                                        <ul>
                                            {selectedExtras.map(extra => (
                                                <>
                                                    {extra.extra.features?.map(feature => (
                                                        <li key={feature.id}>
                                                            <Typography variant="body2">{feature.name}</Typography>
                                                        </li>
                                                    ))}
                                                </>
                                            ))}
                                        </ul>
                                    </>
                                )}
                                {plan && plan.features.length > 0 && (
                                    <>
                                        <ul>
                                            <>
                                                {plan.features?.map(feature => (
                                                    <li key={feature.id}>
                                                        <Typography variant="body2">{feature.name}</Typography>
                                                    </li>
                                                ))}
                                            </>
                                        </ul>
                                    </>
                                )}
                            </>

                        ): (
                            <>
                                <Typography variant="body2">{t("custom.no_features")}</Typography>
                            </>
                        )}
                    </>

                </Box>

                <Box sx={{marginTop:2}}>
                    <Typography variant="h5">{t("custom.current_details")}</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            gap: 2,
                            padding: 2
                        }}
                    >
                        <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                            <GroupIcon />
                            <Typography variant="body1">{currentWorkspace?.workers?.length}/{currentWorkspace?.active_plan?.amount_members}</Typography>
                        </Paper>

                        <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                            <StorageIcon />
                            <Typography variant="body1">{t("custom.storage")} {currentWorkspace?.storage_size}/{currentWorkspace?.active_plan?.storage} GB</Typography>
                        </Paper>

                        <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                            <EuroIcon />
                            <Typography variant="body1">{t("custom.price")}: {currentWorkspace?.active_plan?.price}€</Typography>
                        </Paper>
                    </Box>
                </Box>

            </Box>
        </Modal>
    );
};

export default PurchasePlanModal;
