import React from 'react';
import { Card, CardContent } from '@mui/material';
import ContentLoader from 'react-content-loader';

const SkeletonProfileCard = () => (
  <Card sx={{ backgroundColor: '#252224', mb: 2, width:"338px" }}>
    <CardContent sx={{ textAlign: 'center' }}>
      <ContentLoader
        speed={2}
        width={325}
        height={325}
        viewBox="0 0 325 325"
        backgroundColor="#3D393B"
        foregroundColor="#4A494B"
      >
        <circle cx="150" cy="100" r="75" />
        <rect x="50" y="190" rx="5" ry="5" width="200" height="20" />
        <rect x="50" y="220" rx="5" ry="5" width="140" height="20" />
        <rect x="50" y="250" rx="5" ry="5" width="100" height="20" />
        <rect x="50" y="280" rx="5" ry="5" width="100" height="15" />
        <circle cx="170" cy="290" r="10" />
      </ContentLoader>
    </CardContent>
  </Card>
);

export default SkeletonProfileCard;
