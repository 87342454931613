import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import {useTranslation} from "react-i18next";

export default function ListModal({ openArchiveListModal, handleArchiveListModalClose, handleArchiveList, avoidMisfire}) {
    const { t } = useTranslation();
    
    return (
        <Dialog open={openArchiveListModal} onClose={handleArchiveListModalClose} sx={{

            '& .MuiPaper-root': {
                background: '#252224'
            }
        }}>
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent sx={ { color: 'white' }}>
                {t("modal.archive_list")}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleArchiveListModalClose} color="primary">
                    {t("calendar.event_cancel")}
                </Button>
                <Button onClick={handleArchiveList} disabled={avoidMisfire} color="primary">
                    {t("modal.archive")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}