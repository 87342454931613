import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import axios from 'axios';
import logo from '../assets/img/brand/favicon.png';
import login_bg from '../assets/img/login/bg.png';
import login_file from '../assets/img/login/login_file.png';
import workflowProIcon from '../assets/img/login/WorkFlow Pro icon.png';
import '../css/login.css';

import {
  CardBody,
  FormGroup,
  Form,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';
import CustomCookieConsent from '../components/Custom/CustomCookieConsent.js';
import { useNavigate } from 'react-router-dom';
import {Button, keyframes, TextField} from "@mui/material";

const Login = () => {
  const { t } = useTranslation();
  const { login, accessToken } = useAuthorization();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      navigate('/workflow/index');
    }
  }, [accessToken, navigate]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/users/login/', {
        username: username,
        password: password,
      });
      await login(response.data);
    } catch (error) {
      console.error('Login failed:', error.message);
      setError(error.message);
    }
  };

    const rainbowGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

  return (
    <>
        <div className="background">
            <CustomCookieConsent />
            <div className="login-container">
                <div className="login-card">
                    <CardBody>
                        <div className="login-header">
                            Log In
                        </div>
                        <div className="form-container">
                            <div className="login-logo">
                                <img src={workflowProIcon} alt="Workflow Pro" />
                            </div>
                            <Form role="form" className="login-form">
                                <FormGroup className="mb-1">
                                    <TextField
                                        label={t("login.email_placeholder")}
                                        name="username"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={username}
                                        InputLabelProps={{ style: { color: '#78909c' } }}
                                        onChange={(e) => setUsername(e.target.value)}
                                        InputProps={{
                                            style: { color: 'black', backgroundColor: '#ffffff', borderRadius:'0'},
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <TextField
                                        label={t("login.password_placeholder")}
                                        name="password"
                                        type="password"
                                        fullWidth
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ style: { color: '#78909c' } }}
                                        InputProps={{
                                            style: { color: 'black', backgroundColor: '#ffffff', borderRadius:'0'},
                                        }}
                                    />
                                </FormGroup>
                                <Row style={{ width: '100%' }}>
                                    <Col className="text-center">
                                        <small className="error-message">{t(error)}</small>
                                    </Col>
                                </Row>
                                <div className="text-center">
                                    <Button
                                        onClick={handleLogin}
                                        className="custom-button"
                                        disabled={!!error}
                                    >
                                        {t("login.sign_in")}
                                    </Button>
                                </div>
                                <div className="text-center footer-links">
                                    <small className="footer-link">
                                        Don't have an account? &nbsp;
                                        <a href="/sign-in">Sign In!</a>
                                    </small>
                                    <small className="footer-link">
                                        <a href="/forgot-password">Forgot your password?</a>
                                    </small>
                                </div>
                            </Form>
                        </div>
                    </CardBody>
                </div>
            </div>
        </div>
    </>
  );
};

export default Login;
