import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { debounce } from 'lodash';

import { useService } from 'contexts/ServiceContextProvider';
import {useTranslation} from "react-i18next";

const ListHeaderModal = ({
    openListHeaderModal = false,
    handleListHeaderModalClose,
    List = {}
}) => {
    const { t } = useTranslation();

    const { updateListHeader } = useService();

    const [title, setTitle] = useState('');
    const [color, setColor] = useState('');
    const [emoji, setEmoji] = useState('');
    const [textColor, setTextColor] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [debouncedColor, setDebouncedColor] = useState('');
    const [titleError, setTitleError] = useState('');
    const [imageError, setImageError] = useState('');

    useEffect(() => {

        if (List.header) {
            setTitle(List.header.title);
            setColor(List.header.color || '#000000'); // Default to black if color is not provided
            setEmoji(List.header.emoji || '');
            setTextColor(List.header.title_color || '#000000'); // Default to black if title_color is not provided
            if (List.header.image) {
                setImagePreview(`${process.env.REACT_APP_BACKEND_URL}${List.header.image}`);
                setImageFile(null); // Clear the file input if the image is loaded from the server
            } else {
                setImageFile(null);
                setImagePreview('');
            }
            setDebouncedColor(List.header.color || ''); // Set debouncedColor to the initial color
        } else {
            setTitle('');
            setColor('#000000'); // Default black color
            setEmoji('');
            setTextColor('#000000');
            setImageFile(null);
            setImagePreview('');
            setDebouncedColor(''); // Reset debouncedColor
        }
    }, [List]);

    const updateDebouncedColor = useCallback(
        debounce((colorValue) => {
            setDebouncedColor(colorValue);
        }, 300),
        []
    );

    const handleColorChange = (e) => {
        const newColor = e.target.value;
        setColor(newColor);
        updateDebouncedColor(newColor);
    };

    const handleTextColorChange = (e) => {
        setTextColor(e.target.value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.name.length > 100) {
                setImageError(t("modal.img_err"));
                return;
            }
            setImageFile(file);
            setImagePreview(URL.createObjectURL(file));
            setImageError('');
        }
    };

    const handleRemoveImage = () => {
        setImageFile(null);
        setImagePreview('');
    };

    const handleSave = useCallback(async () => {
        const headerData = {
            emoji,
            title,
            title_color: textColor,
            color: debouncedColor,
        };
        if (imageFile) {
            headerData.image = imageFile; 
        }

        try {
            const response = await updateListHeader(List.id, headerData);

            if (imageFile) {
                List.header = {
                    title: response.data.title,
                    color: response.data.color,
                    emoji: response.data.emoji,
                    title_color: response.data.title_color || '#000000', 
                    image: response.data.image || List.header.image, 
                };
            } else {
                List.header = {
                    title: response.data.title,
                    color: response.data.color,
                    emoji: response.data.emoji,
                    title_color: response.data.title_color || '#000000', // Default to black if title_color is null
                    image: null
                };

            }

            handleListHeaderModalClose(response.data);
        } catch (error) {
            console.error("Error saving header:", error);
        }
    }, [title, debouncedColor, emoji, textColor, imageFile, handleListHeaderModalClose, List, updateListHeader]);

    const handleReset = () => {
        setTitle('');
        setColor('#000000');
        setEmoji('');
        setTextColor('#000000');
        setImageFile(null);
        setImagePreview('');
        setDebouncedColor('');
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        if (newTitle.length <= 18) {
            setTitle(newTitle);
            setTitleError('');
        } else {
            setTitleError(t("modal.title_err"));
        }
    };

    const onEmojiSelect = useCallback((emoji) => {
        setEmoji(emoji.native);
        setShowEmojiPicker(false);
    }, []);

    return (
        <Modal
            open={openListHeaderModal}
            onClose={() => handleListHeaderModalClose()}
            aria-labelledby="list-header-modal-title"
            aria-describedby="list-header-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: '#2e2e2e', // Dark grey background color
                    border: '2px solid #444444', // Slightly lighter grey border color
                    boxShadow: 24,
                    p: 4,
                    color: '#ffffff', // White text color
                }}
            >
                <Typography id="list-header-modal-title" variant="h6" component="h2">
                    {List.header ? t("modal.header_edit") : t("modal.header_create")}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                    <TextField
                        label={t("calendar.event_title")}
                        value={title}
                        onChange={handleTitleChange}
                        fullWidth
                        margin="normal"
                        sx={{ input: { color: '#ffffff' }, label: { color: '#ffffff' }, mr: 2 }} // White input text
                        error={!!titleError}
                        helperText={titleError}
                    />
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={{ color: '#ffffff' }}>{t("modal.text_color")}</Typography>
                        <input
                            type="color"
                            value={textColor}
                            onChange={handleTextColorChange}
                            style={{ marginLeft: '10px' }}
                        />
                    </Box>
                </Box>
                {!imagePreview && (
                    <TextField
                        label={t("modal.header_color")}
                        value={color}
                        onChange={handleColorChange}
                        fullWidth
                        margin="normal"
                        type="color"
                        sx={{ input: { color: '#ffffff' }, label: { color: '#ffffff' } }} // White input text
                    />
                )}
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={handleImageChange}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span" sx={{ marginTop: 2 }}>
                        {t("modal.upload_image")}
                    </Button>
                </label>
                {imagePreview && (
                    <Box mt={2} display="flex" flexDirection="column" alignItems="center">
                        <img src={imagePreview} alt="Selected" style={{ width: '100%', maxHeight: '150px', objectFit: 'cover' }} />
                        <Button variant="contained" color="secondary" onClick={handleRemoveImage} sx={{ marginTop: 1 }}>
                            {t("workspace.rem_image")}
                        </Button>
                    </Box>
                )}
                {imageError && (
                    <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                        {imageError}
                    </Typography>
                )}
                <Box display="flex" alignItems="center" mt={2}>
                    <Typography variant="body1" sx={{ color: '#ffffff' }}>{t("modal.emoji")}</Typography>
                    <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                        <InsertEmoticonIcon sx={{ color: '#ffffff' }} />
                    </IconButton>
                    <Box ml={2} sx={{ fontSize: '2rem', color: '#ffffff' }}>
                        {emoji}
                    </Box>
                </Box>
                {showEmojiPicker && (
                    <Box mt={2}>
                        <Picker data={data} onEmojiSelect={onEmojiSelect} />
                    </Box>
                )}
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button onClick={handleReset} color="secondary">
                        {t("modal.reset")}
                    </Button>
                    <Box>
                        <Button onClick={() => handleListHeaderModalClose()} color="primary">
                            {t("calendar.event_cancel")}
                        </Button>

                        <Button onClick={handleSave} color="primary" variant="contained" sx={{ ml: 2 }}>
                            {t("core.save")}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ListHeaderModal;
