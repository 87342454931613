import React from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    TextField,
    Tooltip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {useTranslation} from "react-i18next";

const PersonalInformation = ({ user, isEditable, handleInputChange, toggleEditable }) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ backgroundColor: '#252224', mb: 1 }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="primary">{t("profile.full_name")}</Typography>
                    </Grid>
                    <Grid item sm={9}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="full_name"
                                value={user.full_name || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    style: { color: 'white' }
                                }}
                            />
                        ) : (
                            <Typography>{user.full_name}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="primary">{t("profile.username")}</Typography>
                    </Grid>
                    <Grid item sm={9}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="username"
                                value={user.username || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    style: { color: 'white' }
                                }}
                            />
                        ) : (
                            <Typography>{user.username}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="primary">{t("profile.email")}</Typography>
                    </Grid>
                    <Grid item sm={9}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="email"
                                value={user.email || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    style: { color: 'white' }
                                }}
                            />
                        ) : (
                            <Typography>{user.email}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="primary">{t("profile.phone")}</Typography>
                    </Grid>
                    <Grid item sm={9}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="phone"
                                value={user.phone || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    style: { color: 'white' }
                                }}
                            />
                        ) : (
                            <Typography>{user.phone}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="primary">{t("profile.address")} <Tooltip title={t("profile.address_info")}>
                            <InfoIcon style={{ color: 'white' }}></InfoIcon>
                        </Tooltip></Typography>
                    </Grid>
                    <Grid item sm={9}>
                        {isEditable ? (
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="address"
                                value={user.address || ''}
                                onChange={handleInputChange}
                                InputProps={{
                                    style: { color: 'white' }
                                }}
                            />
                        ) : (
                            <Typography>{user.address}</Typography>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PersonalInformation;
