import React, {useEffect, useState} from "react";
import {Box, Button, Checkbox, IconButton, LinearProgress, Menu, MenuItem, TextField, Typography} from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Paper from "@mui/material/Paper";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DeleteChecklistModal from "../Modals/DeleteChecklist";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useTranslation} from "react-i18next";


const ChecklistComponent =  ({Task, checklists, setChecklists, editChecklistTitles, setEditChecklistTitles,
                                 newChecklistTitles, setNewChecklistTitles, setContextTaskMenuPosition,
                                 UpdateChecklistOrder, UpdateChecklistItem, UpdateChecklistTitle, DeleteChecklistItem,
                                 GetChecklists, DeleteChecklist, CreateChecklistItem, avoidMisfire, setRefreshFlag, refreshFlag}) => {
    const { t } = useTranslation();
    const [isDragging, setIsDragging] = useState(false);
    const [openDeleteChecklistModal, setOpenChecklistModal] = useState(false);
    const [selectedChecklistId, setSelectedChecklistId] = useState(-1);
    const [menuState, setMenuState] = useState({ anchorEl: null, itemId: null });


    useEffect(() => {
        if (Task.id) {
            fetchChecklists();
        }
    }, [Task]);

    const handleChecklistModalOpen = (id) => {
        setOpenChecklistModal(true);
        setSelectedChecklistId(id);
    };

    const handleDeleteChecklistModalClose = () => {
        setOpenChecklistModal(false);
    };

    const handleCloseAndDelete = () => {
        setOpenChecklistModal(false);
        handleDeleteChecklistModalClose();
        handleDeleteChecklist();
    };

    const fetchChecklists = async () => {
        const fetchedChecklists = await GetChecklists(Task.id);

        setChecklists(sortChecklists(fetchedChecklists));
    };


    const sortChecklists = (unsortedChecklists) => {
        // Sort each checklist's items based on their order
        const sortedChecklists = unsortedChecklists.map(cl => {
            const sortedItems = cl.items.map(item => ({
                ...item,
            })).sort((a, b) => a.order - b.order);
            return {
                ...cl,
                items: sortedItems
            };
        });
        // Sort the checklists themselves based on their order
        return sortedChecklists.sort((a, b) => a.order - b.order);
    };

    const handleDeleteChecklist = async () => {
        try {
            const response = await DeleteChecklist(selectedChecklistId);
            const updatedChecklists = checklists.filter(item => item.id !== selectedChecklistId);
            setCompleted(updatedChecklists, selectedChecklistId);
            setChecklists(updatedChecklists);
            setRefreshFlag(!refreshFlag);
        } catch (error) {
            console.error("Error creating checklist:", error);
        }

    }

    const handleAddChecklistItem = async (checklistId) => {
        const title = newChecklistTitles[checklistId] || '';
        if (title.trim()) {
            const newItem = await CreateChecklistItem(checklistId, title);
            const updatedChecklists = checklists.map(cl => {
                if (cl.id === checklistId) {
                    return { ...cl, items: [...cl.items, newItem] };
                }
                return cl;
            });
            setChecklists(updatedChecklists);
            setCompleted(updatedChecklists, checklistId);
            setNewChecklistTitles(prevState => ({
                ...prevState,
                [checklistId]: ''
            }));
            setRefreshFlag(!refreshFlag);
        }
    };

    /**
     * Updates Task.checklist_completion.total and Task.checklist_completion.completed on the parent (ProjectPage).
     * @param updatedChecklists
     * @param checklistId
     */
    const setCompleted = (updatedChecklists, checklistId) => {
        const checklist = updatedChecklists.filter(item => item.id === checklistId);
        const allChecked = checklist[0]?.items.every(item => item.checked === true);

        let total = 0;
        let completed = 0;
        updatedChecklists.forEach(cl => {
            cl.items.forEach(item => {
                if (item.checked){
                    completed +=1;
                }
                total +=1;
            })
            if (allChecked){

            }
        })

        Task.checklist_completion.total = total;
        Task.checklist_completion.completed = completed;
    }

    const handleToggleChecklistItem = async (checklistId, itemId, checked) => {
        await UpdateChecklistItem(checklistId, itemId, { checked });
        const updatedChecklists = checklists.map(cl => {
            if (cl.id === checklistId) {
                return {
                    ...cl, items: cl.items.map(item => item.id === itemId ? { ...item, checked } : item)
                };
            }
            return cl;
        });
        setCompleted(updatedChecklists, checklistId);
        setChecklists(updatedChecklists);
        setRefreshFlag(!refreshFlag);

    };

    const handleDeleteChecklistItem = async (checklistId, itemId) => {
        try {
            await DeleteChecklistItem(itemId);
            const updatedChecklists = checklists.map(cl => {
                if (cl.id === checklistId) {
                    return { ...cl, items: cl.items.filter(item => item.id !== itemId) };
                }
                return cl;
            });
            setCompleted(updatedChecklists, checklistId);
            setChecklists(updatedChecklists);
            setRefreshFlag(!refreshFlag);

        } catch (error) {
            console.error("Error deleting checklist item:", error);
        }
    };

    const handleToggleHideCheckedItems = (checklistId) => {
        const updatedChecklists = checklists.map(cl =>
            cl.id === checklistId ? { ...cl, hideCompleted: !cl.hideCompleted??true } : cl
        );
        setChecklists(updatedChecklists);
    };

    const getChecklistProgress = (checklist) => {
        if (!checklist || !checklist.items) {
            return 0;
        }
        const totalItems = checklist.items.length;
        const checkedItems = checklist.items.filter(item => item.checked).length;
        return totalItems === 0 ? 0 : (checkedItems / totalItems) * 100;
    };


    //Checks the size limit of a
    const checkSizeLimitCheckListItem = (checklistId, value) => {
        const limit  = 100;
        if (value.toString().length > 100){
            return false;
        }
        return true;
    };

    const handleNewChecklistTitleChange = (checklistId, value) => {
        if(checkSizeLimitCheckListItem(checklistId, value)){
            setNewChecklistTitles(prevState => ({
                ...prevState,
                [checklistId]: value
            }));
        }
    };

    const handleEditChecklistChange = (checklistId, title) => {
        setEditChecklistTitles(prevState => ({
            ...prevState,
            [checklistId]: title
        }));
    };

    const handleEditChecklistItemChange = (checklistId, checklistItemId, title, order, changedOrder=false) => {
        const updatedChecklists = checklists.map(cl => {
            if (cl.id === checklistId) {
                const updatedItems = cl.items.map(item =>
                    item.id === checklistItemId ? { ...item, title, order } : item
                ).sort((a, b) => a.order - b.order); // Sorting the items by order

                if(changedOrder){
                    cl.items.map(item => {
                        if (item.id === checklistItemId){
                            handleEditChecklistItemSave(cl.id, item.id, item.title, item.order);
                        }
                    });
                }

                return {
                    ...cl,
                    items: updatedItems
                };
            }
            return cl;
        });

        setChecklists(updatedChecklists);

    };

    const handleEditChecklistItemSave = async (checklistId, checklistItemId, title, order) => {
        await UpdateChecklistItem(checklistId, checklistItemId, { title, order });
        setRefreshFlag(!refreshFlag);

    };


    const handleSaveChecklistTitle = async (checklistId) => {
        const newTitle = editChecklistTitles[checklistId];

        if (newTitle && newTitle.trim()) {
            try {
                await UpdateChecklistTitle(checklistId, newTitle);
                const updatedChecklists = checklists.map(cl =>
                    cl.id === checklistId ? { ...cl, title: newTitle } : cl
                );

                setChecklists(updatedChecklists);
                setEditChecklistTitles(prevState => {
                    const newState = { ...prevState };
                    delete newState[checklistId];
                    return newState;
                });
                setRefreshFlag(!refreshFlag);


            } catch (error) {
                console.error("Error updating checklist title:", error);
            }
        }
    };


    const handleSaveChecklistOrder = async (checklistId, order, updateChecklists = true) => {

        try {
            await UpdateChecklistOrder(checklistId, order);
            const updatedChecklists = checklists.map(cl =>
                cl.id === checklistId ? { ...cl, order: order } : cl
            );
            if(updateChecklists){
                setChecklists(updatedChecklists);
            }
        } catch (error) {
            console.error("Error updating checklist title:", error);
        }
    };

    const handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setContextTaskMenuPosition({ x: event.clientX, y: event.clientY });
    };

    const onDragStart = () => {
        setIsDragging(true);
    };

    const onDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        if (destination.droppableId === 'checklist' || source.droppableId === 'checklist'){

            /**
             * Used to update the order of the checklists.
             */


            const updatedChecklists= [...checklists]
            /**
             * Sorts all the checklists of the current task, removes the checklist from the old position, assigns
             * it to the new one and updates the checklists state, then it updates through the API.
             * It's done separately because it would take to much time to update the checklists state resulting in stuttering.
             *
             */

            const sortedChecklists = [...updatedChecklists].sort((a, b) => a.order - b.order);
            const [removedList] = sortedChecklists.splice(source.index, 1);
            sortedChecklists.splice(destination.index, 0, removedList);
            sortedChecklists.forEach((item, index) => {
                item.order = index;
            });
            setChecklists(sortedChecklists);
            sortedChecklists.forEach(cl => {
                handleSaveChecklistOrder(cl.id, cl.order, false);
            });

        }else{

            /**
             * Used to update the order of the checklist items.
             */

            const sourceListId = source.droppableId.replace('checklist-items-', '');
            const destinationListId = destination.droppableId.replace('checklist-items-', '');

            const updatedChecklist= [...checklists]


            const sourceList = updatedChecklist.find((checklist)=> checklist.id === sourceListId);
            const destinationList = updatedChecklist.find((checklist)=> checklist.id === destinationListId);

            if (!sourceList || !destinationList) {
                console.error('Source or destination list not found');
                return;
            }



            const items = sourceList.items;
            /**
             * Sorts all the checklist's items of the current task, removes the checklist from the old position, assigns
             * it to the new one and updates the checklists state, then it updates through the API.
             * It's done separately because it would take to much time to update the checklists state resulting in stuttering.
             *
             */

            const sourceSortedItems = [...items].sort((a, b) => a.order - b.order);
            let updatedChecklists = null;
            if (sourceList.id === destinationList.id){
                const [removedList] = sourceSortedItems.splice(source.index, 1);
                sourceSortedItems.splice(destination.index, 0, removedList);
                sourceSortedItems.forEach((item, index) => {
                    item.order = index;

                });

                sourceList.items = sourceSortedItems;
                updatedChecklists = checklists.map(cl => {
                    if (cl.id === sourceList.id) {
                        cl = sourceList;
                    }
                    return cl;
                });

                setChecklists(updatedChecklists);

                updatedChecklists.forEach((cl) => {
                    cl.items.forEach(item => {
                        handleEditChecklistItemChange(cl.id, item.id, item.title, item.order, true);
                    })
                });
            }

        }


        setIsDragging(false);
    };

    //Opens the options menu of the checklistItem.
    const handleClick = (event, itemId) => {
        setMenuState({ anchorEl: event.currentTarget, itemId });
        setSelectedItemId(itemId);

    };


    //Closes the options menu of the checklistItem.
    const handleClose = () => {
        setMenuState({ anchorEl: null, itemId: null });
    };

    const handleDelete = (checklistId) => {
        if (selectedItemId !== null) {
            handleDeleteChecklistItem(checklistId, selectedItemId);
        }
        handleClose();
    };

    const [selectedItemId, setSelectedItemId] = useState(null);


    return(
        <>
            <DeleteChecklistModal open={openDeleteChecklistModal} handleDeleteChecklistModalClose={handleDeleteChecklistModalClose} handleCloseAndDelete={handleCloseAndDelete} avoidMisfire={avoidMisfire} />

            {checklists && checklists.length > 0 && (
                <>
                    <Typography sx={{ marginBottom: "15px" }}>{t("custom.checklists")}</Typography>
                </>
            )}
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId={`checklist`} direction="vertical">
                    {(provided) => (
                        <div className='ChecklistDragDivDivider' {...provided.droppableProps}  ref={provided.innerRef}>
                            {checklists && checklists.map((checklist, index) => (
                                    <Draggable draggableId={checklist.id.toString()} index={index} key={checklist.id}>
                                        {(provided_parent, snapshot) => (
                                            <Paper
                                                className="checklist"
                                                elevation={4}
                                                sx={{
                                                    border: '1px solid #ccc',
                                                    padding: 1,
                                                    width:"95%",
                                                    backgroundColor: snapshot.isDragging ? "#524C4F" : "#D6D4D4",
                                                    marginBottom: "2%"
                                                }}
                                                {...provided_parent.draggableProps}
                                                ref={provided_parent.innerRef}

                                            >

                                                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>

                                                    <Droppable droppableId={`checklist-items-${checklist.id}`} direction="vertical">
                                                        {(provided) => (
                                                            <div className='ChecklistItemsDragDivDivider' {...provided.droppableProps}  ref={provided.innerRef}>
                                                                <Box key={checklist.id} sx={{ marginBottom: "15px"}}>
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        position: 'relative',
                                                                        '&:hover .drag-icon': {
                                                                            visibility: 'visible',
                                                                            opacity: 1,
                                                                            position:'relative'
                                                                        }
                                                                    }}>
                                                                        <Box
                                                                            className="drag-icon"
                                                                            sx={{
                                                                                visibility: 'hidden',
                                                                                opacity: 0,
                                                                                transition: 'opacity 0.3s ease-in-out',
                                                                                position: 'relative',
                                                                                left: 0,
                                                                                color: 'white',
                                                                            }}
                                                                            {...provided_parent.dragHandleProps}
                                                                            ref={provided_parent.innerRef}


                                                                        >
                                                                            <DragIndicatorIcon sx={{ color: "white" }} />
                                                                        </Box>

                                                                        <TextField
                                                                            variant="standard"
                                                                            value={editChecklistTitles[checklist.id] !== undefined ? editChecklistTitles[checklist.id] : checklist.title}
                                                                            onChange={(e) => handleEditChecklistChange(checklist.id, e.target.value)}
                                                                            onBlur={() => handleSaveChecklistTitle(checklist.id)}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    e.target.blur();
                                                                                }
                                                                            }}
                                                                            sx={{ color: "white", input: { color: "white" }, flexGrow: 1 }}
                                                                            InputProps={{
                                                                                disableUnderline: true,
                                                                                style: { color: "white" }
                                                                            }}
                                                                            multiline
                                                                        />

                                                                        <Button onClick={()=> handleToggleHideCheckedItems(checklist.id)} size="small" sx={{ marginTop: "10px", color: "white" }}>
                                                                            {checklist.hideCompleted ? t("custom.show_checked") : t("custom.hide_checked")}
                                                                        </Button>
                                                                        <Button onClick={() => handleChecklistModalOpen(checklist.id)} sx={{ borderRadius: "15px", bgcolor: "#575054", color: "#ffffff", '&:hover': { bgcolor: "#463e3c" }, padding: "10px" }}>
                                                                            {t("custom.delete_checklist")}
                                                                        </Button>



                                                                    </Box>
                                                                    <Typography variant="body2" sx={{ color: "white", marginLeft: "10px" }}>
                                                                        {`${checklist.items.filter(item => item.checked).length}/${checklist.items.length}`} ({`${getChecklistProgress(checklist).toFixed(2)}%`})
                                                                    </Typography>
                                                                    <LinearProgress variant="determinate" value={getChecklistProgress(checklist)} sx={{ marginBottom: "5px" }} />

                                                                    {checklist.items?.filter(item => !checklist.hideCompleted || !item.checked).map((item, index) => (
                                                                        <Draggable draggableId={item.id.toString()} index={index} key={item.id}>
                                                                            {(provided, snapshot) => (
                                                                                <Paper
                                                                                    className="checklist"
                                                                                    elevation={4}
                                                                                    sx={{
                                                                                        border: '1px solid #ccc',
                                                                                        padding: 0.2,
                                                                                        backgroundColor: snapshot.isDragging ? "#524C4F" : "#D6D4D4",
                                                                                        marginBottom: "2%"
                                                                                    }}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    ref={provided.innerRef}
                                                                                    onContextMenu={(event) => handleDrag(event)}
                                                                                >

                                                                                    <Box key={item.id} sx={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        position: 'relative',
                                                                                        '&:hover .drag-icon': {
                                                                                            visibility: 'visible',
                                                                                            opacity: 1,
                                                                                            position:'relative'
                                                                                        }
                                                                                    }}>
                                                                                        <Box
                                                                                            className="drag-icon"
                                                                                            sx={{
                                                                                                visibility: 'hidden',
                                                                                                opacity: 0,
                                                                                                transition: 'opacity 0.3s ease-in-out',
                                                                                                position: 'relative',
                                                                                                left: 0,
                                                                                                color: 'white',
                                                                                            }}
                                                                                        >
                                                                                            <DragIndicatorIcon />
                                                                                        </Box>                                                                                        <Checkbox
                                                                                            checked={item.checked}
                                                                                            onChange={() => handleToggleChecklistItem(checklist.id, item.id, !item.checked)}
                                                                                            sx={{ color: "white" }}
                                                                                        />
                                                                                        <TextField
                                                                                            variant="standard"
                                                                                            value={item.title}
                                                                                            onChange={(e) => handleEditChecklistItemChange(checklist.id, item.id, e.target.value, item.order, false)}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.key === 'Enter') {
                                                                                                    e.target.blur();
                                                                                                }
                                                                                            }}
                                                                                            onBlur={(e)=>{
                                                                                                handleEditChecklistItemSave(checklist.id, item.id, e.target.value, item.order);
                                                                                            }}
                                                                                            multiline
                                                                                            sx={{ color: "white", input: { color: "white" }, flexGrow: 1 }}
                                                                                            InputProps={{
                                                                                                disableUnderline: true,
                                                                                                style: { color: "white" }
                                                                                            }}
                                                                                        />
                                                                                        <>
                                                                                            <IconButton onClick={(event) => handleClick(event, item.id)} size="small">
                                                                                                <MoreVertIcon sx={{ color: "white" }} />
                                                                                            </IconButton>
                                                                                            <Menu
                                                                                                anchorEl={menuState.anchorEl}
                                                                                                open={Boolean(menuState.anchorEl)}
                                                                                                onClose={handleClose}
                                                                                                sx={{
                                                                                                    "& .MuiPaper-root": {
                                                                                                        backgroundColor: "white !important",
                                                                                                    },
                                                                                                    "& .MuiMenuItem-root": {
                                                                                                        color: "black !important",
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <MenuItem onClick={()=>{handleDelete(checklist.id, item.id)}}>Delete Item</MenuItem>
                                                                                            </Menu>
                                                                                        </>
                                                                                    </Box>
                                                                                </Paper>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}

                                                                    <Box sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                                                                        <TextField
                                                                            size="small"
                                                                            placeholder={t("custom.add_checklist_item")}
                                                                            value={newChecklistTitles[checklist.id] || ''}
                                                                            onChange={(e) => handleNewChecklistTitleChange(checklist.id, e.target.value)}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    handleAddChecklistItem(checklist.id);
                                                                                    e.target.blur();
                                                                                }
                                                                            }}
                                                                            sx={{ flexGrow: 1, marginRight: "5px", input: { color: "white" } }}
                                                                        />
                                                                        <IconButton onClick={() => handleAddChecklistItem(checklist.id)} size="small">
                                                                            <AddIcon sx={{ color: "white" }} />
                                                                        </IconButton>
                                                                    </Box>
                                                                </Box>
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>

                                            </Paper>
                                        )}
                                    </Draggable>
                                )
                            )
                            }
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}

export default ChecklistComponent;
