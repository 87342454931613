import React, { useState, useEffect } from 'react';
import {
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    Divider,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import {
    Workspaces as WorkspacesIcon,
    AccountTree as AccountTreeIcon,
    Assignment as AssignmentIcon,
    Check as CheckIcon,
    Close as CloseIcon,
} from '@mui/icons-material';

import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Groups3Icon from '@mui/icons-material/Groups3';
import EventNoteIcon from '@mui/icons-material/EventNote';

import { useNotification } from 'contexts/NotificationContextProvider';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import { useService } from 'contexts/ServiceContextProvider';
import { useTranslation } from "react-i18next";

const NotificationsPage = () => {
    const { WorkspaceInviteUpdate, MarkNotificationAsRead, MarkNotificationAsUnread } = useService();
    const { myNotifs, SetMyNotifs, fetchNotifications } = useNotification();
    const { getLoggedUser } = useAuthorization();
    const loggedUser = getLoggedUser();
    const { t } = useTranslation();

    const [avoidMisfire, setAvoidMisfire] = useState(false);

    useEffect(() => {
        fetchNotifications(loggedUser);
    }, [loggedUser]);

    const FormatTimestamp = (timestamp) => {
        const parsedTimestamp = new Date(timestamp);
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        return parsedTimestamp.toLocaleString('en-US', options);
    };

    const markNotificationAsRead = async (notification, markAsRead) => {
        setAvoidMisfire(true);
        try {
            if (markAsRead) {
                await MarkNotificationAsRead(notification.id);
            } else {
                await MarkNotificationAsUnread(notification.id);
            }
        } catch (error) {
            console.error('Marking Notification as Read Failed:', error.message);
        } finally {
            setTimeout(() => {
                setAvoidMisfire(false);
            }, 1000);
        }
    };

    const handleNotificationClick = async (processed_notification, markAsRead) => {
        await markNotificationAsRead(processed_notification, markAsRead);
        fetchNotifications(loggedUser);
    };

    const handleWorkspaceInvite = async (notification, AcceptedInvite) => {
        setAvoidMisfire(true);
        const response = await WorkspaceInviteUpdate(notification.id, notification.invite_identifier, AcceptedInvite, getLoggedUser());
        if (response.status === 200) {
            fetchNotifications(loggedUser);
        }
    };

    const getIconAndColor = (notification) => {
        let icon, color;

        switch (notification.option_select) {
            case 'Vacation-Accepted':
                icon = <EventAvailableIcon sx={{fontSize:"40px"}}/>;
                color = 'green';
                break;
            case 'Vacation-Declined':
                icon = <EventBusyIcon sx={{fontSize:"40px"}}/>;
                color = 'red';
                break;
            case 'Vacation-Refuted':
                icon = <EventRepeatIcon sx={{fontSize:"40px"}}/>;
                color = 'orange';
                break;
            default:
                if (notification.type === 'Event' && !notification.option_select) {
                    icon = <Groups3Icon sx={{fontSize:"40px"}}/>;
                    color = 'blue';
                } else if (notification.type === 'Vacation' && !notification.option_select) {
                    icon = <EventNoteIcon sx={{fontSize:"40px"}}/>;
                    color = 'black';
                } else {
                    switch (notification.type) {
                        case 'Workspace':
                            icon = <WorkspacesIcon sx={{fontSize:"40px"}}/>;
                            color = 'black';
                            break;
                        case 'Project':
                            icon = <AccountTreeIcon sx={{fontSize:"40px"}}/>;
                            color = 'black';
                            break;
                        case 'Task':
                            icon = <AssignmentIcon  sx={{fontSize:"40px"}}/>;
                            color = 'black';
                            break;
                        default:
                            icon = <MarkEmailUnreadIcon sx={{fontSize:"40px"}} />;
                            color = 'black';
                            break;
                    }
                }
        }

        return { icon, color };
    };

    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', display: "flex", flexDirection: "column", flexWrap: "wrap", alignContent: "center" }}>
            <article className="workspace-title-hub" style={{ width: "80%", display: "flex", justifyContent: "center" }} >
                <Typography variant="h3" style={{ color: "#C0C0C0" }}>
                    {t('notifications.notifications')}
                </Typography>
            </article>
            <Divider sx={{ backgroundColor: "#A3989E", margin: "2%" }} />
            <div style={{ width: "80%" }}>
                {myNotifs && myNotifs.length > 0 ? (
                    myNotifs.map(notification => {
                        const { icon, color } = getIconAndColor(notification);

                        return (
                            <Card sx={{ backgroundColor: "gray" }} key={notification.id} variant="outlined" style={{ marginBottom: '16px' }}>
                                <CardContent sx={{ paddingBottom: "0" }}>
                                    <Typography variant="h5" color="black">{notification.type}{t('notifications.notification')}</Typography>
                                    <ListItem>
                                        <ListItemIcon>
                                            <div style={{ color }} >
                                                {icon}
                                            </div>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<Typography variant="h5">{notification.message}</Typography>}
                                            secondary={<Typography variant="h6" color={"black"}>{FormatTimestamp(notification.timestamp)}</Typography>}
                                        />
                                        <ListItemIcon>
                                            {notification.is_read ? (
                                                <MarkEmailReadIcon sx={{ color: "darkgreen", fontSize: '2.5rem' }} />
                                            ) : (
                                                <MarkEmailUnreadIcon sx={{ color: "darkred", fontSize: '2.5rem' }} />
                                            )}
                                        </ListItemIcon>
                                    </ListItem>
                                </CardContent>
                                <CardActions>
                                    {notification.option_select === "Workspace-Invite" && (
                                        <>
                                            <Button size="large" sx={{ color: "darkgreen" }} onClick={() => handleWorkspaceInvite(notification, true)}>
                                                {t('notifications.accept')} <CheckIcon sx={{ marginLeft: "5px" }} fontSize='small' />
                                            </Button>
                                            <Button size="large" sx={{ color: "darkred" }} onClick={() => handleWorkspaceInvite(notification, false)}>
                                                {t('notifications.decline')} <CloseIcon sx={{ marginLeft: "5px" }} fontSize='small' />
                                            </Button>
                                        </>
                                    )}
                                    {notification.option_select === "Workspace-Invite-Accepted" && (
                                        <Button size="large" sx={{ color: "darkgreen", fontSize: '1.5rem' }}>
                                            {t('notifications.accepted')} <CheckIcon sx={{ marginLeft: "5px" }} fontSize='large' />
                                        </Button>
                                    )}
                                    {notification.option_select === "Workspace-Invite-Declined" && (
                                        <Button size="large" sx={{ color: "darkred", fontSize: '1.5rem' }}>
                                            {t('notifications.declined')} <CloseIcon sx={{ marginLeft: "5px" }} fontSize='large' />
                                        </Button>
                                    )}
                                    <Button onClick={() => handleNotificationClick(notification, !notification.is_read)} size="large" sx={{
                                        color: notification.is_read ? 'darkred' : 'darkgreen',
                                        fontSize: '1.5rem',
                                    }}>
                                        {notification.is_read ? t('notifications.mark_unread') : t('notifications.mark_read')}
                                    </Button>
                                </CardActions>
                            </Card>
                        );
                    })
                ) : (
                    <Typography variant="body1" style={{ color: '#fff', textAlign: 'center', paddingTop: "10px" }}>{t('notifications.no_notifs')}</Typography>
                )}
            </div>
        </div>
    );
};

export default NotificationsPage;
