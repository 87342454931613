import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
  AppBar,
} from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import WidgetsIcon from '@mui/icons-material/Widgets';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TokenIcon from '@mui/icons-material/Token';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import AddIcon from '@mui/icons-material/Add';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { styled } from '@mui/material/styles';
import CommonAvatarGroup from 'components/Core/CommonAvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import UserOnlineStatus from 'components/Modals/UserOnlineChecker';
import VacationRequestModal from 'components/Calendar/VacationRequestModal'
import NotificationMenu from './NotificationMenu'
import menuIcon from 'assets/img/brand/favicon.png'

import VacationApprovalModal from 'components/Calendar/VacationApprovalModal'
import ViewEventsModal from "components/Calendar/Events/ViewEventsModal";
import CreateEventsModal from "components/Calendar/Events/CreateEventsModal";
import HolidayModal from "components/Calendar/Holiday/HolidayModal";
import {useTranslation} from "react-i18next";


const drawerWidth = 240;



const TopBar = ({ isOpen, handleDrawerOpen, handleListModalOpen, isWorkflowCalendar, isWorkflowProjects, isWorkflowProjectsNew, isWorkflowProjectsEdit, isProjectDetails, buttonText, open_Element1, handleClickProjectMenu, anchorEl, handleCloseProjectMenu, projectWorkers, handleAvatarGroupClick,
  open_Element2, handleClickProjectMemberMenu, anchorEl2, handleCloseProjectMemberMenu, searchValue, handleSearchChange, filteredProjectWorkers, myLoggedUser, loggedUserID, handleRemoveUserFromProject, filteredWorkspaceWorkers, handleAddUserToProject, isWorkflowIndex,
  isWorkflowMembers, isWorkflowSettings, isProjectArchive, isVacations, myWorkspaces, buttonWorkspaceText, workspaceWorkersRaw, handleNewWorkSpaceMemberModalOpen, myNotifs, handleNotifOpen, notifAnchorEl, handleNotifClosed, unreadNotifications, handleNotificationClick, FormatTimestamp, handleWorkspaceInvite, getLoggedUser,
  myUserInMemberFormat, getProjectIDFromNavBar, isVacationsOverview }) => {
  const { t } = useTranslation();

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const [isVacationRequestModalOpen, setVacationRequestModalOpen] = useState(false);
  const handleOpenVacationRequestModal = () => setVacationRequestModalOpen(true);
  const handleCloseVacationRequestModal = () => setVacationRequestModalOpen(false);

  const [modalOpenApproveVacations, setModalOpenApproveVacations] = useState(false);

  const handleOpenApproveVacationsModal = () => {
    setModalOpenApproveVacations(true);
  };

  const handleCloseApproveVacationsModal = () => {
    setModalOpenApproveVacations(false);
  };

  const [viewEventsOpen, setViewEventsOpen] = useState(false);
  const [createEventsOpen, setCreateEventsOpen] = useState(false);

  const handleOpenViewEventsModal = () => setViewEventsOpen(true);
  const handleCloseViewEventsModal = () => setViewEventsOpen(false);

  const handleOpenCreateEventsModal = () => setCreateEventsOpen(true);
  const handleCloseCreateEventsModal = () => setCreateEventsOpen(false);

  const [openHolidayModal, setOpenHolidayModal] = useState(false);

  return (
    <AppBar
      position="fixed"
      open={isOpen}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: (theme) => theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(isOpen && {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`
        }),
      }}
    >
      <Toolbar style={{ backgroundColor: '#252224' }}>
        {!isOpen && (
          <IconButton color="#9CC3D5" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
            <div style={{ backgroundImage: `url(${menuIcon})`, width: "30px", height: "30px", rotate: "-90deg", backgroundPosition: "center", backgroundSize: "contain" }} />
          </IconButton>
        )}

        {isVacationsOverview && (
          <Button onClick={handleOpenViewEventsModal} style={{ color: 'burlywood' }}>
            {t("calendar.event_view")} <EventNoteIcon fontSize="large" sx={{ color: '#D6D4D4', ml: 1 }} />
          </Button>

        )}



        {isVacationsOverview && (myLoggedUser?.is_admin || myLoggedUser?.is_owner) && (
          <>
            <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#A3989E', marginRight: '20px', marginLeft: '10px' }} />
            <Button onClick={handleOpenCreateEventsModal} style={{ color: 'burlywood' }}>
              {t("topbar.create_event")} <AddIcon fontSize="large" sx={{ color: '#D6D4D4' }} />
            </Button>
          </>
        )}

        {isVacationsOverview && (myLoggedUser?.is_admin || myLoggedUser?.is_owner) && (
          <>
            <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#A3989E', marginRight: '20px', marginLeft: '10px' }} />
            <Button  onClick={() => setOpenHolidayModal(true)} style={{ color: 'burlywood' }}>
              {t("topbar.manage_holidays")} <AddIcon fontSize="large" sx={{ color: '#D6D4D4' }} />
            </Button>
            <HolidayModal open={openHolidayModal} onClose={() => setOpenHolidayModal(false)} workspaceId={myWorkspaces[0]?.id} />
          </>
        )}

        {isVacations && (
          <Button onClick={handleOpenVacationRequestModal} style={{ color: 'burlywood' }}>
            {t("topbar.vacation_request")} <AddIcon fontSize="large" sx={{ color: '#D6D4D4' }} />
          </Button>

        )}

        {isVacations && (myLoggedUser?.is_admin || myLoggedUser?.is_owner) && (
          <>
            <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#A3989E', marginRight: '20px', marginLeft: '10px' }} />
            <Button onClick={handleOpenApproveVacationsModal} style={{ color: 'burlywood' }}>
              {t("topbar.vacation_approve")} <AddIcon fontSize="large" sx={{ color: '#D6D4D4' }} />
            </Button>
          </>
        )}



        {isWorkflowProjects && !isWorkflowProjectsNew && !isWorkflowProjectsEdit && (
          <>
            {!isProjectDetails && !isProjectArchive && (
              <Button onClick={handleListModalOpen} style={{ color: 'burlywood' }}>
                {t("topbar.add_list")} <AddIcon fontSize="large" sx={{ color: '#D6D4D4' }} />
              </Button>
            )}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button
                id="project-settings-button"
                aria-controls={open_Element1 ? 'project-settings-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open_Element1 ? 'true' : undefined}
                onClick={handleClickProjectMenu}
              >
                {buttonText} <WidgetsIcon fontSize="medium" sx={{ color: '#D6D4D4', marginLeft: '5px' }} />
              </Button>
              <Menu
                id="project-settings-menu"
                anchorEl={anchorEl}
                open={open_Element1}
                onClose={handleCloseProjectMenu}
                MenuListProps={{ 'aria-labelledby': 'project-settings-button' }}
                sx={{
                  '& .MuiPaper-root': {
                    width: '200px',
                    backgroundColor: '#252224',
                    borderColor: '#A3989E',
                  },
                }}
              >
                <MenuItem component={Link} to={`/workflow/projects/${getProjectIDFromNavBar()}`} onClick={handleCloseProjectMenu}>
                  {t("sidebar.project_board")}
                </MenuItem>
                <MenuItem component={Link} to={`/workflow/projects/${getProjectIDFromNavBar()}/details`} onClick={handleCloseProjectMenu}>
                  {t("sidebar.project_details")}
                </MenuItem>
                <MenuItem component={Link} to={`/workflow/projects/${getProjectIDFromNavBar()}/archive`} onClick={handleCloseProjectMenu}>
                  {t("sidebar.project_archives")}
                </MenuItem>
              </Menu>

              <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#A3989E', marginRight: '20px', marginLeft: '10px' }} />

              <CommonAvatarGroup users={projectWorkers} handleAvatarGroupClick={handleAvatarGroupClick} myLoggedUser={myLoggedUser} />

              <Button
                id="basic-button"
                aria-controls={open_Element2 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open_Element2 ? 'true' : undefined}
                onClick={handleClickProjectMemberMenu}
              >
                <GroupAddIcon sx={{ marginRight: '15px', marginLeft: '10px', fontSize: '40px' }} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                open={open_Element2}
                onClose={handleCloseProjectMemberMenu}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                sx={{
                  '& .MuiPaper-root': {
                    width: '300px',
                    backgroundColor: '#252224',
                    borderColor: '#A3989E',
                  },
                  '& .MuiAvatar-root': {
                    border: '2px solid #000000',
                    '&:hover': {
                      borderColor: '#FFD700',
                    },
                  },
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                    {t("modal.members")}
                  </Typography>
                  <TextField
                    id="outlined-search"
                    sx={{ marginBottom: '15px' }}
                    label="Search field"
                    type="search"
                    value={searchValue}
                    onChange={handleSearchChange}
                    inputProps={{ style: { color: '#FFF' } }}
                  />
                  <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                    {t("topbar.project_members")}
                  </Typography>
                  {filteredProjectWorkers.length === 0 ? (
                    <Typography variant="body2" sx={{ paddingBottom: '10px' }}>
                      {t("topbar.no_users_project")}
                    </Typography>
                  ) : (
                    filteredProjectWorkers.map((user) => (
                      <div
                        key={user.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                          marginLeft: '12px',
                          marginRight: 'auto',
                          width: '100%',
                        }}
                      >
                        <a href={`/workflow/profile/${user.id}`}>
                          <UserOnlineStatus
                            lastOnline={user.last_online}
                            workingHours={user.working_hours}
                            userTimezone={user.timezone}
                            userTimezoneOffset={user.timezone_offset}
                            loggedInUserTimezone={myLoggedUser.timezone}
                            loggedInUserTimezoneOffset={myLoggedUser.timezone_offset}
                            showBadgeOnly={true}

                          >
                            <Avatar alt={user.full_name} src={process.env.REACT_APP_BACKEND_URL + user.image} />
                          </UserOnlineStatus>
                        </a>
                        <Typography variant="body2" sx={{ marginLeft: '12px' }}>
                          {user.full_name}
                        </Typography>
                        <div style={{ marginLeft: 'auto', marginRight: '15px' }}>

                          {(myLoggedUser.is_admin || myLoggedUser.is_owner) &&
                            user.id !== loggedUserID &&
                            !user.is_owner && (
                              <Button onClick={() => handleRemoveUserFromProject(user.id)}>{t("topbar.remove")}</Button>
                            )}

                          {user.is_owner && (
                            <Tooltip title={t("topbar.project_owner")}>
                              <BrightnessHighIcon sx={{ color: '#FFF', marginRight: '15px', marginLeft: 'auto' }} />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    ))
                  )}
                  <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                    {t("sidebar.workspace_members")}
                  </Typography>
                  {filteredWorkspaceWorkers.length === 0 ? (
                    <Typography variant="body2">{t("no_users_workspace")}</Typography>
                  ) : (
                    filteredWorkspaceWorkers.map((user) => (
                      <div
                        key={user.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                          marginLeft: '12px',
                          marginRight: 'auto',
                          width: '100%',
                        }}
                      >
                        <a href={`/workflow/profile/${user.id}`}>
                          <UserOnlineStatus
                            lastOnline={user.last_online}
                            workingHours={user.working_hours}
                            userTimezone={user.timezone}
                            userTimezoneOffset={user.timezone_offset}
                            loggedInUserTimezone={myLoggedUser.timezone}
                            loggedInUserTimezoneOffset={myLoggedUser.timezone_offset}
                            showBadgeOnly={true}
                          >
                            <Avatar alt={user.full_name} src={process.env.REACT_APP_BACKEND_URL + user.image} />
                          </UserOnlineStatus>
                        </a>
                        <Typography variant="body2" sx={{ marginLeft: '12px' }}>
                          {user.full_name}
                        </Typography>
                        <div style={{ marginLeft: 'auto', marginRight: '15px' }}>
                          <Button onClick={() => handleAddUserToProject(user.id)}>{t("topbar.add")}</Button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </Menu>
            </div>
          </>
        )}
        {(isWorkflowIndex || isWorkflowSettings || isWorkflowMembers || isWorkflowCalendar) && myWorkspaces[0] && (
          <>
            <div className='MainWorkspaceButtons' style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

              {(!isWorkflowCalendar) && (
                <>
                  <Button
                    id="workspace-settings-button"
                    aria-controls={open_Element1 ? 'workspace-settings-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open_Element1 ? 'true' : undefined}
                    onClick={handleClickProjectMenu}
                  >
                    {buttonWorkspaceText} <WidgetsIcon fontSize="medium" sx={{ color: '#D6D4D4', marginLeft: '5px' }} />
                  </Button><Menu
                    id="workspace-settings-menu"
                    anchorEl={anchorEl}
                    open={open_Element1}
                    onClose={handleCloseProjectMenu}
                    MenuListProps={{ 'aria-labelledby': 'workspace-settings-button' }}
                    sx={{
                      '& .MuiPaper-root': {
                        width: 'auto',
                        backgroundColor: '#252224',
                        borderColor: '#A3989E',
                      },
                    }}
                  >
                    <MenuItem component={Link} to={`/workflow/index`} onClick={handleCloseProjectMenu}>
                      {t("sidebar.projects")}
                    </MenuItem>
                    <MenuItem component={Link} to={`/workflow/workspace/${myWorkspaces[0].id}/members`} onClick={handleCloseProjectMenu}>
                      {t("sidebar.workspace_members")}
                    </MenuItem>
                    {(myLoggedUser?.is_admin || myLoggedUser?.is_owner) && (
                      <MenuItem component={Link} to={`/workflow/workspace/${myWorkspaces[0].id}/settings`} onClick={handleCloseProjectMenu}>
                        {t("sidebar.workspace_settings")}
                      </MenuItem>
                    )}
                  </Menu>
                  <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#A3989E', marginRight: '20px', marginLeft: '10px' }} />
                </>
              )}
              <CommonAvatarGroup users={workspaceWorkersRaw} handleAvatarGroupClick={handleAvatarGroupClick} myLoggedUser={myLoggedUser} />
              <Button
                id="basic-button"
                aria-controls={open_Element2 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open_Element2 ? 'true' : undefined}
                onClick={handleClickProjectMemberMenu}
              >
                <GroupAddIcon sx={{ marginRight: '15px', marginLeft: '10px', fontSize: '40px' }} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                open={open_Element2}
                onClose={handleCloseProjectMemberMenu}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                sx={{
                  '& .MuiPaper-root': {
                    width: '300px',
                    backgroundColor: '#252224',
                    borderColor: '#A3989E',
                  },
                  '& .MuiAvatar-root': {
                    border: '2px solid #000000',
                    '&:hover': {
                      borderColor: '#FFD700',
                    },
                  },
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                    {t("modal.members")}
                  </Typography>
                  <TextField
                    id="outlined-search"
                    sx={{ marginBottom: '15px' }}
                    label={t("topbar.search_field")}
                    type="search"
                    value={searchValue}
                    onChange={handleSearchChange}
                    inputProps={{ style: { color: '#FFF' } }}
                  />
                  <Typography variant="body1" sx={{ marginBottom: '10px', marginLeft: '20px' }}>
                    {t("sidebar.workspace_members")} <Button onClick={handleNewWorkSpaceMemberModalOpen} sx={{ marginLeft: '-20px', marginRightLeft: '-20px' }}>
                      <AddIcon fontSize="medium" sx={{ color: '#D6D4D4' }} />
                    </Button>
                  </Typography>
                  {workspaceWorkersRaw.length === 0 ? (
                    <Typography variant="body2">{t("no_users_workspace")}</Typography>
                  ) : (
                    workspaceWorkersRaw.map((user) => (
                      <div key={user.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginLeft: '12px', marginRight: 'auto', width: '100%' }}>
                        <a href={`/workflow/profile/${user.id}`}>
                          <UserOnlineStatus
                            lastOnline={user.last_online}
                            workingHours={user.working_hours}
                            userTimezone={user.timezone}
                            userTimezoneOffset={user.timezone_offset}
                            loggedInUserTimezone={myLoggedUser.timezone}
                            loggedInUserTimezoneOffset={myLoggedUser.timezone_offset}
                            showBadgeOnly={true}
                          >
                            <Avatar alt={user.full_name} src={process.env.REACT_APP_BACKEND_URL + user.image} />
                          </UserOnlineStatus>
                        </a>
                        <Typography variant="body2" sx={{ marginLeft: '12px' }}>
                          {user.full_name}
                        </Typography>
                        {user.is_owner && (
                          <Tooltip title={t("profile.workspace_owner")}>
                            <TokenIcon sx={{ color: '#FFF', marginRight: '10%', marginLeft: 'auto' }} />
                          </Tooltip>
                        )}
                        {user.is_admin && (
                          <Tooltip title={t("profile.workspace_admin")}>
                            <BrightnessAutoIcon sx={{ color: '#FFF', marginRight: '10%', marginLeft: 'auto' }} />
                          </Tooltip>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </Menu>
            </div>
          </>
        )}
        <Button className='NotifButton' sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', MarginRight: '0' }} onClick={handleNotifOpen}>
          <Badge
            badgeContent={myNotifs && myNotifs.filter((notification) => !notification.is_read).length > 0 ? myNotifs.filter((notification) => !notification.is_read).length.toString() : '0'}
            sx={{}}
            color="error"
            invisible={!(myNotifs && myNotifs.some((notification) => !notification.is_read))}
          >
            <NotificationsIcon fontSize="large" style={{ color: '#fff' }} />
          </Badge>
        </Button>

        <a href={`/workflow/profile/${getLoggedUser()}`}>
          <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar
              sx={{
                border: '2px solid #000000',
                '&:hover': {
                  borderColor: '#FFFFFF', // Change border color to white on hover
                },
              }}
              alt={myUserInMemberFormat?.username}
              src={process.env.REACT_APP_BACKEND_URL + myUserInMemberFormat?.image}
            />
          </StyledBadge>
        </a>
        <NotificationMenu
          anchorEl={notifAnchorEl}
          open={Boolean(notifAnchorEl)}
          onClose={handleNotifClosed}
          unreadNotifications={unreadNotifications}
          handleNotificationClick={handleNotificationClick}
          FormatTimestamp={FormatTimestamp}
          handleWorkspaceInvite={handleWorkspaceInvite}
        />
      </Toolbar>
      <VacationRequestModal
        open={isVacationRequestModalOpen}
        handleCloseVacationRequestModal={handleCloseVacationRequestModal}
        myUserInMemberFormat={myUserInMemberFormat}
        workspace_id={myWorkspaces[0]?.id}
      />
      <VacationApprovalModal
        open={modalOpenApproveVacations}
        handleCloseApproveVacationsModal={handleCloseApproveVacationsModal}
        workspace_id={myWorkspaces[0]?.id}
      />
      <ViewEventsModal open={viewEventsOpen} onClose={handleCloseViewEventsModal} workspaceId={myWorkspaces[0]?.id} />
      <CreateEventsModal open={createEventsOpen} onClose={handleCloseCreateEventsModal} workspaceId={myWorkspaces[0]?.id} />
    </AppBar>
  );
};

export default TopBar;
