import React, { useState, useEffect } from 'react';
import { useAuthorization } from 'contexts/AuthContextProvider';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { TextField, Button, Typography, Container, Grid, Box, Paper, Tabs, Tab } from '@mui/material';
import WorkspaceAdminIcon from '@mui/icons-material/BrightnessAuto';
import WorkspaceWorkerIcon from '@mui/icons-material/Person';
import DeleteWorkspaceModal from 'components/Modals/DeleteWorkspace';
import { useService } from 'contexts/ServiceContextProvider';
import axios from 'axios';
import PurchasePlanModal from "../components/Modals/PurchasePlanModal";
import CheckoutModal from "../components/Modals/CheckoutModal";
import CustomFallback from "../components/Custom/CustomFallBack";
import SubscriptionModal from "../components/Modals/SubscriptionModal";
import CancelSubscriptionModal from "../components/Modals/CancelSubscriptionModal";
import SuspendSubscriptionModal from "../components/Modals/SuspendSubscriptionModal";
import {useTranslation} from "react-i18next";


const WorkspaceSettings = () => {

    const { t } = useTranslation();

    const [workspace, setWorkspace] = useState(null);
    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [companyMail, setCompanyMail] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [adminCount, setAdminCount] = useState(0);
    const [workerCount, setWorkerCount] = useState(0);
    const [plans, setPlans] = useState([]);
    const [extras, setExtras] = useState([]);
    const [workerMax, setWorkerMax] = useState(0);
    const [storage, setStorage] = useState(0);
    const [storageMax, setStorageMax] = useState(0);
    const [activePlan, setActivePlan] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [openCheckoutModal, setOpenCheckoutModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { getLoggedUser } = useAuthorization();
    const loggedUserID = getLoggedUser();
    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;
    const { updateWorkspaceData, deleteWorkspace, fetchPlans, fetchExtras, purchasePlan, fetchSubscriptions, cancelSubscription, suspendSubscription } = useService();

    const [workspaceOwner, setWorkspaceOwner] = useState('');
    const [openDeleteWorkspaceModal, setOpenDeleteWorkspaceModal] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [openPlanModal, setOpenPlanModal] = useState(false);
    const [paypalFormHtml, setPaypalFormHtml] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
    const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] = useState(false);
    const [openSuspendSubscriptionModal, setOpenSuspendSubscriptionModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [currentTransactions, setCurrentTransactions] = useState([]);


    useEffect(() => {
        if (myWorkspaces && myWorkspaces[0]) {
            setWorkspace(myWorkspaces[0]);
            setName(myWorkspaces[0].name);
            setBio(myWorkspaces[0].bio);
            setCompanyMail(myWorkspaces[0].company_mail);
            setAdminCount(myWorkspaces[0]?.admins?.length ?? 0);
            setWorkerCount(myWorkspaces[0]?.workers?.length ?? 0);
            if (myWorkspaces[0].image) {
                setImagePreview(myWorkspaces[0].image);
            }
            setWorkerMax(myWorkspaces[0]?.active_plan.amount_members ?? 0);
            setStorage(myWorkspaces[0]?.storage_size??0);
            setStorageMax(myWorkspaces[0]?.active_plan.storage ?? 0);
            setWorkspaceOwner(myWorkspaces[0].owner);
            setActivePlan(myWorkspaces[0]?.active_plan);
        }
    }, [myWorkspaces]);

    const getPlans = async () => {
        try {
            const response = await fetchPlans();
            setPlans(response);
        } catch (error) {
            console.error('Error fetching plans:', error);
        }
    }

    const getExtras = async () => {
        try {
            const response = await fetchExtras();
            setExtras(response);
        } catch (error) {
            console.error('Error fetching extras:', error);
        }
    }

    const getSubscriptions = async () => {
        try {
            setIsLoading(true);
            const response = await fetchSubscriptions(workspace.id); // Fetch subscriptions related to the workspace
            const sortedSubscriptions = response.sort((a, b) => {
                if (a.subscription.status === 'ACTIVE' && b.subscription.status !== 'ACTIVE') return -1;
                if (a.subscription.status !== 'ACTIVE' && b.subscription.status === 'ACTIVE') return 1;
                return 0;
            });
            setSubscriptions(sortedSubscriptions);
        } catch (error) {
            console.error('Error fetching subscriptions:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getPlans();
        getExtras();
    }, []);

    useEffect(() => {
        if(workspace && workspace.id !== null){
            getSubscriptions();
        }
    }, [workspace]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleDeleteWorkspaceModalClose = () => {
        setOpenDeleteWorkspaceModal(false);
    };

    const handleDeleteWorkspaceModalOpen = () => {
        setOpenDeleteWorkspaceModal(true);
    };

    const handleArchiveWorkspace = async () => {
        const response = await deleteWorkspace(myWorkspaces[0].id);
        if (response.status === 204) {
            window.location.href = `/workflow/index`;
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImageFile(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setImageFile(null);
        setImagePreview('');
    };

    const handleSave = async () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('bio', bio);
        formData.append('company_mail', companyMail);
        if (imageFile) {
            formData.append('image', imageFile);
        }

        try {
            const response = await updateWorkspaceData(myWorkspaces[0].id, formData);
            setWorkspace(response.data);
            window.location.href = "/workflow/index";
        } catch (error) {
            console.error('Error saving workspace:', error);
        }
    };

    const handleOpenPlanModal = (plan) => {
        setSelectedPlan(plan);
        setOpenPlanModal(true);
    };

    const handleOpenCheckoutModal = (plan) => {
        setOpenCheckoutModal(true);
    };

    const handleOpenCheckOut = async (plan, extras) => {
        setIsLoading(true);
        try {
            const response = await purchasePlan(myWorkspaces[0].id, plan, extras);

            // Redirect the user to the PayPal payment page
            //setPaypalFormHtml(response.paypal_form_html);
            //setOpenCheckoutModal(true);
            if (response && response.approval_url) {
                // Redirect to PayPal payment page
                window.location.href = response.approval_url;
            }else{
                console.error('Approval URL not found in the response:', response);
            }
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.error('Error purchasing plan:', error);
        }
    };

    // Sort plans with active plan first
    const sortedPlans = React.useMemo(() => {
        if (!activePlan) return plans;
        const activePlanIndex = plans.findIndex(plan => plan.id === activePlan.main_plan.id);
        if (activePlanIndex > -1) {
            return [
                plans[activePlanIndex],
                ...plans.slice(0, activePlanIndex),
                ...plans.slice(activePlanIndex + 1)
            ];
        }
        return plans;
    }, [plans, activePlan]);


    const handleOpenSubscriptionModal = (subscription) => {
        setSelectedSubscription(subscription);
        setOpenSubscriptionModal(true);
    };

    const handleCloseSubscriptionModal = () => {
        setOpenSubscriptionModal(false);
    };

    const handleOpenCancelSubscriptionModal = (subscription) => {
        setOpenCancelSubscriptionModal(true);
    };

    const handleCloseCancelSubscriptionModal = () => {
        setOpenCancelSubscriptionModal(false);
        setSelectedSubscription(null);
    };

    const handleOpenSuspendSubscriptionModal = (subscription) => {
        setOpenSuspendSubscriptionModal(true);
    };

    const handleCloseSuspendSubscriptionModal = () => {
        setOpenSuspendSubscriptionModal(false);
        setSelectedSubscription(null);
    };

    const handleConfirmCancelSubscription = async (subscription_id)  => {
        setIsLoading(true);
        try {
            const response = await  cancelSubscription(workspace.id, subscription_id);

            if (response) {
                window.location.href = response.url;
            }
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.error('Error canceling subscription:', error);
        }
    };


    const handleConfirmSuspendSubscription = async (subscription_id)  => {
        setIsLoading(true);
        try {
            const response = await  suspendSubscription(workspace.id, subscription_id);

            if (response) {
                window.location.href = response.url;
            }
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.error('Error purchasing plan:', error);
        }
    };

    return (
        <>

                    <Container>
                        {/* Tabs Header */}
                        <Box sx={{ width: '100%', marginBottom: '25px' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="workspace tabs">
                                <Tab label={t('workspace_settings.overview')} />
                                <Tab label={t('workspace_settings.settings')} />
                                <Tab label={t('workspace_settings.plans')} />
                                <Tab label="Subscriptions" />
                            </Tabs>
                        </Box>

                        {/* Tabs Content */}
                        {tabValue === 0 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "flex-start", alignItems: "center", marginBottom: "25px" }}>
                                    <Paper elevation={3} sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', padding: 1, width: 155, marginRight: "10px" }}>
                                        <WorkspaceAdminIcon color="primary" />
                                        <Typography variant="body2">{t('workspace_settings.admin_count', {count: adminCount})}</Typography>
                                    </Paper>
                                    <Paper elevation={3} sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', padding: 1, width: 155, marginRight: "10px" }}>
                                        <WorkspaceWorkerIcon color="primary" />
                                        <Typography variant="body2">{t('workspace_settings.workers_count', {count: workerCount, max: workerMax})}</Typography>
                                    </Paper>
                                    <Paper elevation={3} sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', padding: 1, width: 155, marginRight: "10px" }}>
                                        <WorkspaceAdminIcon color="primary" />
                                        <Typography variant="body2">{t('workspace_settings.storage', {storage: storage, max: storageMax})}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginBottom: "25px" }}>
                                    <Typography variant="h3">{t('workspace_settings.active_plan')}</Typography>
                                    {activePlan && (
                                        <Paper key={activePlan?.id} elevation={3} sx={{ padding: 2, marginBottom: 2, color: 'black' }}>
                                            <Typography variant="h6">{activePlan?.main_plan?.name}</Typography>
                                            <Typography variant="body1">{t('workspace_settings.plan_members')}: {activePlan?.amount_members}</Typography>
                                            <Typography variant="body1">{t('workspace_settings.plan_storage')}: {activePlan?.storage} GB</Typography>
                                            <Typography variant="body1">{t('workspace_settings.plan_price')}: {activePlan?.price}€</Typography>
                                            <Typography variant="body2">{activePlan?.description}</Typography>
                                        </Paper>
                                    )}
                                </Grid>
                                <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginBottom: "25px" }}>
                                    <Typography variant="h3">{t('workspace_settings.extras')}</Typography>
                                    {activePlan?.extras.length > 0 ? (
                                        activePlan?.extras.map((extra) => (
                                                <Paper key={activePlan?.id} elevation={3} sx={{ padding: 2, marginBottom: 2, color: 'black' }}>
                                                    <Typography variant="h6">{extra?.name}</Typography>
                                                    {extra?.amount_members > 0 ? (<Typography variant="body1">{t('workspace_settings.plan_members')}: {extra?.amount_members}</Typography>) : ''}
                                                    {extra?.storage > 0 ? (<Typography variant="body1">{t('workspace_settings.plan_storage')}: {extra.storage} GB</Typography>) : ''}
                                                    <Typography variant="body1">{t('workspace_settings.plan_price')}: {extra?.price}€</Typography>
                                                    <Typography variant="body2">{extra?.description}</Typography>
                                                </Paper>
                                            )
                                        )
                                    ):(
                                        <Typography variant="body1">{t('workspace_settings.no_extras')}</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginBottom: "25px" }}>
                                    <Typography variant="h3">{t('workspace_settings.features')}</Typography>
                                    {activePlan?.features.length > 0 ? (
                                        activePlan?.features.map((feature) => (
                                                <Paper key={activePlan?.id} elevation={3} sx={{ padding: 2, marginBottom: 2, color: 'black' }}>
                                                    <Typography variant="h6">{feature?.name}</Typography>
                                                    <Typography variant="body2" component="div" dangerouslySetInnerHTML={{ __html: feature?.description }} />
                                                </Paper>
                                            )
                                        )
                                    ):(
                                        <Typography variant="body1">{t('workspace_settings.no_features')}</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        )}

            {tabValue === 1 && workspace && (
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('workspace_settings.workspace_name')}
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                InputLabelProps={{ style: { color: '#FFF' } }}
                                InputProps={{ style: { color: 'white' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('workspace_settings.workspace_email')}
                                variant="outlined"
                                value={companyMail}
                                onChange={(e) => setCompanyMail(e.target.value)}
                                InputLabelProps={{ style: { color: '#FFF' } }}
                                InputProps={{ style: { color: 'white' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('workspace_settings.workspace_bio')}
                                variant="outlined"
                                multiline
                                rows={4}
                                value={bio}
                                onChange={(e) => setBio(e.target.value)}
                                InputLabelProps={{ style: { color: '#FFF' } }}
                                InputProps={{ style: { color: 'white' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                accept="image/*"
                                id="workspace-image-input"
                                type="file"
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="workspace-image-input">
                                <Button variant="contained" component="span">
                                    {t('workspace_settings.upload_image')}
                                </Button>
                            </label>
                            <Typography>{t('workspace_settings.rec_image_size')}</Typography>
                            {imagePreview && (
                                <Box mt={2} display="flex" flexDirection="column" alignItems="center">
                                    <img src={imagePreview} alt="Workspace" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }} />
                                    <Button variant="contained" color="secondary" onClick={handleRemoveImage} sx={{ marginTop: 1 }}>
                                        {t('workspace_settings.rem_image')}
                                    </Button>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: "50px", display:"flex", flexDirection:"row", justifyContent:"space-between" }}>
                            <Button variant="contained" color="primary" onClick={handleSave}>
                                {t('workspace_settings.save')}
                            </Button>
                            {workspaceOwner && loggedUserID && workspaceOwner === loggedUserID && (
                                <Button
                                    onClick={handleDeleteWorkspaceModalOpen}
                                    color="error"
                                    variant="contained"
                                    component="span"
                                    sx={{
                                        width: "155px",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('workspace_settings.delete')}
                                </Button>
                            )}
                        </Grid>
                        <DeleteWorkspaceModal
                            openDeleteWorkspaceModal={openDeleteWorkspaceModal}
                            handleDeleteWorkspaceModalClose={handleDeleteWorkspaceModalClose}
                            handleArchiveWorkspace={handleArchiveWorkspace}
                        />
                    </Grid>
                </form>
            )}

            {tabValue === 2 && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>{t('workspace_settings.sub_plans')}</Typography>
                    </Grid>
                    {sortedPlans?.length > 0 ? (
                        sortedPlans.map((plan) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={plan.id}>
                                <Paper elevation={3} sx={{ padding: 2, marginBottom: 2, textAlign:"center" }}>
                                    <Typography variant="h4">{plan.name}</Typography>
                                    <br/>
                                    <Typography variant="body2">{plan.description}</Typography>
                                    <br/>
                                    <Typography variant="h3">{plan.price}€</Typography>
                                    <Typography variant="body1">{t('workspace_settings.per_month')}</Typography>

                                                <br/>
                                                <Typography variant="body1">{t('workspace_settings.features')}</Typography>
                                                <Typography variant="body2">{t('workspace_settings.plan_members')}: {plan.amount_members}</Typography>
                                                <Typography variant="body2">{t('workspace_settings.plan_storage')}: {plan.storage} GB</Typography>
                                                <br/>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleOpenPlanModal(plan)}
                                                >
                                                    {activePlan && activePlan.main_plan.id === plan.id ? t('workspace_settings.add_extras') : t('workspace_settings.purchase')}
                                                </Button>
                                            </Paper>
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid item xs={12}>
                                        <Typography variant="body1">{t('workspace_settings.no_plans')}</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {tabValue === 3 && (
                        <Grid container spacing={2}>
                            {isLoading ? (
                                <CustomFallback />
                            ) : (<>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>Subscriptions</Typography>
                            </Grid>
                            { subscriptions && subscriptions.length > 0 ? (
                                subscriptions.map((subscription) => (
                                    <Grid item xs={12} sm={6} md={4} key={subscription.id}>
                                        <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                                            <Typography variant="h5">Plan: {subscription?.plan?.main_plan?.name}</Typography>
                                            <Typography variant="body1">Start Date: {new Date(subscription.subscription.start_time).toLocaleDateString()}</Typography>
                                            <Typography variant="body1">Status: {subscription.subscription.status}</Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={()=>handleOpenSubscriptionModal(subscription)}
                                            >
                                                View Transactions
                                            </Button>

                                            {transactions.length > 0 && (
                                                <Box mt={2}>
                                                    <Typography variant="h6">Transactions:</Typography>
                                                    {transactions.map((transaction) => (
                                                        <Paper key={transaction.id} elevation={2} sx={{ padding: 1, marginTop: 1 }}>
                                                            <Typography variant="body2">Date: {new Date(transaction.date).toLocaleDateString()}</Typography>
                                                            <Typography variant="body2">Amount: {transaction.amount}€</Typography>
                                                            <Typography variant="body2">Status: {transaction.status}</Typography>
                                                        </Paper>
                                                    ))}
                                                </Box>
                                            )}
                                        </Paper>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="body1">No subscriptions found.</Typography>
                                </Grid>
                            )}
                            </> )}
                        </Grid>
                    )}
                        {/* Modal for viewing transactions and editing subscription */}
                        {selectedSubscription && (
                            <>
                                <SubscriptionModal
                                    open={openSubscriptionModal}
                                    onClose={handleCloseSubscriptionModal}
                                    subscription={selectedSubscription.subscription}
                                    plan={selectedSubscription.plan}
                                    transactions={currentTransactions}
                                    handleCancelSubscription={handleOpenCancelSubscriptionModal}
                                    handleSuspendSubscription={handleOpenSuspendSubscriptionModal}
                                />

                                <CancelSubscriptionModal
                                    open={openCancelSubscriptionModal}
                                    onClose={handleCloseCancelSubscriptionModal}
                                    subscription={selectedSubscription?.subscription}
                                    handleConfirmCancelSubscription={handleConfirmCancelSubscription}
                                />

                                <SuspendSubscriptionModal
                                    open={openSuspendSubscriptionModal}
                                    onClose={handleCloseSuspendSubscriptionModal}
                                    subscription={selectedSubscription?.subscription}
                                    handleConfirmSuspendSubscription={handleConfirmSuspendSubscription}
                                />
                            </>
                        )}




                        {/* Plan Modal */}
                        {selectedPlan && (
                            <PurchasePlanModal
                                open={openPlanModal}
                                onClose={() => setOpenPlanModal(false)}
                                plan={selectedPlan}
                                extras={extras}
                                handleOpenCheckOut={handleOpenCheckOut}
                                currentWorkspace={workspace}
                            />
                        )}
                        <CheckoutModal
                            open={openCheckoutModal}
                            onClose={()=> setOpenCheckoutModal(false)}
                            workspaceId ={myWorkspaces[0]?.id}
                            paypalFormHtml = {paypalFormHtml}
                        />

                        {/* Delete Workspace Modal */}
                        <DeleteWorkspaceModal
                            open={openDeleteWorkspaceModal}
                            onClose={handleDeleteWorkspaceModalClose}
                            onDelete={handleArchiveWorkspace}
                        />
                    </Container>
    </>
    );
};

export default WorkspaceSettings;
