import React, { useState, useEffect } from "react";
import {
    Modal,
    Box,
    Typography,
    Button,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    CircularProgress,
    TextField,
    ListItemSecondaryAction,
    Tooltip
} from "@mui/material";
import { useService } from 'contexts/ServiceContextProvider';
import { useAuthorization } from "contexts/AuthContextProvider";
import SubjectIcon from '@mui/icons-material/Subject';
import WarningIcon from '@mui/icons-material/Warning';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {useTranslation} from "react-i18next";

const VacationApprovalModal = ({ open, handleCloseApproveVacationsModal, workspace_id }) => {
    const { t } = useTranslation();

    const { accessToken } = useAuthorization();
    const [pendingVacations, setPendingVacations] = useState([]);
    const [selectedVacations, setSelectedVacations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const { GetPendingVacationRequests, UpdatePendingVacationRequest, getUserRemainingWorkspaceVacationDays } = useService();
    const [tooltipTitles, setTooltipTitles] = useState({});

    // New state for refutation modal
    const [openModal, setOpenModal] = useState(false);
    const [currentEventId, setCurrentEventId] = useState(null);
    const [changeReason, setChangeReason] = useState('');

    useEffect(() => {
        if (open) {
            fetchPendingVacations();
        }
    }, [open]);

    const fetchPendingVacations = async () => {
        setLoading(true);
        try {
            const response = await GetPendingVacationRequests(workspace_id);
            //console.log(response.data)
            setPendingVacations(response.data);
        } catch (error) {
            console.error("Error fetching pending vacations:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleToggle = (vacationId) => {
        setSelectedVacations((prevSelected) => {
            if (prevSelected.includes(vacationId)) {
                return prevSelected.filter((id) => id !== vacationId);
            } else {
                return [...prevSelected, vacationId];
            }
        });
    };

    const handleAction = async (action) => {
        try {
            await UpdatePendingVacationRequest(workspace_id, selectedVacations, action);
            fetchPendingVacations();
            setSelectedVacations([]);
        } catch (error) {
            console.error(`Error ${action}ing vacations:`, error);
        }
    };

    const handleOpenRefuteModal = (eventId) => {
        setCurrentEventId(eventId);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setChangeReason('');
    };

    const handleSuggestChanges = async () => {
        try {
            await UpdatePendingVacationRequest(workspace_id, [currentEventId], 'request_changes', changeReason);
            fetchPendingVacations();
            handleCloseModal();
        } catch (error) {
            console.error('Error suggesting changes:', error);
        }
    };

    const filteredVacations = pendingVacations?.filter((vacation) =>
        vacation.user.full_name?.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    const getFullVacationTypeName = (type) => {
        switch (type) {
            case "RV":
                return t("calendar.vacation_type1");
            case "SL":
                return t("calendar.vacation_type2");
            case "PL":
                return t("calendar.vacation_type3");
            case "MPL":
                return t("calendar.vacation_type4");
            default:
                return t("calendar.vacation_type5");
        }
    };

    const getBackgroundPattern = (type) => {
        if (!type) {
            return "white";  // Default color if type is undefined
        }

        let userColor = "white";
        switch (type) {
            case "RV":
                userColor = "blue";
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 10 10'><path d='M0 0 L10 10 M-1 9 L9 -1 M1 11 L11 1' stroke='rgba(255, 255, 255, 0.5)' stroke-width='1' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            case "SL":
                userColor = "red";
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'><path d='M0 0 L10 10 M10 0 L0 10' stroke='rgba(255, 255, 255, 0.5)' stroke-width='3' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            case "PL":
                userColor = "yellow";
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'><path d='M0 3 L6 3' stroke='rgba(255, 255, 255, 0.5)' stroke-width='4' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            case "MPL":
                userColor = "pink";
                return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><rect x='1' y='1' width='6' height='6' fill='none' stroke='rgba(255, 255, 255, 0.5)' stroke-width='3' /></svg>"), linear-gradient(${userColor}, ${userColor})`;
            default:
                return userColor;
        }
    };

    const handleTooltipOpen = async (workspace_id, user_id, vacation_id) => {
        const response = await getUserRemainingWorkspaceVacationDays(workspace_id, user_id);
        if (response && response.data) {
            const vacationDays = response.data;
            setTooltipTitles((prevTitles) => ({
                ...prevTitles,
                [vacation_id]: (
                    <Box sx={{ backgroundColor: '#2f2f2f', padding: '1rem', borderRadius: '8px', marginTop: "4px", marginBottom: "4px", height: 'auto', alignSelf: 'flex-start', flexGrow: 1 }}>
                        <Typography variant="body1" component="div" sx={{ color: "#cfcfcf", display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                            <strong>{t("calendar.vacation_user_info")}</strong>
                        </Typography>
                        <Box>
                            <Typography variant="body2" component="div" sx={{ color: '#cfcfcf' }}>
                                {t("calendar.vacation_per_year", {count: vacationDays.remaining_vacation_days})}
                            </Typography>
                            <Typography variant="body2" component="div" sx={{ color: '#cfcfcf' }}>
                                {t("calendar.vacation_rollovers", {count: vacationDays.rollover_days})}
                            </Typography>
                            <Typography variant="body2" component="div" sx={{ color: '#cfcfcf' }}>
                                {t("calendar.vacation_used_days", {count: vacationDays.used_vacation_days})}
                            </Typography>
                            <Typography variant="body2" component="div" sx={{ color: '#cfcfcf' }}>
                                {t("calendar.vacation_total_days", {count: vacationDays.total_available_days})}
                            </Typography>
                        </Box>
                    </Box>
                ),
            }));
        } else {
            setTooltipTitles((prevTitles) => ({
                ...prevTitles,
                [vacation_id]: 'Error fetching remaining vacation days',
            }));
        }
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseApproveVacationsModal}
                aria-labelledby="vacation-approval-modal-title"
                aria-describedby="vacation-approval-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography
                        id="vacation-approval-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ color: "#ffffff" }}
                    >
                        {t("calendar.vacation_pending")}
                    </Typography>
                    <TextField
                        label={t("calendar.search_users_by_name")}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{ color: "#ffffff", backgroundColor: "#2f2f2f" }}
                        InputLabelProps={{ style: { color: "#cfcfcf" } }}
                        InputProps={{
                            style: { color: "#ffffff", backgroundColor: "#2f2f2f" },
                        }}
                    />
                    {loading ? (
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <List>
                                {filteredVacations?.map((vacation) => {
                                    const vacationTypeName = getFullVacationTypeName(vacation.type);
                                    const visibilityStatus = vacation.visibility
                                        ? t("calendar.vacation_public")
                                        : t("calendar.vacation_private");
                                    const vacationDescription = vacation.description;

                                    const warningBorder = vacation.do_not_deduct
                                        ? {
                                            border: '4px solid',
                                            borderColor: 'transparent',
                                            borderImage: 'linear-gradient(45deg, yellow 6.25%, black 6.25%, black 12.5%, yellow 12.5%, yellow 18.75%, black 18.75%, black 25%, yellow 25%, yellow 31.25%, black 31.25%, black 37.5%, yellow 37.5%, yellow 43.75%, black 43.75%, black 50%, yellow 50%, yellow 56.25%, black 56.25%, black 62.5%, yellow 62.5%, yellow 68.75%, black 68.75%, black 75%, yellow 75%, yellow 81.25%, black 81.25%, black 87.5%, yellow 87.5%, yellow 93.75%, black 93.75%, black 100%)',
                                            borderImageSlice: 1,
                                            borderRadius: '4px',
                                            padding: 2
                                        }
                                        : { padding: 2 };

                                    return (
                                        <ListItem
                                            key={vacation.id}
                                            disableGutters
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                padding: "10px 0",
                                                ...warningBorder
                                            }}
                                        >
                                            <Checkbox
                                                edge="start"
                                                checked={selectedVacations.includes(vacation.id)}
                                                onChange={() => handleToggle(vacation.id)}
                                                sx={{ color: "#ffffff" }}
                                            />
                                            <ListItemText
                                                primary={
                                                    <span>
                                                        <Typography sx={{ color: "#ffffff" }}>
                                                            {vacation.title}
                                                        </Typography>
                                                        <span style={{ color: "#ff4081" }}>
                                                            ({vacation.date_start} {t("calendar.vacation_to")} {vacation.date_end})
                                                        </span>
                                                    </span>
                                                }
                                                primaryTypographyProps={{ sx: { color: "#ffffff" } }}
                                                secondary={
                                                    <><Box sx={{ display: "flex", alignItems: "center" }}>
                                                        {(vacationDescription) && (
                                                            <Tooltip title={vacationDescription} arrow>
                                                                <SubjectIcon sx={{
                                                                    color: "#ffffff",
                                                                    border: "1px dashed #ffffff",
                                                                    borderRadius: "4px",
                                                                    padding: "2px",
                                                                    marginRight: "8px",
                                                                }} />
                                                            </Tooltip>
                                                        )}
                                                        <Typography>
                                                            {vacationTypeName} -
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                marginLeft: "5px",
                                                                color: visibilityStatus === 'Public' ? 'lightgreen' : 'tomato',
                                                                backgroundColor: visibilityStatus === 'Public' ? 'darkgreen' : 'darkred',
                                                                padding: '2px 4px',
                                                                borderRadius: '4px'
                                                            }}
                                                        >
                                                            {visibilityStatus}
                                                        </Typography>
                                                        <Tooltip
                                                            title={tooltipTitles[vacation.id]}
                                                            arrow
                                                            onOpen={() => handleTooltipOpen(workspace_id, vacation.user.id, vacation.id)}
                                                        >
                                                            <CalendarTodayIcon sx={{
                                                                color: "#ffffff",
                                                                marginLeft: "8px",
                                                                cursor: "pointer"
                                                            }} />
                                                        </Tooltip>
                                                    </Box>
                                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                            {vacation.do_not_deduct && (
                                                                <><br />
                                                                    <Typography sx={{ display: "flex" }}>
                                                                        <WarningIcon sx={{ marginRight: "2px" }} color="warning" /> {t("calendar.vacation_warning")} <WarningIcon sx={{ marginLeft: "2px" }} color="warning" /> <br />
                                                                    </Typography>
                                                                    <Typography sx={{ display: "flex" }}>
                                                                        {t("calendar.vacation_warning_info")}
                                                                    </Typography>
                                                                </>
                                                            )}
                                                        </Box>
                                                    </>
                                                }
                                            />
                                            <ListItemSecondaryAction
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",   
                                                    marginRight: "20px",
                                                    
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",          
                                                        flexDirection: "row",   
                                                        alignItems: "center",   
                                                        gap: 1,       
                                                         
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: 30,
                                                            height: 30,
                                                            display: "inline-block",
                                                            backgroundSize: "cover",
                                                            backgroundImage: getBackgroundPattern(vacation.type),
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                    <Typography sx={{ color: "#ffffff" }}>
                                                        {vacation.type}
                                                    </Typography>
                                                </Box>

                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#ff9800', color: '#ffffff', mt: 1 }}
                                                    onClick={() => handleOpenRefuteModal(vacation.id)}
                                                >
                                                    {t("calendar.vacation_refute_button")}
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                    )}
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "darkgreen", color: "lightgreen" }}
                            onClick={() => handleAction('approve')}
                            disabled={selectedVacations?.length === 0}
                        >
                            {t("calendar.vacation_approve")}
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "darkred", color: "tomato", ml: 2 }}
                            onClick={() => handleAction('decline')}
                            disabled={selectedVacations?.length === 0}
                        >
                            {t("calendar.vacation_decline")}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ color: "#ffffff", borderColor: "#cfcfcf", ml: 3 }}
                            onClick={handleCloseApproveVacationsModal}
                        >
                            {t("calendar.event_cancel")}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{ p: 4, backgroundColor: '#2B2B2B', borderRadius: '8px', width: 400, margin: 'auto', marginTop: '15%', }}>
                    <Typography variant="h6" sx={{ mb: 2, color: '#D3D3D3' }}>{t("calendar.vacation_refute")}</Typography>
                    <TextField
                        label={t("calendar.vacation_refute_reason")}
                        multiline
                        fullWidth
                        value={changeReason}
                        onChange={(e) => setChangeReason(e.target.value)}
                        rows={4}
                        variant="outlined"
                        InputLabelProps={{
                            style: { color: '#D3D3D3' },
                        }}
                        InputProps={{
                            style: { color: '#D3D3D3', backgroundColor: '#3B3B3B' },
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#4F4F4F',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#6A6A6A',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#9A9A9A',
                                },
                            },
                        }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                        <Button
                            variant="contained"
                            sx={{ color: '#ffffff' }}
                            onClick={handleSuggestChanges}
                        >
                            Submit
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ color: '#ffffff' }}
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '500px',
    minWidth: '500px',
    maxHeight: '90vh',
    bgcolor: "#1c1c1c",
    border: "2px solid #2f2f2f",
    boxShadow: 24,
    p: 3,
    overflowY: 'auto',
    marginLeft: "120px"
};

export default VacationApprovalModal;
