import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {useTranslation} from "react-i18next";

export default function DeleteSecretModal({ openConfirmationModal, handleCloseConfirmationModal, handleDeleteSecret, avoidMisfire}) {
    const { t } = useTranslation();
    
    return (
        <Dialog open={openConfirmationModal} onClose={handleCloseConfirmationModal} sx={{

            '& .MuiPaper-root': {
                background: '#252224'
            }
        }}>
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent sx={{color:"white"}}>
                {t("modal.secret_delete")}
            </DialogContent>
            <DialogContent sx={{color:"white", display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center"}}>
            <WarningIcon sx={{marginRight:"15px"}} color="warning"/> {t("modal.irreversible")} <WarningIcon sx={{marginLeft:"15px"}} color="warning"/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseConfirmationModal} color="primary">
                    {t("calendar.event_cancel")}
                </Button>
                <Button onClick={handleDeleteSecret} disabled={avoidMisfire} color="primary">
                    {t("modal.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}