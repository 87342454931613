import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import './i18n';
import App from './app';
import { createRoot } from 'react-dom/client';
import React from 'react';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App tab="home" />);
