import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Typography, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import {useTranslation} from "react-i18next";

export default function NewSecretModal({ openSecretModal, handleSecretModalClose, handleCreateSecret, avoidMisfire, accessType }) {
    const { t } = useTranslation();


    const [visibility, setVisibility] = React.useState(accessType && accessType === 2 ? "Owner" : accessType && accessType === 1 ? "Admin" : "User");
    const [title, setTitle] = useState("");
    const [secret, setSecret] = useState("");



    const handleVisibilityChange = (event) => {
        setVisibility(event.target.value);
    };

    const handleSaveSecret = () => {
        if (title.trim() === "" || secret.trim() === "") {
            // If Title or Secret is empty, prevent saving
            return;
        }
        // Call handleCreateSecret function if Title and Secret are not empty
        handleCreateSecret(title, secret, visibility);
    };



    const renderVisibilityMessage = () => {
        switch (visibility) {
            case 'Owner':
                return t("modal.secret_visibility_owner");
            case 'Admin':
                return t("modal.secret_visibility_admin");
            case 'User':
            default:
                return t("modal.secret_visibility_user");
        }
    };

    return (
        <Dialog open={openSecretModal} onClose={handleSecretModalClose} sx={{
            '& .MuiPaper-root': {
                background: '#252224'
            }
        }}>
            <DialogTitle>Enter your Secret</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label={t("calendar.event_title")}
                    type="text"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    InputLabelProps={{
                        style: { color: '#FFF' }
                    }}
                    InputProps={{
                        style: { color: 'white' }
                    }}
                />
            </DialogContent>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="secret"
                    label={t("modal.secret")}
                    type="password"
                    fullWidth
                    value={secret}
                    onChange={(e) => setSecret(e.target.value)}
                    InputLabelProps={{
                        style: { color: '#FFF' }
                    }}
                    InputProps={{
                        style: { color: 'white' }
                    }}
                />
                <Typography sx={{ width: "450px", color: "white" }}> {t("modal.password_encrypt")}</Typography>
            </DialogContent>
            <DialogContent>
                <TextField
                    select
                    margin="dense"
                    id="visibility"
                    label={t("project_details.visibility")}
                    fullWidth
                    value={visibility}
                    onChange={handleVisibilityChange}
                    InputLabelProps={{
                        style: { color: '#FFF' }
                    }}
                    InputProps={{
                        style: { color: 'white' }
                    }}
                >
                    {accessType === 2 && (
                        <MenuItem value="Owner">{t("modal.owner")}</MenuItem>
                    )}
                    {(accessType !== 0) && (
                        <MenuItem value="Admin">{t("modal.admin")}</MenuItem>
                    )}

                    <MenuItem value="User">{t("modal.user")}</MenuItem>
                </TextField>
                <Typography sx={{ width: "450px" }}>
                    {renderVisibilityMessage()}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSecretModalClose} color="primary">
                    {t("calendar.event_cancel")}
                </Button>
                <Button onClick={handleSaveSecret} disabled={avoidMisfire || title.trim() === "" || secret.trim() === ""} color="primary">
                    {t("modal.save_secret")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}