import React from 'react';
import { Card, CardContent } from '@mui/material';
import ContentLoader from 'react-content-loader';

const SkeletonRecentProjectStatusWrapper = () => (
  <Card sx={{ backgroundColor: '#252224', mb: 2, width: '100%' }}>
    <CardContent>
      <ContentLoader
        speed={2}
        width="100%"
        height={150}
        backgroundColor="#3D393B"
        foregroundColor="#4A494B"
      >
        <rect x="0" y="20" rx="5" ry="5" width="100%" height="20" />
        <rect x="0" y="60" rx="5" ry="5" width="100%" height="20" />
        <rect x="0" y="100" rx="5" ry="5" width="100%" height="20" />
      </ContentLoader>
    </CardContent>
  </Card>
);

export default SkeletonRecentProjectStatusWrapper;