import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Switch,
    FormControlLabel,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO } from 'date-fns';
import { useService } from 'contexts/ServiceContextProvider';
import { useSidebar } from 'contexts/SidebarContextProvider';
import { useParams } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const RefuteRequestModal = ({ open, handleClose, refutedVacations, handleDelete }) => {
    const { t } = useTranslation();

    const { DeleteVacationRequest, UpdatePendingVacationRequest, getMyRemainingWorkspaceVacationDays } = useService();
    const { isSidebarOpen } = useSidebar();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [error, setError] = useState('');
    const [totalDays, setTotalDays] = useState(0);
    const [totalWeekdays, setTotalWeekdays] = useState(0);

    const { workspaceId } = useParams();

    const currentVacation = refutedVacations?.[currentIndex];

    const [vacationDays, setVacationDays] = useState({});

    useEffect(() => {
        async function fetchVacationDays() {
            if (workspaceId && currentVacation) {
                try {
                    const response = await getMyRemainingWorkspaceVacationDays(workspaceId);
                    setVacationDays(response.data);
                } catch (error) {
                    console.error('Failed to fetch vacation days:', error);
                }
            }
        }

        fetchVacationDays();
    }, [workspaceId, currentVacation]);

    // State for vacation data
    const [vacationData, setVacationData] = useState({
        title: '',
        description: '',
        type: 'RV',
        date_start: new Date(),
        date_end: new Date(),
        visibility: false,
        workspaceId: workspaceId || '',
        do_not_deduct: false,
    });

    useEffect(() => {
        if (currentVacation) {
            setVacationData({
                title: currentVacation.title || '',
                description: currentVacation.description || '',
                type: 'RV',
                date_start: currentVacation.date_start ? parseISO(currentVacation.date_start) : new Date(),
                date_end: currentVacation.date_end ? parseISO(currentVacation.date_end) : new Date(),
                visibility: currentVacation.visibility || false,
                workspaceId: workspaceId || '',
                do_not_deduct: currentVacation.do_not_deduct || false,
            });
        }
    }, [currentVacation, workspaceId]);

    // Calculate total days and weekdays
    useEffect(() => {
        const start = vacationData.date_start instanceof Date && !isNaN(vacationData.date_start) ? vacationData.date_start : new Date();
        const end = vacationData.date_end instanceof Date && !isNaN(vacationData.date_end) ? vacationData.date_end : new Date();
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        setTotalDays(diffDays);

        const weekdaysCount = getWeekdaysCount(start, end);
        setTotalWeekdays(weekdaysCount);
    }, [vacationData.date_start, vacationData.date_end]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setVacationData({
            ...vacationData,
            [name]: value,
        });
    };

    const handleDateChange = (name, date) => {
        let newVacationData = { ...vacationData, [name]: date };

        if (name === 'date_start' && date > vacationData.date_end) {
            setError(t("calendar.vacation_err1"));
        } else if (name === 'date_end' && date < vacationData.date_start) {
            setError(t("calendar.vacation_err2"));
        } else {
            setError('');

            const start = new Date(newVacationData.date_start);
            const end = new Date(newVacationData.date_end);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            setTotalDays(diffDays);

            const weekdaysCount = getWeekdaysCount(start, end);
            setTotalWeekdays(weekdaysCount);
        }

        setVacationData(newVacationData);
    };

    const getWeekdaysCount = (start, end) => {
        let count = 0;
        const current = new Date(start);
        while (current <= end) {
            const day = current.getDay();
            if (day !== 0 && day !== 6) count++;
            current.setDate(current.getDate() + 1);
        }
        return count;
    };

    const handleVisibilityChange = (event) => {
        setVacationData({
            ...vacationData,
            visibility: event.target.checked,
        });
    };

    const handleDeductabilityChange = (event) => {
        setVacationData({
            ...vacationData,
            do_not_deduct: event.target.checked,
        });
    };

    const handleSubmit = () => {
        if (vacationData.date_start > vacationData.date_end) {
            setError(t("calendar.vacation_err1"));
            return;
        }

        const formattedData = {
            ...vacationData,
            date_start: format(vacationData.date_start, 'yyyy-MM-dd'),
            date_end: format(vacationData.date_end, 'yyyy-MM-dd'),
        };

        UpdatePendingVacationRequest(workspaceId, [currentVacation.id], 'resubmit', '', formattedData);

        if (currentIndex < refutedVacations.length - 1) {
            setCurrentIndex(currentIndex + 1);
        } else {
            handleClose();
        }
    };

    const handleDeleteVacation = async () => {
        try {
            await DeleteVacationRequest(workspaceId, [currentVacation.id]);
    
            // Move to the next refuted vacation request, or close if all are resolved
            if (currentIndex < refutedVacations.length - 1) {
                setCurrentIndex(currentIndex + 1);
            } else {
                handleClose(); // All refuted requests are resolved
            }
        } catch (error) {
            console.error('Error deleting vacation:', error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => { }}
            aria-labelledby="refute-request-modal-title"
            aria-describedby="refute-request-modal-description"
            sx={{ marginLeft: isSidebarOpen() ? '240px' : '0px' }}
        >
            <Box sx={modalStyle}>
                <Typography id="refute-request-modal-title" variant="h6" component="h2" sx={{ color: '#ffffff' }}>
                    {t("calendar.vacation_resubmit", {count1: currentIndex + 1, count2: refutedVacations?.length})}
                </Typography>

                {/* Display the admin's refutal message */}
                {currentVacation?.refute_description && (
                    <Box sx={{ backgroundColor: '#2f2f2f', padding: '1rem', borderRadius: '8px', marginBottom: '1rem' }}>
                        <Typography variant="body2" sx={{ color: '#ff9800' }}>
                            <strong>{t("calendar.vacation_feedback")}</strong> {currentVacation.refute_description}
                        </Typography>
                    </Box>
                )}

                <TextField
                    label={t("calendar.event_description")}
                    name="description"
                    value={vacationData.description}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={3}
                    InputLabelProps={{ style: { color: '#cfcfcf' } }}
                    InputProps={{
                        style: { color: '#ffffff', backgroundColor: '#2f2f2f' },
                    }}
                    onChange={handleChange}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel sx={{ color: '#cfcfcf' }}>Type</InputLabel>
                    <Select
                        label={t("calendar.vacation_type")}
                        name="type"
                        value={vacationData.type}
                        onChange={handleChange}
                        variant="outlined"
                        sx={{ color: '#ffffff', backgroundColor: '#2f2f2f' }}
                    >
                        <MenuItem value="RV">{t("calendar.vacation_type1")}</MenuItem>
                        <MenuItem value="SL">{t("calendar.vacation_type2")}</MenuItem>
                        <MenuItem value="PL">{t("calendar.vacation_type3")}</MenuItem>
                        <MenuItem value="MPL">{t("calendar.vacation_type4")}</MenuItem>
                    </Select>
                </FormControl>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <Box sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                        <DatePicker
                            selected={vacationData.date_start}
                            onChange={(date) => handleDateChange('date_start', date)}
                            dateFormat="yyyy-MM-dd"
                            selectsStart
                            startDate={vacationData.date_start}
                            endDate={vacationData.date_end}
                            customInput={
                                <TextField
                                    label={t("calendar.event_start_date")}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ style: { color: '#cfcfcf' } }}
                                    InputProps={{
                                        style: { color: '#ffffff', backgroundColor: '#2f2f2f' },
                                    }}
                                />
                            }
                        />
                        <DatePicker
                            selected={vacationData.date_end}
                            onChange={(date) => handleDateChange('date_end', date)}
                            dateFormat="yyyy-MM-dd"
                            selectsEnd
                            startDate={vacationData.date_start}
                            endDate={vacationData.date_end}
                            minDate={vacationData.date_start}
                            customInput={
                                <TextField
                                    label={t("calendar.event_end_date")}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{ style: { color: '#cfcfcf' } }}
                                    InputProps={{
                                        style: { color: '#ffffff', backgroundColor: '#2f2f2f' },
                                    }}
                                />
                            }
                        />
                    </Box>
                    <Box sx={{ backgroundColor: '#2f2f2f', padding: '1rem', borderRadius: '8px', marginTop: "16px", height: '143px', alignSelf: 'flex-start', flexGrow: 1 }}>
                        <Typography variant="body1" sx={{ color: "#cfcfcf", display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                            <strong>{t("calendar.vacation_info")}</strong>
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#cfcfcf' }}>
                            {t("calendar.vacation_per_year", {count: vacationDays.remaining_vacation_days})}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#cfcfcf' }}>
                            {t("calendar.vacation_rollovers", {count: vacationDays.rollover_days})}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#cfcfcf' }}>
                            {t("calendar.vacation_used_days", {count: vacationDays.used_vacation_days})}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#cfcfcf' }}>
                            {t("calendar.vacation_total_days", {count: vacationDays.total_available_days})}
                        </Typography>
                    </Box>
                </div>

                <Typography variant="subtitle1" sx={{ color: '#cfcfcf', marginTop: '1rem' }}>
                    {t("calendar.vacation_total_count", {count1: totalDays, count2: totalWeekdays})}
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={vacationData.visibility}
                                onChange={handleVisibilityChange}
                                name="visibility"
                                color="primary"
                            />
                        }
                        label={t("calendar.event_public")}
                        sx={{ color: '#cfcfcf', mt: 1 }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={vacationData.do_not_deduct}
                                onChange={handleDeductabilityChange}
                                name="do_not_deduct"
                                color="primary"
                            />
                        }
                        label={t("calendar.vacation_no_deduct")}
                        sx={{ color: '#cfcfcf', mt: 1 }}
                    />
                </Box>

                {error && (
                    <Typography variant="body2" sx={{ color: 'red', mt: 1 }}>
                        {error}
                    </Typography>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#ffffff', color: '#1c1c1c' }}
                        onClick={handleSubmit}
                        disabled={!!error}
                    >
                        Resubmit
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ color: '#ffffff', borderColor: '#f44336', ml: 1 }}
                        onClick={handleDeleteVacation}
                    >
                        Delete
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#1c1c1c',
    border: '2px solid #2f2f2f',
    boxShadow: 24,
    p: 4,
};

export default RefuteRequestModal;
