
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, TextField, Box, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';

// Ask Paulo for approval of the new styling for button, to achieve consistency
const StyledDialog = styled(Dialog)(({ }) => ({
    '& .MuiPaper-root': {
        backgroundColor: '#252225',
        color: '#a3989e',
    }
}));

const StyledButton = styled(Button)(({ }) => ({
    backgroundColor: '#3d393b',
    color: '#a3989e',
    '&:hover': {
        backgroundColor: '#a3989e',
        color: '#252225',
    }
}));

const CoverPickerModal = ({ open, setCoverModalOpen, saveNewCoverImage, cover_image, cover_color }) => {
    const [color, setColor] = useState(null);
    const [image, setImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        if (cover_image) {
            setImage(cover_image);
            setColor('');
        } else {
            setColor(cover_color);
            setImage(null);
            setImageFile(null);
        }
    }, [cover_image, cover_color, open]);

    const handleColorChange = (event) => {
        setColor(event.target.value);
        setImage(null);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
            setImageFile(file);
            setColor('');
        }
    };

    const handleClearImage = () => {
        setImage(null);
        setImageFile(null);
        setColor(null)
    };

    const handleClearColor = () => {
        setColor(null);
    };

    const handleClose = () => {
        setCoverModalOpen(false);
    };

    const handleSave = () => {
        saveNewCoverImage(imageFile, color);
        handleClose();
    };

    return (
        <StyledDialog open={open} onClose={handleClose}  >
            <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>Change the Cover</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: "300px" }}>
                    {!image && (
                        <TextField
                            type="color"
                            fullWidth
                            value={color}
                            onChange={handleColorChange}
                            sx={{ width: '100%', input: { color: color } }} // Ensures the color picker is visible
                        />
                    )}
                    <StyledButton
                        variant="contained"
                        component="label"
                    >
                        Upload Image
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleImageChange}
                            
                        />
                    </StyledButton>
                        {image && (
                            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={image} alt="Preview" style={{ maxHeight: '200px', maxWidth: '340px' }} />
                                <StyledButton onClick={handleClearImage} sx={{ mt: 1 }}>
                                    Clear Image
                                </StyledButton>
                            </Box>
                        )}
                        {!image && color !== null && (
                            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <StyledButton onClick={handleClearColor} sx={{ mt: 1 }}>
                                Clear Color
                            </StyledButton>
                            </Box>
                        )}
                </Box>
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={handleClose}>Cancel</StyledButton>
                <StyledButton onClick={handleSave}>Save</StyledButton>
            </DialogActions>
        </StyledDialog >
    );
};

export default CoverPickerModal;
