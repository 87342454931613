import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  Tooltip,
  FormControlLabel,
} from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale/pt';
import { de } from 'date-fns/locale/de';
import { useService } from 'contexts/ServiceContextProvider';
import { useTranslation } from "react-i18next";


import InfoIcon from '@mui/icons-material/Info';

import { useSidebar } from 'contexts/SidebarContextProvider';

const VacationRequestModal = ({ open, handleCloseVacationRequestModal, myUserInMemberFormat, workspace_id }) => {
  const { t } = useTranslation();
  registerLocale('pt', pt);
  registerLocale('de', de);

  const { submitNewVacation, getMyRemainingWorkspaceVacationDays } = useService();
  const { isSidebarOpen } = useSidebar();
  const [vacationDays, setVacationDays] = useState({});

  useEffect(() => {
    async function fetchVacationDays() {
      if (workspace_id) {
        try {
          const response = await getMyRemainingWorkspaceVacationDays(workspace_id);
          setVacationDays(response.data);
          //console.log("test", response.data)
        } catch (error) {
          console.error('Failed to fetch vacation days:', error);
        }
      }
    }

    fetchVacationDays();
  }, [workspace_id, getMyRemainingWorkspaceVacationDays]);


  // State for vacation data
  const [vacationData, setVacationData] = useState({
    title: '', // Initialize as an empty string
    description: '', // New description field
    type: 'RV',
    date_start: new Date(),
    date_end: new Date(),
    visibility: false,
    workspace_id: workspace_id || '',
    do_not_deduct: false,
  });

  const [error, setError] = useState('');
  const [totalDays, setTotalDays] = useState(0);
  const [totalWeekdays, setTotalWeekdays] = useState(0);

  // Ensure that the title is updated only when myUserInMemberFormat is available
  useEffect(() => {
    if (myUserInMemberFormat && myUserInMemberFormat.full_name) {
      setVacationData((prevState) => ({
        ...prevState,
        title: `${t("calendar.vacation_person", { person: myUserInMemberFormat.full_name })}`,
      }));
    }
  }, [myUserInMemberFormat]);

  // Set workspace ID if it changes
  useEffect(() => {
    if (workspace_id) {
      setVacationData((prevState) => ({
        ...prevState,
        workspace_id: workspace_id,
      }));
    }
  }, [workspace_id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVacationData({
      ...vacationData,
      [name]: value,
    });
  };

  const handleDateChange = (name, date) => {
    let newVacationData = { ...vacationData, [name]: date };

    if (name === 'date_start' && date > vacationData.date_end) {
      setError(t("calendar.vacation_err1"));
    } else if (name === 'date_end' && date < vacationData.date_start) {
      setError(t("calendar.vacation_err2"));
    } else {
      setError('');

      const start = new Date(newVacationData.date_start);
      const end = new Date(newVacationData.date_end);
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
      setTotalDays(diffDays);

      const weekdaysCount = getWeekdaysCount(start, end);
      setTotalWeekdays(weekdaysCount);
    }

    setVacationData(newVacationData);
  };

  useEffect(() => {
    if (myUserInMemberFormat && myUserInMemberFormat.full_name) {
      let titleSuffix = t("calendar.vacation_suffix1"); // Default for RV which is the one we always start
      switch (vacationData.type) {
        case 'SL':
          titleSuffix = t("calendar.vacation_suffix2");
          break;
        case 'PL':
          titleSuffix = t("calendar.vacation_suffix3");
          break;
        case 'MPL':
          titleSuffix = t("calendar.vacation_suffix4");
          break;
        default:
          titleSuffix = t("calendar.vacation_suffix1");
      }

      setVacationData((prevState) => ({
        ...prevState,
        title: `${myUserInMemberFormat.full_name}${titleSuffix}`,
      }));
    }
  }, [myUserInMemberFormat, vacationData.type]);


  const getWeekdaysCount = (start, end) => {
    let count = 0;
    const current = new Date(start);
    while (current <= end) {
      const day = current.getDay();
      if (day !== 0 && day !== 6) count++;
      current.setDate(current.getDate() + 1);
    }
    return count;
  };

  const handleVisibilityChange = (event) => {
    setVacationData({
      ...vacationData,
      visibility: event.target.checked,
    });
  };

  const handleDeductabilityChange = (event) => {
    setVacationData({
      ...vacationData,
      do_not_deduct: event.target.checked,
    });
  };

  const handleSubmit = () => {
    if (vacationData.date_start > vacationData.date_end) {
      setError(t("calendar.vacation_err1"));
      return;
    }

    const formattedData = {
      ...vacationData,
      date_start: format(vacationData.date_start, 'yyyy-MM-dd'),
      date_end: format(vacationData.date_end, 'yyyy-MM-dd'),
    };
    submitNewVacation(formattedData, workspace_id);
    handleClose();
  };

  const handleClose = () => {
    setVacationData({
      title: `${myUserInMemberFormat.full_name || ''}'s Vacation`,
      description: '',
      type: 'RV',
      date_start: new Date(),
      date_end: new Date(),
      visibility: false,
      workspace_id: workspace_id || '',
    });
    handleCloseVacationRequestModal();
  };


  const formattedHistory = (past_rollovers) => {
    const rolloverHistory = vacationDays.rollover_days_history
      ? JSON.parse(past_rollovers)
      : {};

    return Object.entries(rolloverHistory)
      .map(([year, days]) => `${year}: ${days} days`)
      .join('<br />');
  };



  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="vacation-request-modal-title"
      aria-describedby="vacation-request-modal-description"
      sx={{ marginLeft: isSidebarOpen() ? '240px' : '0px' }}
    >
      <Box sx={modalStyle}>
        <Typography id="vacation-request-modal-title" variant="h6" component="h2" sx={{ color: '#ffffff' }}>
          {t("calendar.vacation_request")}
        </Typography>
        <TextField
          label={t("calendar.event_title")}
          name="title"
          value={vacationData.title}
          fullWidth
          margin="normal"
          variant="outlined"
          disabled
          InputLabelProps={{ style: { color: '#cfcfcf' } }}
          InputProps={{
            style: { color: '#ffffff', backgroundColor: '#2f2f2f' },
          }}
        />
        <TextField
          label={t("calendar.event_description")}
          name="description"
          value={vacationData.description}
          fullWidth
          margin="normal"
          variant="outlined"
          multiline
          rows={3}
          InputLabelProps={{ style: { color: '#cfcfcf' } }}
          InputProps={{
            style: { color: '#ffffff', backgroundColor: '#2f2f2f' },
          }}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel sx={{ color: '#cfcfcf' }}>Type</InputLabel>
          <Select
            label={t("calendar.vacation_type")}
            name="type"
            value={vacationData.type}
            onChange={handleChange}
            variant="outlined"
            sx={{ color: '#ffffff', backgroundColor: '#2f2f2f' }}
          >
            <MenuItem value="RV">{t("calendar.vacation_type1")}</MenuItem>
            <MenuItem value="SL">{t("calendar.vacation_type2")}</MenuItem>
            <MenuItem value="PL">{t("calendar.vacation_type3")}</MenuItem>
            <MenuItem value="MPL">{t("calendar.vacation_type4")}</MenuItem>
          </Select>
        </FormControl>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Box sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <DatePicker
              selected={vacationData.date_start}
              onChange={(date) => handleDateChange('date_start', date)}
              dateFormat="yyyy-MM-dd"
              selectsStart
              startDate={vacationData.date_start}
              endDate={vacationData.date_end}
              customInput={
                <TextField
                  label={t("calendar.event_start_date")}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ style: { color: '#cfcfcf' } }}
                  InputProps={{
                    style: { color: '#ffffff', backgroundColor: '#2f2f2f' },
                  }}
                />
              }
              locale={t("calendar.picker_lng")}
            />
            <DatePicker
              selected={vacationData.date_end}
              onChange={(date) => handleDateChange('date_end', date)}
              dateFormat="yyyy-MM-dd"
              selectsEnd
              startDate={vacationData.date_start}
              endDate={vacationData.date_end}
              minDate={vacationData.date_start}
              customInput={
                <TextField
                  label={t("calendar.event_end_date")}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ style: { color: '#cfcfcf' } }}
                  InputProps={{
                    style: { color: '#ffffff', backgroundColor: '#2f2f2f' },
                  }}
                />
              }
              locale={t("calendar.picker_lng")}
            />
          </Box>
          <Box sx={{ backgroundColor: '#2f2f2f', padding: '1rem', borderRadius: '8px', marginTop: "16px", height: '143px', alignSelf: 'flex-start', flexGrow: 1 }}>
            <Typography variant="body1" sx={{ color: "#cfcfcf", display: "flex", justifyContent: "center", marginBottom: "10px" }}>
              <strong>{t("calendar.vacation_info")}</strong>
            </Typography>
            <Typography variant="body2" sx={{ color: '#cfcfcf' }}>
              {t("calendar.vacation_per_year", { count: vacationDays.remaining_vacation_days })}
            </Typography>
            <Typography variant="body2" sx={{ color: '#cfcfcf' }}>
              {t("calendar.vacation_rollovers", { count: vacationDays.rollover_days })}
              <Tooltip
                title={<span dangerouslySetInnerHTML={{ __html: formattedHistory(vacationDays.rollover_days_history) }} />}
              >
                <InfoIcon style={{ color: 'white' }} />
              </Tooltip>
            </Typography>
            <Typography variant="body2" sx={{ color: '#cfcfcf' }}>
              {t("calendar.vacation_used_days", { count: vacationDays.used_vacation_days })}
            </Typography>
            <Typography variant="body2" sx={{ color: '#cfcfcf' }}>
              {t("calendar.vacation_total_days", { count: vacationDays.total_available_days })}
            </Typography>
          </Box>
        </div>


        <Typography variant="subtitle1" sx={{ color: '#cfcfcf', marginTop: '1rem' }}>
          {t("calendar.vacation_total_count", { count1: totalDays, count2: totalWeekdays })}
        </Typography>

        {error && (
          <Typography variant="body2" sx={{ color: 'red', mt: 1 }}>
            {error}
          </Typography>
        )}
        <Box sx={{ display: "flex" }}>
          <FormControlLabel
            control={
              <Switch
                checked={vacationData.visibility}
                onChange={handleVisibilityChange}
                name="visibility"
                color="primary"
              />
            }
            label={t("calendar.event_public")}
            sx={{ color: '#cfcfcf', mt: 1 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={vacationData.do_not_deduct}
                onChange={handleDeductabilityChange}
                name="visibility"
                color="primary"
              />
            }
            label={t("calendar.vacation_no_deduct")}
            sx={{ color: '#cfcfcf', mt: 1 }}
          />
        </Box>
        {(!vacationData.visibility || vacationData.do_not_deduct) && (
          <Box sx={{ border: '1px dashed #cfcfcf', borderRadius: 2, p: 2, mt: 1 }}>
            {!vacationData.visibility && (
              <Typography variant="body2" sx={{ color: '#cfcfcf', mb: 1 }}>
                {t("calendar.vacation_admins_info1")}
              </Typography>
            )}
            {vacationData.do_not_deduct && (
              <Typography variant="body2" sx={{ color: '#cfcfcf' }}>
                {t("calendar.vacation_admins_info2")}
              </Typography>
            )}
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#ffffff', color: '#1c1c1c' }}
            onClick={handleSubmit}
            disabled={!!error}
          >
            {t("calendar.vacation_submit")}
          </Button>
          <Button
            variant="outlined"
            sx={{ color: '#ffffff', borderColor: '#cfcfcf', ml: 1 }}
            onClick={handleClose}
          >
            {t("calendar.event_cancel")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#1c1c1c',
  border: '2px solid #2f2f2f',
  boxShadow: 24,
  p: 4,
};



export default VacationRequestModal;
