import React, { useState, useEffect } from 'react';
import {
  Box,
  Modal,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import {useTranslation} from "react-i18next";

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const groupLogsByProject = (logs, t) => {
  const groupedLogs = {};

  logs.forEach((log) => {
    const projectName = log.project_name || t("core.no_project");
    
    if (!groupedLogs[projectName]) {
      groupedLogs[projectName] = [];
    }
    
    groupedLogs[projectName].push(log);
  });

  return groupedLogs;
};

const ModalContent = styled(Box)({
  width: '80%',
  maxHeight: '80vh',
  backgroundColor: '#1E1E1E', // Dark color scheme
  color: '#FFFFFF',
  padding: '20px',
  overflowY: 'auto',
  boxShadow: 24,
  borderRadius: '8px',
});

const SearchInput = styled(TextField)({
  marginBottom: '20px',
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#555555',
    },
    '&:hover fieldset': {
      borderColor: '#888888',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#888888',
    },
  },
});

const UserActivityLogModal = ({ open, handleClose, userId }) => {
  const { t } = useTranslation();

  const [logs, setLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      fetchUserActivityLogs();
    }
  }, [open]);

  const fetchUserActivityLogs = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/boards/user/${userId}/activitylogs/`);
      
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.status} - ${response.statusText}`);
      }
      
      const data = await response.json();
      setLogs(data);
      setFilteredLogs(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user activity logs:', error);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (term) {
      const filtered = logs.filter(
        (log) =>
          (log.project_name && log.project_name.toLowerCase().includes(term)) ||  // Search by project name
          log.log_message.toLowerCase().includes(term) ||
          log.created_at.toLowerCase().includes(term)
      );
      setFilteredLogs(filtered);
    } else {
      setFilteredLogs(logs);
    }
  };

  // Group logs by project
  const groupedLogs = groupLogsByProject(filteredLogs, t);

  return (
    <StyledModal open={open} onClose={handleClose}>
      <ModalContent sx={{maxWidth:600}}>
        <Typography variant="h5" gutterBottom>
          {t("core.activity_logs")}
        </Typography>
        <SearchInput
          variant="outlined"
          placeholder={t("core.activity_logs_search")}
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
          InputProps={{
            style: { color: '#FFFFFF' },
          }}
        />
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
            <CircularProgress />
          </Box>
        ) : (
          Object.keys(groupedLogs).map((projectName) => (
            <Box key={projectName} mb={4}>
              <Typography display="flex" justifyContent={"center"} variant="h6" color="primary" gutterBottom>
                {projectName}
              </Typography>
              <List>
                {groupedLogs[projectName].map((log, index) => (
                  <React.Fragment key={index}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={log.log_message}
                        secondary={
                          <>
                            <Typography variant="body2" color="secondary" component="span">
                              {t("core.task_label")}: {log.task_title || t("core.no_task")} {/* Display task title */}
                            </Typography>
                            <br />
                            <Typography variant="body2" color="secondary" component="span">
                              Date: {new Date(log.created_at).toLocaleString()}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    {index < groupedLogs[projectName].length - 1 && <Divider component="li" />}
                  </React.Fragment>
                ))}
              </List>
            </Box>
          ))
        )}
      </ModalContent>
    </StyledModal>
  );
};

export default UserActivityLogModal;

