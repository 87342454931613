import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { AccountTree as AccountTreeIcon, Star as StarIcon } from '@mui/icons-material';
import {useTranslation} from "react-i18next";

const ProjectList = ({
  setShowMoreOptionsProjects,
  showMoreOptionsProjects,
  myProjects,
  myCurrentProject,
  myWorkspaces,
  projectsContext
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleProjectItemClick = (event, project) => {
    if (event.button === 1) {
      // Handle middle-click
      event.preventDefault();
      window.open(`/workflow/projects/${project.id}`, '_blank'); // Open in new tab
    } else if (event.button === 0) {
      // Handle left-click
      projectsContext.handleProjectChange(project.id);
      navigate(`/workflow/projects/${project.id}`);
    }
  };

  const handleProjectClick = () => {
    setShowMoreOptionsProjects(!showMoreOptionsProjects);
  };

  const handleMouseDown = (event, project) => {
    event.preventDefault(); 
    if (event.button === 1) { // Middle mouse button
        window.open(`/workflow/projects/${project.id}`, '_blank');
    }
};

  const maxHeight = myProjects.length > 1 ? '300px' : 'auto';

  return (
    <List sx={{ maxHeight: showMoreOptionsProjects ? maxHeight : 'none', overflow: showMoreOptionsProjects ? 'auto' : 'hidden' }}>
      <ListItem disablePadding onClick={handleProjectClick} onDoubleClick={() => { window.location.href = '/workflow/index'; projectsContext.setCurrentProject(''); }}>
        <ListItemButton>
          <ListItemIcon>
            <AccountTreeIcon sx={{ color: 'white' }} />
          </ListItemIcon>
          {myProjects[0] ? (
            <ListItemText
              primary={t("sidebar.my_projects")}
              secondary={
                <Typography
                  variant="body2"
                  style={{
                    color: '#67B5D9',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {myCurrentProject}
                </Typography>
              }
            />
          ) : (
            <ListItemText
              primary={t("sidebar.my_projects")}
              secondary={
                <Typography
                  variant="body2"
                  style={{
                    color: '#67B5D9',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {t("sidebar.no_projects")}
                </Typography>
              }
            />
          )}
        </ListItemButton>
      </ListItem>
      {showMoreOptionsProjects && myProjects.map(
        (project) =>
          project.favorites && (
            <ListItemButton
              key={project.id}
              onMouseDown={(event) => handleMouseDown(event, project)} // Changed to onAuxClick
              onClick={(event) => event.button === 0 && handleProjectItemClick(event, project)} // Handle left-click
            >
              <ListItemText primary={project.name} />
              <StarIcon sx={{ fontSize: 'large', color: 'yellow' }} />
            </ListItemButton>
          )
      )}
      {showMoreOptionsProjects && myWorkspaces[0] && (
        <ListItemButton sx={{ display: 'flex', flexDirection: 'column' }} component={Link} to="projects/new">
          <ListItemText primary={t("sidebar.new_project")} />
        </ListItemButton>
      )}
    </List>
  );
};

export default ProjectList;
