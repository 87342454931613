import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import {useTranslation} from "react-i18next";

export default function DeleteChecklistModal({ open, handleDeleteChecklistModalClose, handleCloseAndDelete, avoidMisfire }) {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={handleDeleteChecklistModalClose} sx={{

            '& .MuiPaper-root': {
                background: '#252224'
            }
        }}>
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent sx={ { color: 'white' }}>
                {t("modal.checklist_delete")}<br/>
                {t("modal.checklist_no_return")}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteChecklistModalClose} color="primary">
                    {t("calendar.event_cancel")}
                </Button>
                <Button onClick={handleCloseAndDelete} disabled={avoidMisfire} color="primary">
                    {t("modal.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}