// NotificationProvider.js
import React, { createContext, useState, useContext } from 'react';
import { useService } from 'contexts/ServiceContextProvider';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);




export const NotificationProvider = ({ children }) => {
  const [myNotifs, SetMyNotifs] = useState(null);
  const unreadNotifications = myNotifs ? myNotifs.filter(notification => !notification.is_read) : [];
  const { getUserNotifications } = useService();


  const fetchNotifications = async (loggedUser) => {
    try {
      const notifications = await getUserNotifications(loggedUser);
      SetMyNotifs(notifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
 

  return (
    <NotificationContext.Provider value={{ myNotifs, SetMyNotifs, unreadNotifications , fetchNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};
