import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, TextField, Typography, MenuItem, Select, FormControl, InputLabel, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { useService } from 'contexts/ServiceContextProvider';
import Pagination from '@mui/material/Pagination';
import {useTranslation} from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#333',
    color: '#fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

const HolidayModal = ({ open, onClose, workspaceId }) => {
    const { t } = useTranslation();

    const { fetchWorkspaceHolidays, createHoliday } = useService();
    const [holidays, setHolidays] = useState([]);
    const [filteredHolidays, setFilteredHolidays] = useState([]);
    const [newHoliday, setNewHoliday] = useState({ name: '', local_name: '', date: '', country: '', affects_all_country: false });
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [loading, setLoading] = useState(true);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isLocalSameAsEnglish, setIsLocalSameAsEnglish] = useState(false);
    const holidaysPerPage = 5;

    useEffect(() => {
        if (open && workspaceId) {
            fetchWorkspaceHolidays(workspaceId).then((data) => {
                setHolidays(data);
                setFilteredHolidays(data);
                setLoading(false);

                const uniqueCountries = [...new Set(data.map(holiday => holiday.country || 'Custom'))];
                setCountries(uniqueCountries);
            });
        }
    }, [open, workspaceId, fetchWorkspaceHolidays]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "name" && isLocalSameAsEnglish) {
            setNewHoliday({ ...newHoliday, name: value, local_name: value });
        } else {
            setNewHoliday({ ...newHoliday, [name]: value });
        }
    };

    const handleLocalSameAsEnglishToggle = (e) => {
        setIsLocalSameAsEnglish(e.target.checked);
        if (e.target.checked) {
            setNewHoliday({ ...newHoliday, local_name: newHoliday.name });
        }
    };

    const handleCreateHoliday = () => {
        createHoliday(workspaceId, { ...newHoliday, source: 'Custom' })
            .then((newCreatedHoliday) => {
                setHolidays([...holidays, newCreatedHoliday]);
                setNewHoliday({ name: '', local_name: '', date: '', country: '' });
                setShowCreateForm(false);
                onClose();
            })
            .catch((error) => {
                console.error('Failed to create holiday:', error);
            });
    };

    const handleCountryChange = (e) => {
        const selected = e.target.value;
        setSelectedCountry(selected);

        if (selected === 'Custom') {
            setNewHoliday({ ...newHoliday, country: null });
        } else {
            setNewHoliday({ ...newHoliday, country: selected });
        }

        if (selected === 'Custom') {
            setFilteredHolidays(holidays.filter(holiday => !holiday.country));
        } else if (selected) {
            setFilteredHolidays(holidays.filter(holiday => holiday.country === selected));
        } else {
            setFilteredHolidays(holidays);
        }
    };

    const handleSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchTerm(searchValue);

        const filtered = holidays.filter((holiday) =>
            holiday.name.toLowerCase().includes(searchValue) || holiday.local_name.toLowerCase().includes(searchValue)
        );
        setFilteredHolidays(filtered);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const indexOfLastHoliday = currentPage * holidaysPerPage;
    const indexOfFirstHoliday = indexOfLastHoliday - holidaysPerPage;
    const currentHolidays = filteredHolidays.slice(indexOfFirstHoliday, indexOfLastHoliday);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{marginLeft:"120px"}}
        >
            <Box sx={style}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}} >
                    <Typography variant="h6" component="h2" gutterBottom sx={{ color: '#fff' }}>
                        {showCreateForm ? t("calendar.holiday_create") : t("calendar.holiday_workspace")}
                    </Typography>

                    <Button variant="contained" color="primary" onClick={() => setShowCreateForm(!showCreateForm)}>
                        {showCreateForm ? t("calendar.holiday_view") : t("calendar.holiday_create")}
                    </Button>
                </Box>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                        <CircularProgress sx={{ color: '#fff' }} />
                    </Box>
                ) : showCreateForm ? (
                    <Box mt={2}>
                        <TextField
                            label={t("calendar.holiday_name")}
                            name="name"
                            value={newHoliday.name}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ style: { color: '#fff' } }}
                            sx={{ '& .MuiInputBase-input': { color: '#fff' } }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={isLocalSameAsEnglish} onChange={handleLocalSameAsEnglishToggle} />}
                            label={t("calendar.holiday_mark_local")}
                            sx={{ color: '#fff' }}
                        />
                        <TextField
                            label={t("calendar.holiday_local")}
                            name="local_name"
                            value={newHoliday.local_name}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ style: { color: '#fff' } }}
                            sx={{ '& .MuiInputBase-input': { color: '#fff' } }}
                            disabled={isLocalSameAsEnglish}
                        />
                        <TextField
                            label={t("calendar.holiday_date")}
                            name="date"
                            type="date"
                            value={newHoliday.date}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true, style: { color: '#fff' } }}
                            sx={{ '& .MuiInputBase-input': { color: '#fff' } }}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="country-select-label-create" sx={{ color: '#fff' }}>{t("calendar.holiday_country")}</InputLabel>
                            <Select
                                labelId="country-select-label-create"
                                name="country"
                                value={newHoliday.country || ''}
                                onChange={handleCountryChange}
                                label="Country"
                                sx={{ color: '#fff' }}
                            >
                                <MenuItem value="Custom">{t("calendar.holiday_custom_country")}</MenuItem>
                                {countries
                                    .filter((country) => country !== 'Custom')
                                    .map((country) => (
                                        <MenuItem key={country} value={country}>
                                            {country}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>

                        <Button variant="contained" color="primary" onClick={handleCreateHoliday} sx={{ marginTop: 2 }}>
                            {t("calendar.holiday_create_button")}
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Box mt={2}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="country-select-label-filter" sx={{ color: '#fff' }}>{t("calendar.holiday_country_filter")}</InputLabel>
                                <Select
                                    labelId="country-select-label-filter"
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    label="Filter by Country"
                                    sx={{ color: '#fff' }}
                                >
                                    <MenuItem value="">
                                        <em>{t("calendar.holiday_country_all")}</em>
                                    </MenuItem>
                                    <MenuItem value="Custom">{t("calendar.holiday_custom_country")}</MenuItem>
                                    {countries
                                        .filter((country) => country !== 'Custom')
                                        .map((country) => (
                                            <MenuItem key={country} value={country}>
                                                {country}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            <TextField
                                label={t("calendar.holiday_search_name")}
                                value={searchTerm}
                                onChange={handleSearch}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ style: { color: '#fff' } }}
                                sx={{ '& .MuiInputBase-input': { color: '#fff' } }}
                            />
                        </Box>

                        <Typography variant="subtitle1" gutterBottom sx={{ color: '#fff' }}>
                            {t("calendar.holiday_list")}
                        </Typography>
                        <ul>
                            {currentHolidays.map((holiday) => (
                                <li key={holiday.id} style={{ color: '#fff' }}>
                                    {holiday.local_name} ({holiday.name}) - {holiday.date}
                                </li>
                            ))}
                        </ul>

                        {filteredHolidays.length > holidaysPerPage && (
                            <Pagination
                                count={Math.ceil(filteredHolidays.length / holidaysPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    '& .MuiPaginationItem-root': {
                                        color: '#F5F5F5',
                                    },
                                }}
                            />
                        )}
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default HolidayModal;
