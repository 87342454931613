import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const SettingsPage = () => {
    const { t, i18n } = useTranslation();
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedValue, setSelectedValue] = useState('EN');
    const [selectedValue2, setSelectedValue2] = useState('English');
    const [dropdownWidth, setDropdownWidth] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            handleOptionClick(savedLanguage);
        } else if (i18n.language) {
            handleOptionClick(i18n.language.substring(0, 2).toUpperCase());
        }
    }, []);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const closeDropdown = () => {
        setShowDropdown(false);
    };

    const calculateDropdownWidth = () => {
        const options = document.querySelectorAll('.language-option');
        if (options.length > 0) {
            const maxWidth = Math.max(...Array.from(options).map((option) => option.offsetWidth));
            setDropdownWidth(maxWidth);
        }
    };

    useEffect(() => {
        calculateDropdownWidth();
        window.addEventListener('resize', calculateDropdownWidth);
        return () => {
            window.removeEventListener('resize', calculateDropdownWidth);
        };
    }, [selectedValue]);

    const languages = {
        EN: 'English',
        DE: 'German',
        PT: 'Portuguese'
    };

    const handleOptionClick = (language) => {
        i18n.changeLanguage(language.toLowerCase());
        setSelectedValue(language);
        setSelectedValue2(languages[language]);
        localStorage.setItem('selectedLanguage', language);
        localStorage.setItem('selectedLanguage2', languages[language]);
        closeDropdown();
    };

    const dropdownOptions = Object.entries(languages).filter(
        ([code]) => code !== selectedValue
    );

    useEffect(() => {
        const handleMouseClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        };

        document.addEventListener('mousedown', handleMouseClick);
        return () => {
            document.removeEventListener('mousedown', handleMouseClick);
        };
    }, []);

    return (
        <div style={{ padding: '20px', fontFamily: 'Inter, sans-serif' }}>
            <div style={{ marginBottom: '10px', color: '#C0C0C0', fontWeight: 'bold' }}>
                {t("settings.lng_select")}
            </div>
            <div
                className="language-dropdown"
                style={{
                    position: 'relative',
                    display: 'inline-block',
                    width: '200px',  // Adjust the width as needed
                }}
            >
                <div
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        padding: '10px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        textAlign: 'center',
                        color: 'white',
                    }}
                    onClick={toggleDropdown}
                >
                    <div
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {selectedValue}
                    </div>
                    <div
                        style={{
                            fontWeight: 'lighter',
                        }}
                    >
                        {selectedValue2}
                    </div>
                </div>
                {showDropdown && (
                    <div
                        ref={dropdownRef}
                        style={{
                            position: 'absolute',
                            top: '100%',
                            left: '0',
                            width: '100%',
                            backgroundColor: '#005ac6',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                            zIndex: 1,
                            padding: '10px',
                            textAlign: 'center',
                            borderRadius: '4px',
                        }}
                    >
                        {dropdownOptions.map(([code, name], index) => (
                            <div
                                key={index}
                                className="language-option"
                                style={{
                                    margin: '5px 0',
                                    cursor: 'pointer',
                                    color: 'white',
                                    fontWeight: 'lighter',
                                }}
                                onClick={() => handleOptionClick(code)}
                            >
                                {name}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SettingsPage;
