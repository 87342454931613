//Here should go all the shared app settings to stylize the application to anyone needs
import { styled } from '@mui/material/styles';
import { Dialog, Button } from '@mui/material';

// Styled Dialog
export const StyledDialog = styled(Dialog)(({ }) => ({
    '& .MuiPaper-root': {
        backgroundColor: '#252225',
        color: '#a3989e',
    }
}));

// Styled Button
export const StyledButton = styled(Button)(({ }) => ({
    backgroundColor: '#3d393b',
    color: '#a3989e',
    '&:hover': {
        backgroundColor: '#a3989e',
        color: '#252225',
    }
}));
