import React from 'react';
import {
    Card,
    CardContent,
    Badge,
    Typography,
    Box,
    Avatar,
    IconButton
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UserOnlineStatus from 'components/Modals/UserOnlineChecker';
import {useTranslation} from "react-i18next";

const ProfileCard = ({ user, workingHours, isEditable, selectedFile, handleFileChange, myUserTimezone }) => {
    const { t } = useTranslation();

    const getCountryFlag = (location_string) => {
        const regex = /\(([^)]+)\)/;
        const matches = regex.exec(location_string);
        if (matches && matches.length > 1) {
            return matches[1].toLocaleLowerCase();
        }
    };
    

    return (
        <Card sx={{ backgroundColor: '#252224', mb: 2, marginRight: "30px" }}>
            <CardContent sx={{ textAlign: 'center' }}>
                {isEditable ? (
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <IconButton component="label">
                                <CloudUploadIcon sx={{ color: 'white' }} />
                                <input
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </IconButton>
                        }
                    >
                        <Avatar
                            src={selectedFile ? URL.createObjectURL(selectedFile) : user.image}
                            alt="avatar"
                            sx={{ width: 150, height: 150, margin: 'auto', mb: 2 }}
                        />
                    </Badge>
                ) : (
                    <Avatar
                        src={selectedFile ? URL.createObjectURL(selectedFile) : user.image}
                        alt="avatar"
                        sx={{ width: 150, height: 150, margin: 'auto', mb: 2 }}
                    />
                )}
                <Typography variant="h5" color="#FFF">
                    {user.full_name?.split(' ').map((name, index, array) => {
                        if (index === 0) {
                            return name; // First name
                        } else if (index === array.length - 1) {
                            return ` "${user.username}" ${name}`; // Last name
                        } else {
                            return ' '; // Space between first name and username, and between username and last name
                        }
                    })}
                </Typography>
                <Typography variant="h6" color="#FFF" sx={{ mb: 1 }}>{user.job}</Typography>
                {user.location && (
                    <Typography variant="body2" color="#FFF">
                        {user.location}
                        <img
                            src={`https://flagcdn.com/w80/${getCountryFlag(user.location)}.png`}
                            srcSet={`https://flagcdn.com/w80/${getCountryFlag(user.location)}.png 2x`}
                            width="25"
                            alt="Flag"
                            style={{ marginLeft: "7px" }}
                        />
                    </Typography>
                )}
                <Typography variant="body2" color="#FFF">
                    {t("profile.timezone")} {user.timezone} ({user.timezone_offset})
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                    {myUserTimezone?.timezone && myUserTimezone?.timezone_offset && user?.last_online && (
                        <UserOnlineStatus
                            lastOnline={user.last_online}
                            workingHours={workingHours}
                            userTimezone={myUserTimezone.timezone}
                            userTimezoneOffset={myUserTimezone.timezone_offset}
                            loggedInUserTimezone = {myUserTimezone.timezone}
                            loggedInUserTimezoneOffset = {myUserTimezone.timezone_offset}
                            showMessage={true}
                            showBadgeOnly={false} />
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default ProfileCard;
