export const ConvertToMyTimeZone = (userTimezone, userTimezoneOffset, date, loggedInUserTimezone, loggedInUserTimezoneOffset) => {
    if (userTimezone && userTimezoneOffset && date && loggedInUserTimezone && loggedInUserTimezoneOffset) {

        const inputDate = new Date(date);

        const originalOffsetInMinutes = parseInt(userTimezoneOffset.split(':')[0]) * 60 + parseInt(userTimezoneOffset.split(':')[1]);
        const utcTimestamp = inputDate.getTime() - (originalOffsetInMinutes * 60000);

        const targetOffsetInMinutes = parseInt(loggedInUserTimezoneOffset.split(':')[0]) * 60 + parseInt(loggedInUserTimezoneOffset.split(':')[1]);
        const adjustedTimestamp = utcTimestamp + (targetOffsetInMinutes * 60000);
        const adjustedDate = new Date(adjustedTimestamp);

        return adjustedDate;
    }
    return null;
};


export const convertToUTC = (date) => {
    const inputDate = new Date(date);
    const offset = inputDate.getTimezoneOffset();
    const utcTimestamp = inputDate.getTime() + (offset * 60000);
    const utcDate = new Date(utcTimestamp);
    return utcDate.toISOString();
};

export const formatDateToString = (dateString) => {
    const date = new Date(dateString);
    
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };

    // Format the Date object using toLocaleString
    const formattedDate = date.toLocaleString('en-GB', options);

    return formattedDate;
};
