import React, { useEffect, useState, useRef } from 'react';
import { useAuthorization } from 'contexts/AuthContextProvider';
import { useService } from 'contexts/ServiceContextProvider';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { useParams } from "react-router-dom";
import { Typography, CardContent, Box, Button, Menu, MenuItem, Tooltip, Divider, Paper, TextField, Badge, Avatar, AvatarGroup, Card } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SubjectIcon from '@mui/icons-material/Subject';
import { convertFromRaw } from "draft-js";
import ContentState from "draft-js/lib/ContentState";
import ListIcon from '@mui/icons-material/List';



import { useSnackbar } from 'contexts/SnackbarContextProvider'; 

import CustomFallback from 'components/Custom/CustomFallBack';

import UnarchiveTaskModal from 'components/Modals/UnarchiveTask';
import UnarchiveListModal from 'components/Modals/UnarchiveList';
import DeleteTaskModal from 'components/Modals/DeleteTask';
import DeleteListModal from 'components/Modals/DeleteList';
import {useTranslation} from "react-i18next";

const ArchivePage = () => {

    const { t } = useTranslation();

    const { projectId } = useParams();
    const { GetProjectBoardArchives, ArchiveTask, ArchiveList, fetchAvailableListsInBoard, getLoggedUserPermissions, UnarchiveTask, UnarchiveList } = useService();

    const [myBoard, setMyBoard] = useState(null);
    const [boardModified, setBoardModified] = useState(false);

    const [openTaskModal, setOpenTaskModal] = useState(false);

    const [openDeleteTaskModal, setOpenDeleteTaskModal] = useState(false);
    const [openDeleteListModal, setOpenDeleteListModal] = useState(false);
    const [openUnarchiveTaskModal, setOpenUnarchiveTaskModal] = useState(false);
    const [openUnarchiveListModal, setOpenUnarchiveListModal] = useState(false);

    const { showSnackbar } = useSnackbar();

    const [openViewTaskModal, setOpenViewTaskModal] = useState(false);
    const [openListHeaderModal, setOpenListHeaderModal] = useState(false);
    const [showColorOptions, setShowColorOptions] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [availableLabels, setAvailableLabels] = useState([]);
    const [avoidMisfire, setAvoidMisfire] = useState(false);
    const [activeList, setActiveList] = useState('');
    const [contextTaskMenuPosition, setContextTaskMenuPosition] = useState({ x: 0, y: 0 });
    const [contextListMenuPosition, setContextListMenuPosition] = useState({ x: 0, y: 0 });
    const [selectedTask, setSelectedTask] = useState([null]);
    const [modalSelectedTask, setModalSelectedTask] = useState([null]);
    const [projectWorkers, setProjectWorkers] = useState([null]);
    const [selectedList, setSelectedList] = useState([null]);
    const [editTaskMode, setEditTaskMode] = useState(null);
    const [editListMode, setEditListMode] = useState(null);
    const [editTaskNewTitle, setEditTaskNewTitle] = useState(null);
    const [editListNewTitle, setEditListNewTitle] = useState("");
    const [titleError, setTitleError] = useState(false);
    const { getLoggedUser } = useAuthorization();
    const [myUserPerms, setMyUserPerms] = useState();
    const [myUserInMemberFormat, setMyUserInMemberFormat] = useState();
    const [isDragging, setIsDragging] = useState(false);
    const [collapsedLists, setCollapsedLists] = useState({});
    const [collapsing, setCollapsing] = useState({});
    const [viewTaskModalOpen, setViewTaskModalOpen] = useState(false);
    const [autoFocusLabel, setAutoFocusLabel] = useState(false);
    const [activeId, setActiveId] = useState(null);
    const [labelClass, setLabelClass] = useState('label-contract');
    const containerRef = useRef(null);
    const boxRef = useRef(null);
    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;

    useEffect(() => {
        const getProjectBoard = async () => {
            const response = await GetProjectBoardArchives(projectId);
            const boardData = response.data;
            setMyBoard(boardData);
            setIsLoading(false);
        };
        getProjectBoard();
    }, []);

    useEffect(() => {
        const fetchUserPerms = async () => {
          if (myWorkspaces[0]?.id) {
            try {
              const userPerms = await getLoggedUserPermissions(myWorkspaces[0]?.id, getLoggedUser());
              setMyUserPerms(userPerms);
            } catch (error) {
              console.error('Error fetching user permissions:', error);
            }
          }
        };
        fetchUserPerms();
      }, [myWorkspaces[0]?.id]);

    const openViewTaskModalHandler = (task, list, autoFocus = false) => {
        setSelectedTask(task);
        setSelectedList(list);
        setAutoFocusLabel(autoFocus);
        setViewTaskModalOpen(true);
    };

    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    });


    const handleDeleteListModalOpen = () => {
        setOpenDeleteListModal(true);
    };

    const handleDeleteTaskModalClose = () => {
        setOpenDeleteTaskModal(false);
        handleTaskCloseMenu();
    };

    const handleUnarchiveTaskModalOpen = () => {
        setOpenUnarchiveTaskModal(true);
    };
    const handleUnarchiveTaskModalClose = () => {
        setOpenUnarchiveTaskModal(false);
        handleTaskCloseMenu();
    };

    const handleUnarchiveListModalOpen = () => {
        setOpenUnarchiveListModal(true);
    };

    const handleUnarchiveListModalClose = () => {
        setOpenUnarchiveListModal(false);
        handleListCloseMenu();
    };

    const handleUnarchiveTask = async (task_id) => {
        try {
            await UnarchiveTask(task_id);
            const updatedBoard = { ...myBoard };
            updatedBoard.lists.forEach(list => {
                list.tasks = list.tasks.filter(task => task.id !== task_id);
            });
            setMyBoard(updatedBoard);
            showSnackbar(t('archives.unarchive_task'), 'success');
        } catch (error) {
            showSnackbar(t('archives.unarchive_task_fail'), 'error');
        }
        handleTaskCloseMenu();
    };

    const handleUnarchiveList = async (list_id) => {
        try {
            await UnarchiveList(list_id);
            const updatedBoard = { ...myBoard };
            const unarchivedListIndex = updatedBoard.lists.findIndex(list => list.id === list_id);
            if (unarchivedListIndex > -1) {
                updatedBoard.lists[unarchivedListIndex].is_deleted = false;
                updatedBoard.lists[unarchivedListIndex].tasks.forEach(task => task.is_deleted = false);
            }
            setMyBoard(updatedBoard);
            showSnackbar(t('archives.unarchive_list'), 'success');
        } catch (error) {
            showSnackbar(t('archives.unarchive_list_fail'), 'error');
        }
        handleListCloseMenu();
    };

    const handleDeleteTask = async () => {
        try {
            await ArchiveTask(selectedList, selectedTask);
            const updatedBoard = { ...myBoard };
            updatedBoard.lists.forEach(list => {
                list.tasks = list.tasks.filter(task => task.id !== selectedTask.id);
            });
            setMyBoard(updatedBoard);
            showSnackbar(t('archives.archive_task'), 'success');
        } catch (error) {
            showSnackbar(t('archives.archive_task_fail'), 'error');
        }
        handleDeleteTaskModalClose();
    };

    const handleDeleteList = async () => {
        try {
            const response = await ArchiveList(selectedList);
            const updatedBoard = { ...myBoard };
            updatedBoard.lists = updatedBoard.lists.filter(list => list.id !== selectedList.id);
            setMyBoard(updatedBoard);
            showSnackbar(t('archives.archive_list'), 'success');
        } catch (error) {
            showSnackbar(t('archives.archive_list_fail'), 'error');
        }
        handleDeleteListModalClose();
    };

    const handleTaskRightClick = (event, task, list) => {
        event.preventDefault();
        event.stopPropagation();
        setContextTaskMenuPosition({ x: event.clientX, y: event.clientY });
        setSelectedTask(task);
        setSelectedList(list);
    };

    const handleTaskCloseMenu = () => {
        setContextTaskMenuPosition({ x: 0, y: 0 });
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    const handleListTitleChange = (event) => {
        const newValue = event.target.value.toLowerCase();
        if (newValue === "to do" || newValue === "doing" || newValue === "done") {
            setTitleError(true);
        } else {
            setTitleError(false);
            setEditListNewTitle(event.target.value);
        }
    };

    const handleListRightClick = (event, list) => {
        if (event.target.classList.contains('task')) {
            return;
        }
        setContextListMenuPosition({ x: event.clientX, y: event.clientY });
        setSelectedList(list);
    };

    const handleListCloseMenu = () => {
        setContextListMenuPosition({ x: 0, y: 0 });
    };

    const handleDeleteListModalClose = () => {
        setOpenDeleteListModal(false);
        handleListCloseMenu();
    };

    const getCardContentStyle = (isDraggingOver, isCollapsed, isCollapsing) => ({
        transition: `max-height ${transitionDuration}ms ease-in-out, padding ${transitionDuration}ms ease-in-out`,
        overflow: 'hidden',
        backgroundColor: isDraggingOver ? '#2A3D75' : 'inherit',
        borderRadius: '12px',
        maxHeight: isCollapsed || isCollapsing ? '50vh' : '100vh',
        padding: isCollapsed || isCollapsing ? '8px' : '16px',
        color: isDraggingOver ? 'white' : 'inherit',
        display: 'flex', // Ensure display is set to flex for proper alignment
        flexDirection: 'column',
    });

    const getOverArchingBoxStyle = (isCollapsed, isCollapsing, list_color) => {
        return {
            transition: `width ${transitionDuration}ms ease-in-out`,
            backgroundColor: list_color,
            marginBottom: 2,
            borderRadius: 3,
            width: isCollapsed || isCollapsing ? '50px' : '300px',
            maxWidth: "300px",
            marginLeft: "25px",
            marginRight: "25px",
        };
    };

    const getBoxStyle = (isCollapsed, isCollapsing) => ({
        transition: `width ${transitionDuration}ms ease-in-out`,
        marginBottom: 2,
        borderRadius: 3,
        width: isCollapsed || isCollapsing ? '50px' : '300px',
        maxWidth: "270px",
        overflowY: isCollapsed || isCollapsing ? 'hidden' : 'auto', // Ensure overflowY is set properly
        overflowX: 'hidden',
        height: 'calc(85vh - 240px)', // Ensure proper height setting for scrolling
    });

    const transitionDuration = 1250;

    const handleViewTask = (task, list) => {
        openViewTaskModalHandler(task, list);
        setModalSelectedTask(task);
        handleListCloseMenu();
    };

    const formatDate = (date) => {
        const options = { day: 'numeric', month: 'short' };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const handleDeleteTaskModalOpen = () => {
        setOpenDeleteTaskModal(true);
    };

    return (
        <>
            {isLoading ? (
                <CustomFallback />
            ) : (
                <>
                    <div ref={containerRef} style={{ height: 'calc(100vh - 120px)', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: '20px' }}>
                            <Card style={{ backgroundColor: '#252224', padding: '0' }}>
                                <CardContent style={{ paddingBottom: '16px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                            <div style={{
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '50%',
                                                backgroundColor: '#C0C0C0',
                                                marginRight: '8px'
                                            }}></div>
                                            <Typography variant="body2" style={{ color: '#C0C0C0', textAlign: 'center', marginRight: "10px" }}>
                                                {t('archives.unarchived_list')}
                                            </Typography>
                                            <div style={{
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '50%',
                                                backgroundColor: '#2A3D74',
                                                marginRight: '8px'
                                            }}></div>
                                            <Typography variant="body2" style={{ color: '#C0C0C0', textAlign: 'center' }}>
                                                {t('archives.archived_list')}
                                            </Typography>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>



                        <div style={{ flex: '1 1 auto', overflowX: 'auto', overflowY: 'hidden', display: 'flex' }}>

                            <div className="myListsClass" style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "flex-start", marginTop: "10px" }}>
                                {myBoard.lists.map((list) => (
                                    <Box
                                        key={list.id}
                                        sx={getOverArchingBoxStyle(collapsedLists[list.id], collapsing[list.id], list.is_deleted ? "#2A3D74" : "#C0C0C0")} 
                                        style={{ overflowX: 'hidden',height:"fit-content", minHeight: "150px" }}
                                    >
                                        <CardContent
                                            onContextMenu={(event) => handleListRightClick(event, list)}
                                            style={getCardContentStyle(false, collapsedLists[list.id], collapsing[list.id], "#000")}
                                        >
                                            <Typography variant="h5" gutterBottom sx={{ paddingBottom: "20px", whiteSpace: 'nowrap' }}>
                                                {editListMode === list.id ? (
                                                    <>
                                                        <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%" >
                                                            <TextField
                                                                fullWidth
                                                                id="standard-multiline-flexible"
                                                                multiline
                                                                maxRows={4}
                                                                variant="standard"
                                                                value={editListNewTitle}
                                                                onChange={handleListTitleChange}
                                                                error={titleError}
                                                                helperText={titleError && "List title cannot be 'To Do', 'Doing', or 'Done'"}
                                                                InputProps={{
                                                                    style: {
                                                                        fontFamily: 'Inter, sans-serif',
                                                                        fontWeight: 400,
                                                                        fontSize: '1.5rem',
                                                                        lineHeight: 1.334,
                                                                        color: "#FFFFFF"
                                                                    }
                                                                }}
                                                            />
                                                            <Box display="flex" justifyContent="flex-end" width="100%" mt={2}>
                                                                <Button
                                                                    variant="text"
                                                                    sx={{ fontSize: "18px" }}
                                                                    disabled={titleError}
                                                                >
                                                                    Save Changes
                                                                    <EditIcon sx={{ color: "#FFF", marginLeft: "20px" }} />
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </>
                                                ) : collapsedLists[list.id] ? (
                                                    <>
                                                        <Tooltip title={`The List "${list.title}" is collapsed`} arrow>
                                                            <ListIcon sx={{ marginLeft: "5px", color: "#FFFFFF" }} />
                                                        </Tooltip>
                                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                                                <span style={{
                                                                    writingMode: 'vertical-rl',
                                                                    marginTop: '8px',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    maxHeight: '38vh',
                                                                    paddingBottom: '10px',
                                                                    color: "#FFFFFF"
                                                                }}>
                                                                    {list.title}
                                                                </span>
                                                                <Tooltip
                                                                    title={`There ${list.task_count === 1 ? 'is' : 'are'} ${list.task_count} task${list.task_count === 1 ? '' : 's'} in this list.`}
                                                                    arrow
                                                                >
                                                                    <Badge
                                                                        badgeContent={list.task_count}
                                                                        showZero
                                                                        max={999}
                                                                        style={{ transform: 'rotate(90deg)' }}
                                                                        sx={{
                                                                            '& .MuiBadge-badge': {
                                                                                backgroundColor: "#FFFFFF",
                                                                                color: list.color === '#252224' ? '#000000' : undefined,
                                                                            }
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                                {(list.title === "Done" || list.title === "Doing" || list.title === "To Do") && (
                                                                    <Tooltip title="This list cannot be deleted or edited">
                                                                        <LockIcon
                                                                            style={{ transform: 'rotate(90deg)' }}
                                                                            sx={{
                                                                                writingMode: 'vertical-rl',
                                                                                fontSize: 18,
                                                                                color: "#FFFFFF",
                                                                                marginTop: "15px"
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Box
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            sx={{ color: 'inherit' }}
                                                        >
                                                            <Box sx={{ maxWidth: '90%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                <Tooltip title={list.title}>
                                                                    <Typography
                                                                        noWrap
                                                                        sx={{
                                                                            // This was my solution to the actual size of the titles, based on screen size..
                                                                            fontSize: {
                                                                                xs: '12px',
                                                                                sm: '14px',
                                                                                md: '16px',
                                                                                lg: '18px',
                                                                            },
                                                                            maxWidth: '100%',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            color: "#FFFFFF"
                                                                        }}
                                                                    >
                                                                        {list.title}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Box>
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                            >
                                                                {list.show_total_tasks && (
                                                                    <Tooltip
                                                                        title={`There ${list.task_count === 1 ? 'is' : 'are'} ${list.task_count} task${list.task_count === 1 ? '' : 's'} in this list.`}
                                                                        arrow
                                                                    >
                                                                        <Badge
                                                                            badgeContent={list.task_count}
                                                                            showZero
                                                                            max={999}
                                                                            sx={{
                                                                                '& .MuiBadge-badge': {
                                                                                    backgroundColor: "#FFFFFF",
                                                                                    color: list.color === '#252224' ? '#000000' : undefined,
                                                                                },
                                                                                marginRight: (list.title === "Done" || list.title === "Doing" || list.title === "To Do") ? "20px" : "15px"
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                                {(list.title === "Done" || list.title === "Doing" || list.title === "To Do") && (
                                                                    <Tooltip title="This list cannot be deleted or edited">
                                                                        <LockIcon
                                                                            sx={{
                                                                                fontSize: 18,
                                                                                color: "#FFFFFF"
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </>
                                                )}
                                            </Typography>
                                            <Box ref={boxRef} sx={{ ...getBoxStyle(collapsedLists[list.id], collapsing[list.id]), height: '100%', maxHeight: "calc(70vh - 120px)" }}>
                                                {!collapsedLists[list.id] && list?.tasks?.map((task) => (
                                                    <Paper
                                                        key={task.id}
                                                        className="task"
                                                        elevation={4}
                                                        sx={{
                                                            border: '1px solid #ccc',
                                                            padding: 1,
                                                            backgroundColor: "#D6D4D4",
                                                            margin: "1%",
                                                            marginBottom: "5%",
                                                            cursor: 'grab',
                                                            transform: activeId === task.id ? 'rotate(2deg)' : 'none', // Slight rotation effect
                                                            transition: 'transform 0.2s', // Smooth transition
                                                        }}
                                                        onClick={editTaskMode === null ? () => handleViewTask(task, list) : undefined}
                                                        onContextMenu={(event) => handleTaskRightClick(event, task, list)}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            {myBoard && task?.label?.map((label) => {
                                                                const maxLabelLength = task.label.reduce((maxLength, currentLabel) => {
                                                                    return Math.max(maxLength, currentLabel?.title?.length);
                                                                }, 0);

                                                                const labelStyle = {
                                                                    backgroundColor: label.color,
                                                                    borderRadius: '15px',
                                                                    padding: '2px',
                                                                    marginRight: '5px',
                                                                    cursor: 'pointer',
                                                                    minWidth: 'max-content',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    '--label-max-length': `${maxLabelLength}ch`, // Custom property for keyframes
                                                                };

                                                                return (
                                                                    <div
                                                                        key={label.id}
                                                                        onClick={(event) => { event.stopPropagation(); }}
                                                                        style={labelStyle}
                                                                        className={labelClass} // Apply transition class
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: '12px',
                                                                                fontWeight: 'bold',
                                                                                color: 'white',
                                                                                paddingRight: '3px',
                                                                                paddingLeft: '3px',
                                                                                textShadow: '1px 1px black'
                                                                            }}
                                                                        >
                                                                            {myBoard.full_label && label.title}
                                                                        </Typography>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <>
                                                            <div style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}><b>#{task.frontend_order}</b> - {task.title}</div>
                                                        </>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                            {(task.start_date || task.due_date || task.end_date) && (
                                                                <Tooltip arrow>
                                                                    <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: "2px", alignItems: "center" }}>
                                                                        <div style={{
                                                                            width: "auto",
                                                                            height: "25px",
                                                                            backgroundColor: "#000",
                                                                            color: "#FFF",
                                                                            borderRadius: "10px",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            fontSize: "12px",
                                                                            padding: '0 5px',
                                                                        }}>
                                                                            <AccessTimeIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
                                                                            {task.end_date ? formatDate(task.end_date) : task.due_date ? formatDate(task.due_date) : task.start_date ? formatDate(task.start_date) : ''}
                                                                        </div>
                                                                    </div>
                                                                </Tooltip>
                                                            )}
                                                            {(() => {
                                                                let content = new ContentState();
                                                                try {
                                                                    if (task.description) {
                                                                        content = convertFromRaw(JSON.parse(task.description));
                                                                    } else {
                                                                        content = ContentState.createFromText('');
                                                                    }
                                                                } catch (error) {
                                                                    content = ContentState.createFromText(task.description);
                                                                }

                                                                const descriptionLength = content.getPlainText().length;
                                                                if (descriptionLength > 0) {
                                                                    return (
                                                                        <div>
                                                                            <SubjectIcon />
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            })()}
                                                            {task.checklist_completion && task.checklist_completion.total > 0 && (
                                                                <Tooltip title="Checklist Completion" arrow>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <CheckBoxIcon sx={{ color: '#4A90E2' }} /> {/* Dark-ish blue color */}
                                                                        <Typography variant="body2" sx={{ color: '#4A90E2', marginLeft: 1 }}>
                                                                            {`${task.checklist_completion.completed}/${task.checklist_completion.total}`}
                                                                        </Typography>
                                                                    </div>
                                                                </Tooltip>
                                                            )}
                                                            <div style={{ display: "flex", justifyContent: "flex-end", marginLeft: "auto" }}>
                                                                <AvatarGroup
                                                                    max={4}
                                                                    sx={{
                                                                        '& .MuiAvatar-root': {
                                                                            border: '2px solid #000000',
                                                                            width: 24, height: 24,
                                                                            fontSize: 'small'
                                                                        },
                                                                    }}
                                                                >
                                                                    {task.members?.map((member) => (
                                                                        <Avatar sx={{ width: 24, height: 24 }} key={member.id} src={process.env.REACT_APP_BACKEND_URL + member.image}></Avatar>
                                                                    ))}
                                                                </AvatarGroup>
                                                            </div>
                                                        </div>
                                                    </Paper>
                                                ))}
                                            </Box>
                                        </CardContent>
                                    </Box>
                                ))}

                            </div>
                        </div>
                    </div>

                    <Menu
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextTaskMenuPosition.y !== 0 && contextTaskMenuPosition.x !== 0
                                ? { top: contextTaskMenuPosition.y, left: contextTaskMenuPosition.x }
                                : undefined
                        }
                        open={contextTaskMenuPosition.y !== 0 && contextTaskMenuPosition.x !== 0}
                        onClose={handleTaskCloseMenu}
                        PaperProps={{
                            style: {
                                backgroundColor: '#252224',
                                color: '#C0C0C0',
                                maxWidth: '230px',
                            },
                        }}
                    >
                        {[
                            <MenuItem disableRipple key="task-title">
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        color: '#C0C0C0',
                                    }}
                                >
                                    {selectedTask ? selectedTask.title : 'No task selected'}
                                </Box>
                            </MenuItem>,
                            <Divider sx={{ my: 0.5, borderColor: '#3A3A3A' }} key="divider" />,
                            (myUserPerms?.is_admin || myUserPerms?.is_owner) && (
                                <MenuItem onClick={() => handleDeleteTaskModalOpen()} key="archive-task">Delete Task</MenuItem>
                            ),
                            <MenuItem key="deleteList" onClick={() => handleUnarchiveTaskModalOpen()} >Unarchive Task</MenuItem>
                        ]}
                    </Menu>

                    <Menu
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextListMenuPosition.y !== 0 && contextListMenuPosition.x !== 0
                                ? { top: contextListMenuPosition.y, left: contextListMenuPosition.x }
                                : undefined
                        }
                        open={contextListMenuPosition.y !== 0 && contextListMenuPosition.x !== 0}
                        onClose={handleListCloseMenu}
                        PaperProps={{
                            style: {
                                backgroundColor: '#252224',
                                color: '#C0C0C0',
                                maxWidth: '350px',
                            },
                        }}
                    >
                        {[
                            <MenuItem disableRipple key="list-title">
                                <Box
                                    sx={{
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        color: '#C0C0C0',
                                    }}
                                >
                                    {selectedList ? selectedList.title : 'No List selected'}
                                </Box>
                            </MenuItem>,
                            <Divider sx={{ my: 0.5, borderColor: '#3A3A3A' }} key="divider" />,
                            !(selectedList.title === "Doing" || selectedList.title === "To Do" || selectedList.title === "Done") && [
                                selectedList.is_deleted ? (
                                    <>
                                        <MenuItem key="unarchiveList" onClick={() => handleUnarchiveListModalOpen()} >Unarchive List</MenuItem>
                                        <MenuItem key="deleteList" onClick={() => handleDeleteListModalOpen()} >Delete List</MenuItem>
                                    </>
                                ) : (
                                    <MenuItem key="notArchived" disabled>This list isn't archived</MenuItem>
                                )
                            ],
                        ]}
                    </Menu>

                </>
            )}
            <UnarchiveTaskModal openUnarchiveTaskModal={openUnarchiveTaskModal} handleUnarchiveTaskModalClose={handleUnarchiveTaskModalClose} handleUnarchiveTask={handleUnarchiveTask} selectedTask={selectedTask} selectedList={selectedList} availableLists={myBoard?.lists} board_id={myBoard?.id} fetchAvailableListsInBoard={fetchAvailableListsInBoard} avoidMisfire={avoidMisfire} />
            <UnarchiveListModal openUnarchiveListModal={openUnarchiveListModal} handleUnarchiveListModalClose={handleUnarchiveListModalClose} handleUnarchiveList={handleUnarchiveList} selectedList={selectedList} avoidMisfire={avoidMisfire} />
            <DeleteTaskModal openDeleteTaskModal={openDeleteTaskModal} handleDeleteTaskModalClose={handleDeleteTaskModalClose} handleDeleteTask={handleDeleteTask} avoidMisfire={avoidMisfire} />
            <DeleteListModal openDeleteListModal={openDeleteListModal} handleDeleteListModalClose={handleDeleteListModalClose} handleDeleteList={handleDeleteList} avoidMisfire={avoidMisfire} />
            
        </>
    );
};

export default ArchivePage;
