import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import {useTranslation} from "react-i18next";

const WorkspaceList = ({ handleWorkspaceClick, showMoreOptions, showNewWorkspace, setCurrentProject, myWorkspaces, workspaceContext }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();


  return (
    <List>
      <ListItem disablePadding onClick={handleWorkspaceClick} onDoubleClick={() => { window.location.href ='/workflow/index'; setCurrentProject("") }}>
        <ListItemButton>
          <ListItemIcon>
            <WorkspacesIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {myWorkspaces[0] ? (
            <ListItemText
              primary={t("sidebar.my_workspaces")}
              secondary={<Typography variant="body2" style={{ color: '#67B5D9' }}>{myWorkspaces[0].name}</Typography>}
            />
          ) : (
            <ListItemText
              primary={t("sidebar.my_workspaces")}
              secondary={<Typography variant="body2" style={{ color: '#67B5D9' }}>{t("sidebar.no_workspaces")}</Typography>}
            />
          )}
        </ListItemButton>
      </ListItem>
      {showMoreOptions && myWorkspaces.slice(1).map((item) => (
        <ListItemButton key={item.id} component={Link} onClick={async () => { await workspaceContext.handleWorkspaceChange(item.id)}}>
          <ListItemText primary={item.name} />
        </ListItemButton>
      ))}
      {showMoreOptions && showNewWorkspace === true && (
        <ListItemButton sx={{ display: "flex", flexDirection: "column" }} component={Link} to="workspaces/new" >
          <ListItemText primary={t("sidebar.new_workspace")} />
        </ListItemButton>
      )}
    </List>
  );
};

export default WorkspaceList;
