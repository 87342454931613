import React, { useState, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Checkbox, Box, Menu, MenuItem, ListItemIcon, ListItemText, Typography, IconButton, Tooltip } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CasinoIcon from '@mui/icons-material/Casino';
import { debounce } from 'lodash';
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import {useTranslation} from "react-i18next";

const getRandomHex = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export default function NewTaskModal({ openTaskModal, handleTaskModalClose, handleSaveTask, avoidMisfire, DeleteLabel, AddLabelToBoard, UpdateLabelTitle, myBoard, setMyBoard }) {
    const { t } = useTranslation();

    const [localName, setLocalName] = useState('');
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openLabelDialog, setOpenLabelDialog] = useState(false);
    const [labelTitle, setLabelTitle] = useState('');
    const [labelAdding, setLabelAdding] = useState(false);
    const [editingLabelId, setEditingLabelId] = useState(null);
    const [newTitle, setNewTitle] = useState('');
    const [labelColor, setLabelColor] = useState(getRandomHex());
    const { showSnackbar } = useSnackbar();

    const defaultColors = [
        '#CC0000', '#D21414', '#E63B3B', '#F46262', '#FF8888',
        '#FFAA00', '#FFBB33', '#FFCC66', '#FFDD99', '#FFEECC',
        '#FFFF00', '#CCFF33', '#99FF66', '#66FF99', '#33FFCC',
        '#00FFFF', '#33CCFF', '#6699FF', '#9966FF', '#CC33FF',
        '#FF00FF', '#FF33CC', '#FF6699', '#FF9966', '#FFCC33'
    ];

    const open = Boolean(anchorEl);

    const handleLocalNameChange = (event) => {
        setLocalName(event.target.value);
    };

    const handleLabelChange = (labelId) => {
        setSelectedLabels((prevSelectedLabels) =>
            prevSelectedLabels.includes(labelId)
                ? prevSelectedLabels.filter((id) => id !== labelId)
                : [...prevSelectedLabels, labelId]
        );
    };

    const handleSaveClick = () => {
        if (localName.trim() === '') {
            showSnackbar(t("modal.task_empty"), 'error');
            return;
        }
        handleSaveTask(localName, selectedLabels);
        showSnackbar(t("modal.task_created"), 'success');
        setLocalName("");
        setSelectedLabels([]);
        handleClose();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (!avoidMisfire) {
                event.target.blur();
                handleSaveClick();
            }
        }
    };

    const handleClose = () => {
        handleTaskModalClose();
        setLocalName("");
        setSelectedLabels([]);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenLabelDialog = () => {
        setOpenLabelDialog(true);
    };

    const handleCloseLabelDialog = () => {
        setOpenLabelDialog(false);
        setLabelAdding(false);
    };

    const handleSetLabelAddOpen = () => {
        setLabelColor(getRandomHex());
        setLabelTitle("");
        setLabelAdding(true);
    };

    const handleSetLabelAddClose = () => {
        setLabelAdding(false);
    };

    const handleAddNewLabel = async (title, color, close = true) => {
        const response = await AddLabelToBoard(myBoard.id, title, color);
        if (response.status === 201) {
            const newLabel = response.data;
            setSelectedLabels((prevLabels) => [...prevLabels, newLabel.id]);
            setMyBoard((prevBoard) => ({
                ...prevBoard,
                labels: [...prevBoard.labels, newLabel]
            }));
            showSnackbar(t("modal.label_added"), 'success');
        }
        if (close) { handleCloseLabelDialog(); }
    };

    const handleEditClick = (label) => {
        setEditingLabelId(label.id);
        setNewTitle(label.title);
    };

    const handleLabelTitleChange = (e) => {
        setNewTitle(e.target.value);
    };

    const handleBlur = async (label) => {
        if (newTitle !== label.title) {
            const response = await UpdateLabelTitle(label.id, newTitle);
            if (response.status === 200) {
                label.title = newTitle;
                setSelectedLabels((prevLabels) => prevLabels.map((l) => (l.id === label.id ? { ...l, title: newTitle } : l)));
                setMyBoard((prevBoard) => ({
                    ...prevBoard,
                    labels: prevBoard.labels.map((l) => (l.id === label.id ? { ...l, title: newTitle } : l))
                }));
                showSnackbar(t("modal.label_updated"), 'success');
            }
        }
        setEditingLabelId(null);
        setNewTitle('');
    };

    const handleKeyDown = (e, label) => {
        if (e.key === 'Enter') {
            handleBlur(label);
        }
    };

    const handleDeleteLabel = async (labelId) => {
        await DeleteLabel(myBoard.id, labelId);
        setSelectedLabels((prevLabels) => prevLabels.filter((id) => id !== labelId));
        setMyBoard((prevBoard) => ({
            ...prevBoard,
            labels: prevBoard.labels.filter((label) => label.id !== labelId)
        }));
        showSnackbar(t("modal.label_deleted"), 'success');
    };

    const debouncedHandleColorSelection = useCallback(
        debounce((color) => {
            setLabelColor(color);
        }, 300),
        []
    );

    const getRandomColor = () => {
        handleAddNewLabel("", getRandomHex(), false);
    };

    return (
        <Dialog
            open={openTaskModal}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            sx={{
                '& .MuiPaper-root': {
                    background: '#252224'
                }
            }}
        >
            <DialogTitle sx={{ color: '#FFF' }}>{t("modal.task_title")}</DialogTitle>
            <DialogContent
                sx={{
                    maxHeight: '50vh',
                    overflow: 'auto'
                }}
            >
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("main_page.sort_name")}
                    type="text"
                    fullWidth
                    value={localName}
                    onChange={handleLocalNameChange}
                    onKeyPress={handleKeyPress}
                    InputLabelProps={{
                        style: { color: '#FFF' }
                    }}
                    InputProps={{
                        style: { color: 'white', resize: 'both' }
                    }}
                    multiline
                    rows={2}
                />
                <Box>
                    {selectedLabels.length > 0 && (
                        <>
                            <Typography variant="caption" color="textSecondary" sx={{ color: '#FFF' }}>
                                {t("modal.labels_selected")}
                            </Typography>
                            <Box sx={{ display: "flex", direction: "row" }}>
                                {myBoard.labels
                                    .filter((label) => selectedLabels.includes(label.id))
                                    .map((label) => (
                                        <Typography
                                            key={label.id}
                                            variant="body2"
                                            sx={{ color: label.color, marginRight: 1 }}
                                        >
                                            {label.title}
                                        </Typography>
                                    ))}
                            </Box>
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ margin: 2 }}>
                    <Button
                        variant="outlined"
                        startIcon={<ArrowDropDownIcon />}
                        onClick={handleMenuOpen}
                        sx={{ color: 'white', borderColor: 'white' }}
                    >
                        {t("modal.labels_select")}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'label-dropdown-button',
                            sx: { backgroundColor: '#252224', color: 'white' }
                        }}
                    >
                        {myBoard.labels.map((label) => (
                            <MenuItem
                                key={label.id}
                                onClick={() => handleLabelChange(label.id)}
                                sx={{ '&:hover': { backgroundColor: '#3a393b' } }}
                            >
                                <ListItemIcon onClick={(event) => event.stopPropagation()}>
                                    <Checkbox
                                        checked={selectedLabels.includes(label.id)}
                                        onChange={(event) => {
                                            event.stopPropagation(); // Stop event propagation
                                            handleLabelChange(label.id);
                                        }}
                                        value={label.id}
                                        sx={{ color: label.color, '&.Mui-checked': { color: label.color } }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={label.title} sx={{ color: 'white' }} />
                            </MenuItem>
                        ))}
                        <Button onClick={handleOpenLabelDialog}  sx={{marginLeft: "5px", color:"white"}}>
                            {t("modal.label_add")} <AddIcon sx={{marginLeft: "5px"}} />
                        </Button>
                    </Menu>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                    <Button onClick={handleClose} color="primary">
                        {t("calendar.event_cancel")}
                    </Button>
                    <Button onClick={handleSaveClick} disabled={avoidMisfire} color="primary">
                        {t("core.save")}
                    </Button>
                </Box>
            </DialogActions>
            <Dialog open={openLabelDialog} onClose={handleCloseLabelDialog} sx={{ '& .MuiPaper-root': { background: '#252224', display: "flex", textAlign: "-webkit-center", } }}>
                {!labelAdding && (
                    <>
                        <DialogTitle sx={{ width: "400px" }}>{t("modal.label_select")}</DialogTitle >
                        <DialogContent>
                            <Typography variant='subtitle2'>{t("modal.labels")}</Typography>
                            <div>
                                {myBoard.labels.map((label) => (
                                    <div key={label.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <Checkbox
                                            checked={selectedLabels.some(item => item === label.id)}
                                            onChange={() => handleLabelChange(label.id)}
                                            color="primary"
                                        />
                                        <div
                                            style={{
                                                width: '225px',
                                                height: '30px',
                                                backgroundColor: label.color,
                                                borderRadius: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '0 10px',
                                                justifyContent: 'space-between',
                                                cursor: 'pointer',
                                                border: selectedLabels.some(item => item === label.id) ? '2px solid white' : 'none'
                                            }}
                                        >
                                            {editingLabelId === label.id ? (
                                                <TextField
                                                    value={newTitle}
                                                    onChange={handleLabelTitleChange}
                                                    onBlur={() => handleBlur(label)}
                                                    onKeyDown={(e) => handleKeyDown(e, label)}
                                                    autoFocus
                                                    InputProps={{
                                                        style: { color: '#FFF' }
                                                    }}
                                                />
                                            ) : (
                                                <Typography variant='body2' style={{ color: '#fff' }}>{label.title}</Typography>
                                            )}
                                            {selectedLabels.some(item => item === label.id) && <CheckIcon style={{ color: 'white' }} />}
                                        </div>
                                        <IconButton onClick={() => handleEditClick(label)} size="small" style={{ marginLeft: '8px' }}>
                                            <EditIcon style={{ color: 'white' }} />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteLabel(label.id)} size="small" style={{ marginLeft: '8px' }}>
                                            <DeleteIcon style={{ color: 'white' }} />
                                        </IconButton>
                                    </div>
                                ))}
                            </div>
                            <Tooltip title={t("modal.label_random")}>
                                <CasinoIcon sx={{ color: "white" }} onClick={() => getRandomColor()} />
                            </Tooltip>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseLabelDialog}>{t("calendar.event_cancel")}</Button>
                            <Button onClick={handleSetLabelAddOpen}>{t("modal.label_add")}</Button>
                        </DialogActions>
                    </>
                )}
                {labelAdding && (
                    <>
                        <DialogTitle sx={{ display: "Flex", justifyContent: "space-between" }}>{t("modal.label_add")}</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label={t("modal.label_title")}
                                type="text"
                                fullWidth
                                variant="standard"
                                value={labelTitle}
                                onChange={(e) => setLabelTitle(e.target.value)}
                                InputProps={{
                                    style: {
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 400,
                                        fontSize: '20px',
                                        lineHeight: 1.334,
                                        color: '#C0C0C0'
                                    }
                                }} />
                            <Typography style={{
                                marginTop: "15px",
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 400,
                                fontSize: '13px',
                                lineHeight: 1.334,
                                color: '#C0C0C0'
                            }}>{t("modal.label_color")}</Typography>
                            <input
                                type="color"
                                value={labelColor}
                                onChange={(e) => debouncedHandleColorSelection(e.target.value)}
                                style={{ marginTop: '15px', marginBottom: '20px', width: "100%", height: "150px" }} />
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '5px', marginTop: '5px' }}>
                                {defaultColors.map((color, index) => (
                                    <div key={index} style={{ width: '100%', height: '30px', backgroundColor: color, cursor: 'pointer', borderRadius: '10px' }} onClick={() => setLabelColor(color)}></div>
                                ))}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleSetLabelAddClose}>{t("modal.return")}</Button>
                            <Button onClick={() => handleAddNewLabel(labelTitle, labelColor)}>{t("modal.label_save")}</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </Dialog>
    );
}
