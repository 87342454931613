import React, {useState} from 'react';
import { Modal, Box, Typography, Button, Paper, Grid } from '@mui/material';

const CheckoutModal = ({ open, onClose, workspaceId, paypalFormHtml, onConfirm }) => {
    const [workspace, setWorkspace] = useState(null);
    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '80%',
                    maxWidth: '600px',
                    backgroundColor: 'white',
                    padding: 4,
                    borderRadius: '8px',
                    overflow: 'auto', // Allows content to scroll
                    maxHeight: '80vh', // Limits the height and allows scrolling if content overflows
                }}
            >
                <Typography variant="h5" gutterBottom>Review Your Selection</Typography>

                {paypalFormHtml && (
                    <div dangerouslySetInnerHTML={{ __html: paypalFormHtml }} />
                )}

                <button onClick={onConfirm}>Purchase Plan</button>


                <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" color="primary" onClick={onConfirm}>Confirm and Proceed</Button>
                    <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CheckoutModal;
