import React from 'react';
import red_bar from 'assets/img/loading/red_bar.png';
import yellow_bar from 'assets/img/loading/yellow_bar.png';
import green_bar from 'assets/img/loading/green_bar.png';
import blue_bar from 'assets/img/loading/blue_bar.png';

const CustomFallback = () => {
    const barStyle = {
        width: '50px', // Adjust size according to your image
        height: '100px', // Adjust size according to your image
        backgroundSize:"cover",
        backgroundPosition: 'center',
        margin: '0 -10px', // Space between bars
        animation: 'pop 1.2s infinite', // Apply the pop animation
        position: 'relative'
    };

    const animationDelay = (index) => ({
        animationDelay: `${index * 0.3}s`, // Staggered delay for each bar
    });

    const containerStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: '9999',
        overflow: 'hidden'
    };

    // Define the keyframes for the pop animation
    const keyframes = `
    @keyframes pop {
      0% {
        transform: scale(1);
        opacity: 0.5;
      }
      50% {
        transform: scale(1.2);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 0.5;
      }
    }
  `;

    return (
        <div style={containerStyle}>
            <style>{keyframes}</style> {/* Inject keyframes into the component */}
            <div style={{ ...barStyle, backgroundImage: `url(${red_bar})`, ...animationDelay(0) }}></div>
            <div style={{ ...barStyle, backgroundImage: `url(${yellow_bar})`, ...animationDelay(1) }}></div>
            <div style={{ ...barStyle, backgroundImage: `url(${green_bar})`, ...animationDelay(2) }}></div>
            <div style={{ ...barStyle, backgroundImage: `url(${blue_bar})`, ...animationDelay(3) }}></div>
        </div>
    );
};

export default CustomFallback;
