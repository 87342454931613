import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import { useService } from 'contexts/ServiceContextProvider';
import {useParams} from 'react-router-dom';
import { useAuthorization } from 'contexts/AuthContextProvider';
import moment from 'moment';
import { Typography, Tooltip, Avatar, Badge, Box, FormControlLabel, Checkbox } from '@mui/material';
import { ConvertToMyTimeZone } from 'components/Custom/TimezoneConvertion.js';
import TourIcon from '@mui/icons-material/Tour';
import EventDetailsModal from './Events/EventsDetailsModal';
import {useTranslation} from "react-i18next";



const CalendarComponent = () => {
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);
  const { fetchWorkspaceCalendar, getUserTimeZone, getLoggedUserPermissions, updateWorkspaceEvent } = useService();
  const { workspaceId } = useParams();
  const [myUserTimezone, setMyUserTimezone] = useState(null);
  const [showHolidayEvents, setShowHolidayEvents] = useState(true);
  const [showWorkingHours, setShowWorkingHours] = useState(true);
  const [showNormalEvents, setShowNormalEvents] = useState(true);
  const [myUserPerms, setMyUserPerms] = useState(null);
  const [currentView, setCurrentView] = useState('dayGridMonth');

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { getLoggedUser } = useAuthorization();


  const userColors = new Map();

  const generateColor = (index) => {
    const hue = (index * 137.508) % 360;
    return `hsl(${hue}, 70%, 80%)`;
  };

  const getCountryFlag = (countryCode) => {
    return countryCode?.toLowerCase(); // Converts country code like PT to pt for the flag URL
  };

  const getUserColor = (userName) => {
    if (!userColors.has(userName)) {
      const colorIndex = userColors.size;
      userColors.set(userName, generateColor(colorIndex));
    }
    return userColors.get(userName);
  };

  const handleEventSave = async (editableEvent) => {
    try {
      // Prepare the event data for backend (sending only collaborator IDs)
      const updatedEvent = {
        ...editableEvent,
        collaborators: editableEvent.collaborators.map(c => c.id),  // Extract collaborator IDs for backend
      };
  
      // Send the update request to the backend
      const savedEvent = await updateWorkspaceEvent(updatedEvent, workspaceId, updatedEvent.id);
  
      // Update the event in the calendar's state with full collaborator details
      setEvents(prevEvents => {
        return prevEvents.map(event => {
          if (event.id === updatedEvent.id) {
            return {
              ...event,
              title: editableEvent.title,
              start: editableEvent.date_start,
              end: editableEvent.date_end,
              allDay: editableEvent.all_day_event,
              backgroundColor: editableEvent.colorCode,
              extendedProps: {
                ...event.extendedProps,
                description: editableEvent.description,
                collaborators: editableEvent.collaborators  // Keep full collaborator details for UI
              }
            };
          }
          return event;
        });
      });
  
      //onsole.log("Event successfully updated:", savedEvent);
      setDialogOpen(false);
    } catch (error) {
      console.error("Failed to update event:", error);
    }
  };
  
  
  
  const handleEventClick = (clickInfo) => {
    if (clickInfo.event.extendedProps.type === 'normal') {
      
      setSelectedEvent(clickInfo.event);
      //console.log(clickInfo.event)
      setDialogOpen(true);
    }
  };


  useEffect(() => {
    const initializeCalendar = async () => {
      try {
        if (workspaceId) {
          const [data, timezoneResponse] = await Promise.all([
            fetchWorkspaceCalendar(workspaceId),
            getUserTimeZone()
          ]);
          setMyUserTimezone(timezoneResponse);
          const users = data.working_hours.map(wh => wh.user_info);

          const holidayEvents = data.holidays.map((holiday) => {
            const affectedUsers = users.filter(user =>
              holiday.country === user.location.split('(')[1]?.replace(')', '')
            );

            return {
              type: 'holiday',
              title: holiday.title,
              start: holiday.start,
              allDay: holiday.allDay,
              name: holiday.name,
              local_name: holiday.local_name,
              country: holiday.country,
              affects_all_country: holiday.affects_all_country,
              sub_region: holiday.sub_region,
              source: holiday.source,
              backgroundColor: '#ffeeab',
              extendedProps: {
                ...holiday.extendedProps,
                affectedUsers,
              },
            };
          });

          const workingHourEvents = generateWorkingHourEvents(data.working_hours, timezoneResponse);
          const normalEvents = generateNormalEvents(data.events, timezoneResponse);

          setEvents([...holidayEvents, ...workingHourEvents, ...normalEvents]);
        }
      } catch (error) {
        console.error('Error fetching events or timezone:', error);
      }
    };

    initializeCalendar();
  }, [workspaceId]);

  useEffect(() => {
    const fetchUserPerms = async () => {
      if (workspaceId) {
        try {
          const userPerms = await getLoggedUserPermissions(workspaceId, getLoggedUser());
          setMyUserPerms(userPerms);
        } catch (error) {
          console.error('Error fetching user permissions:', error);
        }
      }
    };
    fetchUserPerms();
  }, [workspaceId]);

  const normalizeDateTime = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setMilliseconds(0);
    return normalizedDate.toISOString();
  };

  const generateWorkingHourEvents = (workingHoursData, loggedUserTimezone) => {
    const events = [];
    const groupedEvents = new Map();
    const dayMap = {
      "Monday": 1,
      "Tuesday": 2,
      "Wednesday": 3,
      "Thursday": 4,
      "Friday": 5,
      "Saturday": 6,
      "Sunday": 0,
    };

    workingHoursData.forEach((workingHours) => {
      const { start, end, title, day, timezone, timezone_offset, user_info } = workingHours;

      if (!start || !end || !day) {
        console.warn('Missing start, end time, or day for event:', title);
        return;
      }

      const userName = user_info.full_name;
      const userColor = getUserColor(userName);
      const userAvatar = user_info.user_image;
      const weekday = dayMap[day];

      if (weekday !== undefined) {
        const startDateTime = ConvertToMyTimeZone(
          timezone,
          timezone_offset,
          moment().day(weekday).set({
            hour: parseInt(start.split(':')[0], 10),
            minute: parseInt(start.split(':')[1], 10),
          }).toISOString(),
          loggedUserTimezone.timezone,
          loggedUserTimezone.timezone_offset
        );

        const endDateTime = ConvertToMyTimeZone(
          timezone,
          timezone_offset,
          moment().day(weekday).set({
            hour: parseInt(end.split(':')[0], 10),
            minute: parseInt(end.split(':')[1], 10),
          }).toISOString(),
          loggedUserTimezone.timezone,
          loggedUserTimezone.timezone_offset
        );

        if (startDateTime && endDateTime) {
          const normalizedStartDateTime = normalizeDateTime(startDateTime);
          const normalizedEndDateTime = normalizeDateTime(endDateTime);

          const eventKey = `${day}-${normalizedStartDateTime}-${normalizedEndDateTime}`;

          if (!groupedEvents.has(eventKey)) {
            groupedEvents.set(eventKey, {
              start: normalizedStartDateTime,
              end: normalizedEndDateTime,
              users: [],
            });
          }

          groupedEvents.get(eventKey).users.push({
            userName,
            userColor,
            userAvatar,
          });
        }
      }
    });

    groupedEvents.forEach((value, key) => {
      events.push({
        type: 'working_hours',
        title: `${value.users.length} Users`,
        start: value.start,
        end: value.end,
        backgroundColor: value.users[0].userColor,
        textColor: '#000000',
        display: 'block',
        extendedProps: {
          users: value.users,
        },
      });
    });

    return events;
  };

  const generateNormalEvents = (normalEventsData, loggedUserTimezone) => {
    return normalEventsData.map((event) => {
      let startDateTime = ConvertToMyTimeZone(
        'GMT',
        '+00:00',
        moment(event.date_start).toISOString(),
        loggedUserTimezone.timezone,
        loggedUserTimezone.timezone_offset
      );

      let endDateTime = ConvertToMyTimeZone(
        'GMT',
        '+00:00',
        moment(event.date_end).toISOString(),
        loggedUserTimezone.timezone,
        loggedUserTimezone.timezone_offset
      );

      if (event.all_day_event) {
        endDateTime = moment(endDateTime).add(1, 'day').toISOString();
      }

      return {
        type: 'normal',
        id: event.id,
        title: event.title,
        start: normalizeDateTime(startDateTime),
        end: normalizeDateTime(endDateTime),
        allDay: event.all_day_event,
        backgroundColor: event.color_code,
        textColor: '#fff',
        extendedProps: {
          description: event.description,
          collaborators: event.collaborators,
        },
      };
    });
  };

  const filteredEvents = events.filter((event) => {
    if (currentView === 'dayGridMonth' && event.type === 'working_hours') return false; // Always hide working hours in month view
    if (!showHolidayEvents && event.type === 'holiday') return false;
    if (!showWorkingHours && event.type === 'working_hours') return false;
    if (!showNormalEvents && event.type === 'normal') return false;
    return true;
  });


  const renderEventContent = (eventInfo) => {
    const { users, collaborators, affectedUsers, local_name, name, country } = eventInfo.event.extendedProps;

    const contentStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      height: '100%',
      textAlign: 'center',
      flexDirection: 'column',
    };

    if (users) {
      const distinctUsers = [...new Map(users.map(user => [user.userName, user])).values()];
      return (
        <Tooltip
          title={
            <div>
              {distinctUsers.map((user, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar src={process.env.REACT_APP_BACKEND_URL + user.userAvatar} sx={{ width: 24, height: 24, marginRight: '8px' }} />
                  <Typography variant="subtitle1" color="inherit">
                    {user.userName}
                  </Typography>
                </div>
              ))}
            </div>
          }
          arrow
        >
          <Badge
            badgeContent={distinctUsers.length}
            color="primary"
            overlap="circular"
          >
            <Avatar src={process.env.REACT_APP_BACKEND_URL + distinctUsers[0].userAvatar} sx={{ width: 24, height: 24 }} />
          </Badge>
        </Tooltip>
      );
    }

    if (collaborators) {
      return (
        <Tooltip
          title={
            <div>
              {collaborators.map((collaborator, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar src={process.env.REACT_APP_BACKEND_URL + collaborator.image} sx={{ width: 24, height: 24, marginRight: '8px' }} />
                  <Typography variant="subtitle1" color="inherit">
                    {collaborator.full_name}
                  </Typography>
                </div>
              ))}
            </div>
          }
          arrow
        >
          <div style={contentStyle}>
            <Typography variant="subtitle2" sx={{ color: "#fff" }}>
              {eventInfo.event.title}
            </Typography>
          </div>
        </Tooltip>
      );
    }
    if (affectedUsers && affectedUsers.length > 0) {
      const distinctAffectedUsers = [...new Map(affectedUsers.map(user => [user.full_name, user])).values()];
      return (
        <Tooltip
          title={
            <Box sx={{ backgroundColor: '#2E3B55', padding: '15px', borderRadius: '8px', color: '#fff' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                {eventInfo.event.extendedProps.country ? (
                  <img
                    src={`https://flagcdn.com/w80/${getCountryFlag(eventInfo.event.extendedProps.country)}.png`}
                    srcSet={`https://flagcdn.com/w80/${getCountryFlag(eventInfo.event.extendedProps.country)}.png 2x`}
                    width="50"
                    height="35"
                    alt="Flag"
                    style={{ marginRight: '15px' }}
                    onError={(e) => { e.target.src = ''; }}
                  />
                ) : (
                  <TourIcon sx={{ color: '#ffcc00', fontSize: 35, marginRight: '15px' }} /> // Custom icon for no country
                )}
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ffcc00' }}>
                  {t("calendar.holiday")}
                </Typography>
              </Box>
              <Typography variant="h6" sx={{ whiteSpace: 'normal', wordBreak: 'break-word', fontWeight: 'bold', display: 'flex', justifyContent: 'center', marginBottom: '8px', marginLeft: 1, marginRight: 1 }}>
                {local_name}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontStyle: 'italic', display: 'flex', justifyContent: 'center', marginBottom: '8px', color: '#fff' }}>
                ({name})
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '8px', textAlign: 'center', color: '#fff' }}>
                {t("calendar.holiday_workers_affected")}
              </Typography>
              {distinctAffectedUsers.map((user, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <Avatar src={process.env.REACT_APP_BACKEND_URL + user.user_image} sx={{ width: 30, height: 30, marginRight: '10px' }} />
                  <Typography variant="body2" sx={{ color: '#ffcc00' }}>
                    {user.full_name}
                  </Typography>
                </Box>
              ))}
            </Box>
          }
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#2E3B55',
                color: '#fff',
                borderRadius: '8px',
                padding: '15px',
              },
              '& .MuiTooltip-arrow': {
                color: '#2E3B55',
              },
            },
          }}
        >
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#000',
            height: '100%',
            textAlign: 'center',
            flexDirection: 'column',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}>
            <Typography variant="subtitle2" sx={{ color: "#000", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
              {eventInfo.event.extendedProps.local_name}
            </Typography>
            {eventInfo.event.extendedProps.country ? (
              <img
                src={`https://flagcdn.com/w80/${getCountryFlag(eventInfo.event.extendedProps.country)}.png`}
                srcSet={`https://flagcdn.com/w80/${getCountryFlag(eventInfo.event.extendedProps.country)}.png 2x`}
                width="25"
                alt="Flag"
                style={{ marginTop: "5px", marginBottom: "5px" }}
                onError={(e) => { e.target.src = ''; }}
              />
            ) : (
              <TourIcon sx={{ color: '#ffcc00', fontSize: 25, marginTop: '5px', marginBottom: '5px' }} />
            )}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          title={
            <Box sx={{ backgroundColor: '#2E3B55', padding: '15px', borderRadius: '8px', color: '#fff' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                {country ? (
                  <img
                    src={`https://flagcdn.com/w80/${getCountryFlag(country)}.png`}
                    srcSet={`https://flagcdn.com/w80/${getCountryFlag(country)}.png 2x`}
                    width="50"
                    height="35"
                    alt="Flag"
                    style={{ marginRight: '15px' }}
                  />
                ) : (
                  <TourIcon sx={{ color: '#ffcc00', fontSize: '35px', marginRight: '15px' }} />
                )}
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ffcc00' }}>
                  {t("calendar.holiday")}
                </Typography>
              </Box>
              <Typography variant="h6" sx={{ whiteSpace: 'nowrap', fontWeight: 'bold', display: 'flex', justifyContent: 'center', marginBottom: '8px', marginLeft: 1, marginRight: 1 }}>
                {local_name}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontStyle: 'italic', display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                {name}
              </Typography>
            </Box>
          }
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#2E3B55',
                color: '#fff',
                borderRadius: '8px',
                padding: '15px',
              },
              '& .MuiTooltip-arrow': {
                color: '#2E3B55',
              },
            },
          }}
        >
          <div style={contentStyle}>
            <Typography variant="subtitle2" sx={{ color: "#000", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>
              {local_name}
            </Typography>
            {country ? (
              <img
                src={`https://flagcdn.com/w80/${getCountryFlag(country)}.png`}
                srcSet={`https://flagcdn.com/w80/${getCountryFlag(country)}.png 2x`}
                width="25"
                alt="Flag"
                style={{ marginTop: "5px", marginBottom: "5px" }}
              />
            ) : (
              <TourIcon sx={{ color: '#ffcc00', fontSize: '25px', marginTop: '5px', marginBottom: '5px' }} />
            )}
          </div>
        </Tooltip>
      );
    }

  };

  return (
    <div>
      <Typography
        variant="h4"
        sx={{ display: "flex", justifyContent: "center", marginBottom: "20px", marginTop: "10px" }}
      >
        {t("calendar.workspace_project")}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '15px', marginBottom: '20px' }}>
        <FormControlLabel
          control={<Checkbox checked={showHolidayEvents} onChange={() => setShowHolidayEvents(prev => !prev)} />}
          label={t("calendar.show_holiday_events")}
        />
        {currentView !== 'dayGridMonth' && (
          <FormControlLabel
            control={<Checkbox checked={showWorkingHours} onChange={() => setShowWorkingHours(prev => !prev)} />}
            label={t("calendar.show_work_hours")}
          />
        )}
        <FormControlLabel
          control={<Checkbox checked={showNormalEvents} onChange={() => setShowNormalEvents(prev => !prev)} />}
          label={t("calendar.show_scheduled_events")}
        />
      </Box>

      <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '10px', marginLeft: "2.5%", marginRight: "2.5%" }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin]}
          initialView="dayGridMonth"
          events={filteredEvents}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          weekends={true}
          editable={false}
          selectable={false}
          eventDisplay="block"
          height="auto"
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          datesSet={(viewInfo) => {
            setCurrentView(viewInfo.view.type);
          }}
          locale={t("calendar.lng")}
          buttonText={{
          today: t("calendar.today"),
          day: t("calendar.day"),
          week:t("calendar.week"),
          month:t("calendar.month")
        }}
        />
        <EventDetailsModal
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          selectedEvent={selectedEvent}
          myUserPerms={myUserPerms}
          onSave={handleEventSave}
          workspaceId={workspaceId}
        />
      </div>
    </div>

  );
};

export default CalendarComponent;