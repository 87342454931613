import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import {useTranslation} from "react-i18next";

const NewWorkspaceMemberModal = ({ openNewWorkSpaceMemberModal, handleNewWorkSpaceMemberModalClose, handleSendInvite, workspace_name, avoidMisfire }) => {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleInvite = () => {
        if (isValidEmail) {
            handleSendInvite(email);
        }
    };

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(inputEmail) || inputEmail === ''; // Allow empty email field
        setIsValidEmail(isValid);
    };

    return (
        <Dialog open={openNewWorkSpaceMemberModal} onClose={handleNewWorkSpaceMemberModalClose} sx={{

            '& .MuiPaper-root': {
                background: '#252224'
            }
        }}>
            <DialogTitle fontStyle={{ color: "#FFF" }}>{t("modal.workspace_invite")} {workspace_name}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="User's Email"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    error={!isValidEmail && email !== ''} // Show error only if email is not empty and not valid
                    helperText={!isValidEmail && email !== '' ? t("modal.invalid_email") : ''}
                    InputLabelProps={{
                        style: { color: '#FFF' }
                    }}
                    InputProps={{ // Use InputProps for multiline TextField
                        style: { color: '#FFF' } // Apply white color to the input
                    }}
                />
                <Typography variant="body2">{t("modal.user_invite")}</Typography>
            </DialogContent>
            <DialogActions sx={{ marginTop: "-10px" }}>
                <Button onClick={() => { handleNewWorkSpaceMemberModalClose(); setEmail(''); }} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleInvite} disabled={!isValidEmail || email === '' || avoidMisfire} color="primary">
                    Invite
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewWorkspaceMemberModal;
