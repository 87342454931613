// AuthorizationContext.js
import React, {createContext, useState, useContext, useEffect} from 'react';
import axios from 'axios';
const AuthorizationContext = createContext();

export const useAuthorization = () => useContext(AuthorizationContext);

export const AuthorizationProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState('' || localStorage.getItem("accessToken"));
  const [refreshToken, setRefreshToken] = useState('');
  const [isStaff, setIsStaff] = useState(false);

  const [loggedUser, setLoggedUser] = useState('');

  useEffect(() => {
    if (!accessToken && (window.location.pathname !== '/auth/login')){
      window.location.href = '/auth/login';
    }
  }, []);
  
  const login = async (tokens) => {
    setAccessToken(tokens.access);
    setRefreshToken(tokens.refresh || '');
    localStorage.setItem("accessToken", tokens.access)
    localStorage.setItem("refreshToken", tokens.refresh)
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/users/profile/', {
        headers: {
          Authorization: 'Bearer ' + tokens.access,
        },
      });
      setLoggedUser(response.data.id || '');
      setIsStaff(response.data.is_staff);
      localStorage.setItem("user_id", response.data.id);
    } catch (error) {
      console.error('Error fetching user profile:', error.message);
      // Handle error fetching user profile
    }
  };

  const logout = () => {
    setAccessToken('');
    setRefreshToken('');
    localStorage.setItem("accessToken", "");
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("user_id", "");
    window.location.href = "/auth/login/"
  };

  const getLoggedUser = () => {
    return localStorage.getItem("user_id");
  }

  return (
    <AuthorizationContext.Provider value={{ accessToken, refreshToken, isStaff, login, logout, getLoggedUser }}>
      {children}
    </AuthorizationContext.Provider>
  );
};
