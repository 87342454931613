import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import { Modal, Box, Typography, Avatar, Grid, Tooltip, List, ListItem, ListItemAvatar, ListItemText, IconButton } from "@mui/material";
import SubjectIcon from '@mui/icons-material/Subject'; 
import { useService } from "contexts/ServiceContextProvider";
import {useTranslation} from "react-i18next";

const ViewEventsModal = ({ open, onClose, workspaceId }) => {
  const { t } = useTranslation();

  const [events, setEvents] = useState([]);
  const [descriptionVisibility, setDescriptionVisibility] = useState({}); 
  const { getWorkspaceEvents } = useService();

  useEffect(() => {
    if (open) {
      const fetchEvents = async () => {
        try {
          const eventsData = await getWorkspaceEvents(workspaceId);

          const formattedEvents = eventsData.map((event) => ({
            id: event.id,
            title: event.title,
            start: event.all_day_event ? event.date_start.split("T")[0] : event.date_start,
            end: event.all_day_event ? event.date_end.split("T")[0] : event.date_end,
            allDay: event.all_day_event,
            description: event.description,
            backgroundColor: event.color_code,
            collaborators: event.collaborators,
          }));

          setEvents(formattedEvents);
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      };
      fetchEvents();
    }
  }, [open, getWorkspaceEvents, workspaceId]);

  const toggleDescriptionVisibility = (eventId) => {
    setDescriptionVisibility((prevState) => ({
      ...prevState,
      [eventId]: !prevState[eventId], 
    }));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h5" gutterBottom>
            {t("calendar.event_view")}
        </Typography>
        <FullCalendar
          plugins={[listPlugin]}
          initialView="listMonth"
          events={events}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
          }}
          height="auto"
          locale={t("calendar.lng")}
          buttonText={{
              today: t("calendar.today")
          }}
          eventContent={(eventInfo) => {
            const collaborators = eventInfo.event.extendedProps.collaborators || [];
            const description = eventInfo.event.extendedProps.description || '';
            const isDescriptionVisible = descriptionVisibility[eventInfo.event.id] || false; 

            return (
              <Grid container justifyContent="space-between" alignItems="center">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item style={{paddingTop:"4px"}}>
                      <Typography>{eventInfo.event.title}</Typography>
                    </Grid>
                    {/* Icon to toggle the description */}
                    {description && (
                      <Grid item style={{paddingTop:"2px"}}>
                        <IconButton
                          size="small"
                          onClick={() => toggleDescriptionVisibility(eventInfo.event.id)}
                          aria-label="Toggle Description"
                          
                        >
                          <SubjectIcon  />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  {description && isDescriptionVisible && (
                    <Box
                      sx={{
                        border: '1px dashed grey',
                        marginBottom: '8px',
                        borderRadius: '5px',
                        backgroundColor: '#f9f9f9',
                      }}
                    >
                      <Typography variant="body2" >{description}</Typography>
                    </Box>
                  )}
                </Box>

                {collaborators.length > 0 && (
                  <Tooltip
                    title={
                      <List sx={{ minWidth: '200px' }}>
                        <Typography variant="subtitle2">Collaborators:</Typography>
                        {collaborators.map((collaborator) => (
                          <ListItem key={collaborator.id}>
                            <ListItemAvatar>
                              <Avatar
                                src={collaborator.image}
                                alt={collaborator.full_name}
                                sx={{ width: 40, height: 40 }}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={collaborator.full_name} />
                          </ListItem>
                        ))}
                      </List>
                    }
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ],
                    }}
                    placement="bottom-start" 
                    arrow 
                  >
                    <Grid item container spacing={1} sx={{ flexWrap: 'nowrap' }}>
                      {collaborators.map((collaborator) => (
                        <Grid item key={collaborator.id}>
                          <Avatar
                            src={collaborator.image}
                            alt={collaborator.full_name}
                            sx={{ width: 24, height: 24 }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
            );
          }}
          noEventsContent={t("calendar.no_events")}
        />
      </Box>
    </Modal>
  );
};

export default ViewEventsModal;
