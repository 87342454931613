import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useSidebar } from 'contexts/SidebarContextProvider';
import {useTranslation} from "react-i18next";

export default function DeleteWorkspaceModal({ openDeleteWorkspaceModal, handleDeleteWorkspaceModalClose, handleArchiveWorkspace}) {


  const { isSidebarOpen } = useSidebar();
    const { t } = useTranslation();

    return (
        <Dialog open={openDeleteWorkspaceModal} onClose={handleDeleteWorkspaceModalClose} sx={{
            marginLeft: isSidebarOpen() ? '240px' : '0px',
            '& .MuiPaper-root': {
                background: '#252224'
            }
        }}>
            <DialogTitle>{t("modal.warning")}</DialogTitle>
            <DialogContent sx={{color:"white"}}>
                {t("modal.workspace_delete")}
            </DialogContent>
            <DialogContent sx={{color:"white", display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center"}}>
            <WarningIcon sx={{marginRight:"15px"}} color="warning"/> {t("modal.irreversible")} <WarningIcon sx={{marginLeft:"15px"}} color="warning"/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteWorkspaceModalClose} color="primary">
                    {t("calendar.event_cancel")}
                </Button>
                <Button onClick={handleArchiveWorkspace} color="primary">
                    {t("modal.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}