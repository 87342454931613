import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    TextField,
    Button,
    IconButton,
    Box,
    MenuItem,
    Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'contexts/SnackbarContextProvider';
import {useTranslation} from "react-i18next";

import { StyledDialog, StyledButton } from '../../Styling';

const TIME_SLOTS = [
    "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00",
    "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00",
    "20:00", "21:00", "22:00", "23:00"
];

const WorkingHours = ({
    isEditable,
    workingHours = [],
    setWorkingHours,
    handleWorkingHoursChange,
    addWorkingHoursSlot,
    removeWorkingHoursSlot,
    calculateTotalWorkingHours,
    handleSave,
    loading
}) => {
    const { t } = useTranslation();
    const DAYS_OF_WEEK = t("calendar.days_of_week", { returnObjects:true });
    const ABBREVIATED_DAYS_OF_WEEK = t("calendar.days_of_week_abr", { returnObjects:true });


    const { showSnackbar } = useSnackbar();
    const [workingHourGroups, setWorkingHourGroups] = useState([]);

    useEffect(() => {
        calculateTotalWorkingHours(workingHourGroups)
    }, [workingHourGroups]);

    // Function to group working hours by time slots
    const groupWorkingHoursByTime = (hours) => {
        const groups = {};

        hours.forEach(({ day, start, end }) => {
            const timeKey = `${start}-${end}`;
            if (!groups[timeKey]) {
                groups[timeKey] = {
                    start,
                    end,
                    days: []
                };
            }
            groups[timeKey].days.push(day);
        });

        return Object.values(groups);
    };

    useEffect(() => {
        if (workingHours && workingHours.length > 0) {
            const groupedHours = groupWorkingHoursByTime(workingHours);
            setWorkingHourGroups(groupedHours);
        }
    }, [workingHours]);

    const renderTimeSlot = (day, time) => {
        const slot = workingHours.find(slot => slot.day === day && slot.start <= time && slot.end > time);
        if (slot) {
            const start = slot.start.split(':');
            const end = slot.end.split(':');
            const startTime = parseInt(start[0]) * 60 + parseInt(start[1]);
            const endTime = parseInt(end[0]) * 60 + parseInt(end[1]);
            const totalMinutes = endTime - startTime;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            let totalHours = '';
            if (hours > 0) {
                totalHours += hours === 1 ? t("profile.hour") : t("profile.hours", {count: hours});
            }
            if (minutes > 0) {
                if (totalHours) {
                    totalHours += ' ';
                }
                totalHours += t("profile.minutes", {count: minutes});
            }

            const tooltipText = (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    {slot.start} - {slot.end} <br /> ({totalHours})
                </Box>
            );
            return (
                <Tooltip title={tooltipText} key={time}>
                    <Box
                        sx={{
                            backgroundColor: '#67b5d9',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white'
                        }}
                    />
                </Tooltip>
            );
        }
        return null;
    };

    const handleTimeChange = (groupIndex, field, value) => {
        const updatedGroups = [...workingHourGroups];
        updatedGroups[groupIndex][field] = value;

        const start = updatedGroups[groupIndex].start;
        const end = updatedGroups[groupIndex].end;
        if (start && end) {
            const [startHour, startMinute] = start.split(':').map(Number);
            const [endHour, endMinute] = end.split(':').map(Number);
            const startTime = startHour * 60 + startMinute;
            const endTime = endHour * 60 + endMinute;

            if (endTime <= startTime) {
                // If end time is before or equal to start time, show an error using Snackbar
                showSnackbar(t("profile.end_time_err"), 'error');
                return;
            }
        }
        setWorkingHourGroups(updatedGroups);
    };

    // Handle toggling day selection for each group
    const handleDayToggle = (groupIndex, day) => {
        const updatedGroups = [...workingHourGroups];
        const group = updatedGroups[groupIndex];
        if (group.days.includes(day)) {
            group.days = group.days.filter(d => d !== day);
        } else {
            group.days.push(day);
        }
        setWorkingHourGroups(updatedGroups);
        setWorkingHours = updatedGroups;
        
    };

    

    const addNewGroup = () => {
        setWorkingHourGroups([...workingHourGroups, { days: [], start: "09:00", end: "17:00" }]);
    };

    const removeGroup = (groupIndex) => {
        const updatedGroups = workingHourGroups.filter((_, index) => index !== groupIndex);
        setWorkingHourGroups(updatedGroups);
    };

    const handleSaveClick = () => {
        const formattedHours = workingHourGroups.reduce((acc, group) => {
            group.days.forEach(day => {
                acc.push({
                    day,
                    start: group.start,
                    end: group.end
                });
            });
            return acc;
        }, []);

        workingHours = formattedHours;
        handleSave(formattedHours); 
    };

    return (
        <Card sx={{ backgroundColor: '#252224', mb: 1 }}>
            <CardContent>
                {/* When editable, show inputs for adding/editing working hours */}
                {isEditable ? (
                    <Grid container spacing={2}>
                        {workingHourGroups.map((group, index) => (
                            <Grid container spacing={2} sx={{ mt: 1 }} key={index}>
                                <Grid item xs={12}>
                                    <Typography variant="body2" sx={{ color: 'white' }}>Select days:</Typography>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        {DAYS_OF_WEEK.map((day) => (
                                            <StyledButton
                                                key={day}
                                                variant={group.days.includes(day) ? 'contained' : 'outlined'}
                                                onClick={() => handleDayToggle(index, day)}
                                                sx={{
                                                    color: group.days.includes(day) ? 'white' : '#000',
                                                    backgroundColor: group.days.includes(day) ? '#a3989e' : '#3d393b',
                                                    borderColor: 'black'
                                                }}
                                            >
                                                {day.slice(0, 2).toUpperCase()}
                                            </StyledButton>
                                        ))}
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        name={`start_${index}`}
                                        type="time"
                                        value={group.start}
                                        onChange={(e) => handleTimeChange(index, 'start', e.target.value)}
                                        InputProps={{
                                            style: { color: 'white' }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} md={1} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography variant="body2" color="primary">{t("profile.to")}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        name={`end_${index}`}
                                        type="time"
                                        value={group.end}
                                        onChange={(e) => handleTimeChange(index, 'end', e.target.value)}
                                        InputProps={{
                                            style: { color: 'white' }
                                        }}
                                    />
                                </Grid>
                                    <IconButton sx={{marginTop:"10px", marginLeft: "10px"}} onClick={() => removeGroup(index)} disabled={workingHourGroups.length === 1}>
                                        <DeleteIcon sx={{ color: workingHourGroups.length === 1 ? 'grey' : 'red' }} />
                                    </IconButton>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    // When not editable, display the old non-editable view
                    <Grid container spacing={1}>
                        <Grid item xs={1} />
                        {ABBREVIATED_DAYS_OF_WEEK.map((day, index) => (
                            <Grid item xs={1.5} key={index}>
                                <Typography variant="body1" color="#FFF" align="center">{day}</Typography>
                            </Grid>
                        ))}
                        {TIME_SLOTS.map((time, index) => (
                            <Grid container spacing={1} key={index}>
                                <Grid item xs={1}>
                                    <Typography variant="body2" color="#FFF" align="center">{time}</Typography>
                                </Grid>
                                {DAYS_OF_WEEK.map((day, dayIndex) => (
                                    <Grid item xs={1.5} key={dayIndex}>
                                        {renderTimeSlot(day, time)}
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                )}
                
                {/* Action buttons for saving or adding new group */}
                {isEditable && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Button variant="contained" onClick={addWorkingHoursSlot}>{t("profile.add_hour_slot")}</Button>
                        <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={loading}>
                            {loading ? t("profile.saving") : t("core.save")}
                        </Button>
                    </Box>
                )}

                {/* Total Working Hours Display */}
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} md={2} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" color="primary">{t("profile.total_hours")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Typography variant="body2" sx={{ color: '#67b5d9' }}>{calculateTotalWorkingHours(workingHourGroups)}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default WorkingHours;
