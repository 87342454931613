import React, { useCallback, useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import { useService } from "contexts/ServiceContextProvider";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CasinoIcon from "@mui/icons-material/Casino";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import ArchiveIcon from '@mui/icons-material/Archive';
import 'react-medium-image-zoom/dist/styles.css';

import { debounce } from "lodash";
import {
    ConvertToMyTimeZone,
    convertToUTC,
    formatDateToString,
} from "components/Custom/TimezoneConvertion.js";
import TaskDatesDialog from "components/Core/TaskDatesDialog.jsx";
import ArchiveTaskModal from "./ArchiveTask";
import TextEditor from "../Custom/TextEditor";
import ChecklistComponent from "../Custom/ChecklistComponent";
import {useTranslation} from "react-i18next";
import CoverPickerModal from "../Modals/CoverPickerModal";
import Comments from "../Core/TaskComments";

export default function ViewTaskModal({
    openViewTaskModal,
    handleViewTaskModalClose,
    Task,
    List,
    myBoard,
    setMyBoard,
    updateToggleLabel,
    projectWorkers,
    myUserTimezone,
    setBoardModified,
    handleMarkAsDone,
    handleMarkAsDoing,
    handleArchiveTask,
    avoidMisfire,
    setContextTaskMenuPosition,
    autoFocusLabel,
    openCover,
    setCoverModalOpen
}) {
    const { t } = useTranslation();

    const [editingTaskTitle, setEditingTaskTitle] = useState(false);
    const [editTaskNewTitle, setEditTaskNewTitle] = useState("");
    const {
        UpdateTaskDates,
        DeleteChecklist,
        UpdateTask,
        SaveTaskLabels,
        AddLabelToBoard,
        DeleteLabel,
        updateTaskMembers,
        GetTaskActivityLog,
        UpdateTaskDescription,
        CreateChecklist,
        CreateChecklistItem,
        GetChecklists,
        UpdateChecklistItem,
        DeleteChecklistItem,
        UpdateChecklistTitle,
        UpdateChecklistOrder,
        UpdateLabel,
        UpdateTaskCover,
        HandleUploadAttachment,
        DeleteTaskAttachment
    } = useService();
    const [open, setOpen] = useState(false);
    const [labelTitle, setLabelTitle] = useState("");
    const [labelAdding, setLabelAdding] = useState(false);

    const [selectedTaskLabels, setSelectedTaskLabels] = useState([]);
    const [selectedTaskMembers, setSelectedTaskMembers] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [taskMembersDialogOpen, setTaskMembersDialogOpen] = useState(false);
    const [taskDatesDialogOpen, setTaskDatesDialogOpen] = useState(false);
    const [taskActivityLog, setTaskActivityLog] = useState([]);
    const [description, setDescription] = useState();
    const [openArchiveTaskModal, setOpenArchiveTaskModal] = useState(false);

    const [checklists, setChecklists] = useState([]);
    const [editChecklistTitles, setEditChecklistTitles] = useState({});
    const [newChecklistTitles, setNewChecklistTitles] = useState({});

    const [taskTitleError, setTaskTitleError] = useState([]);

    const [showAllActivity, setShowAllActivity] = useState(false);
    const [labelEditing, setLabelEditing] = useState(false);
    const [labelEditingTitle, setLabelEditingTitle] = useState(null);
    const [labelEditingColor, setLabelEditingColor] = useState(null);
    const [labelEditingID, setLabelEditingID] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(false);

    const [pastedImage, setPastedImage] = useState(null);
    const [labelDescription, setLabelDescription] = useState('');
    const [labelEditingDescription, setLabelEditingDescription] = useState('');


    const handlePaste = async (event) => {
        const clipboardItems = event.clipboardData.items;

        for (let item of clipboardItems) {
            if (item.type.startsWith("image")) {
                const file = item.getAsFile();
                setPastedImage(URL.createObjectURL(file));
                await saveNewCoverImage(file, Task.cover_color);
            }
        }
    };

    useEffect(() => {
        if (editingTaskTitle) {
            document.addEventListener("paste", handlePaste);
        } else {
            document.removeEventListener("paste", handlePaste);
        }

        return () => {
            document.removeEventListener("paste", handlePaste);
        };
    }, [editingTaskTitle]);


    useEffect(() => {
        if (autoFocusLabel) {
            setOpen(true);
        }
        if (openCover) {
            setCoverModalOpen(true);
        }
    }, [autoFocusLabel, openCover]);

    const handleOpenLabelEditor = () => {
        setOpen(true);
    };

    <AddIcon sx={{ color: "#D6D4D4" }} onClick={handleOpenLabelEditor} />;

    useEffect(() => {
        const fetchTaskActivityLog = async () => {
            if (Task.id) {
                const activityLog = await GetTaskActivityLog(Task.id);
                setTaskActivityLog(activityLog);
            }
        };
        if (Task.label) {
            setSelectedTaskLabels(Task.label);
            if (Task.members !== selectedTaskMembers) {
                setSelectedTaskMembers(selectedTaskMembers);
            } else {
                setSelectedTaskMembers(Task.members);
            }
            setDescription(Task.description);
            fetchTaskActivityLog();
        }

        if (Task.id) {
            //fetchChecklists();
        }
    }, [Task, GetTaskActivityLog, refreshFlag]);




    const handleAddChecklist = async () => {
        try {
            const response = await CreateChecklist(Task.id, t("modal.checklist_new"));
            setChecklists((prevChecklists) => [...prevChecklists, response]);
            setRefreshFlag(!refreshFlag);

        } catch (error) {
            console.error("Error creating checklist:", error);
        }
    };

    const getRandomHex = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const [labelColor, setLabelColor] = useState(getRandomHex());
    const defaultColors = [
        "#CC0000",
        "#D21414",
        "#E63B3B",
        "#F46262",
        "#FF8888",
        "#FFAA00",
        "#FFBB33",
        "#FFCC66",
        "#FFDD99",
        "#FFEECC",
        "#FFFF00",
        "#CCFF33",
        "#99FF66",
        "#66FF99",
        "#33FFCC",
        "#00FFFF",
        "#33CCFF",
        "#6699FF",
        "#9966FF",
        "#CC33FF",
        "#FF00FF",
        "#FF33CC",
        "#FF6699",
        "#FF9966",
        "#FFCC33",
    ];

    const debouncedHandleColorSelection = useCallback(
        debounce((color) => {
            setLabelColor(color);
        }, 300),
        []
    );

    const debouncedHandleEditColorSelection = useCallback(
        debounce((color) => {
            setLabelEditingColor(color);
        }, 300),
        []
    );
    const myBoardSortedLabels = myBoard?.labels?.sort(
        (a, b) => a.order - b.order
    );

    const handleTitleClick = (title) => {
        if (!editingTaskTitle) {
            setEditTaskNewTitle(title);
            setEditingTaskTitle(true);
        }
    };

    useEffect(() => {
        const fetchTaskActivityLog = async () => {
            if (Task.id) {
                const activityLog = await GetTaskActivityLog(Task.id);
                setTaskActivityLog(activityLog);
            }
        };

        if (Task.label) {
            setSelectedTaskLabels(Task.label);
            setSelectedTaskMembers(Task.members);
            setDescription(Task.description);
            fetchTaskActivityLog();
        }
    }, [Task, GetTaskActivityLog]);

    const handleTitleBlur = async () => {
        if (editTaskNewTitle == "") {
            setTaskTitleError(t("modal.task_empty"));
        } else {
            setEditingTaskTitle(false);

            await UpdateTask(List, Task, editTaskNewTitle);
            setRefreshFlag(!refreshFlag);
            const updatedBoard = { ...myBoard };

            const listToUpdate = updatedBoard.lists.find(
                (list) => list.id === List.id
            );
            const taskToUpdate = listToUpdate.tasks.find(
                (task) => task.id === Task.id
            );
            // Update the title of the task
            if (taskToUpdate) {
                taskToUpdate.title = editTaskNewTitle;
                setMyBoard(updatedBoard);
            }

            setEditingTaskTitle(null);
        }
    };

    const handleTitleChange = (event) => {
        event.target.value.toLowerCase();
        setEditTaskNewTitle(event.target.value);
    };

    const handleAddLabel = () => {
        setOpen(true);
    };

    const handleArchiveTaskModalOpen = () => {
        setOpenArchiveTaskModal(true);
    };

    const handleArchiveTaskModalClose = () => {
        setOpenArchiveTaskModal(false);
        handleTaskCloseMenu();
    };

    const handleCloseAndArchive = () => {
        setOpenArchiveTaskModal(false);
        handleViewTaskModalClose();
        handleArchiveTask();
    };

    const handleTaskCloseMenu = () => {
        setContextTaskMenuPosition({ x: 0, y: 0 });
    };

    const handleColorSelection = (color) => {
        setLabelColor(color);
    };

    const handleEditColorSelection = (color) => {
        setLabelEditingColor(color);
    };

    const handleAddNewLabel = async (title, color, description) => {
        const response = await AddLabelToBoard(myBoard.id, title, color, labelDescription);

        if (response.status === 201) {
            setSelectedTaskLabels((prevLabels) => {
                prevLabels = prevLabels || [];
                // Label doesn't exist, add it
                const updatedLabels = [...prevLabels, response.data];
                Task.label = updatedLabels;
                return updatedLabels;
            });

            setMyBoard((prevBoard) => ({
                ...prevBoard,
                labels: [...prevBoard.labels, response.data],
            }));
        }

        handleSetLabelAddClose();
    };



    const saveNewCoverImage = async (image, color) => {
        const formData = new FormData();
        if (image) {
            formData.append('cover_image', image);
            Task.cover_image = URL.createObjectURL(image);
        } else {
            Task.cover_image = null;
        }
        if (color) {
            formData.append('cover_color', color);
            Task.cover_color = color;
        } else {
            Task.cover_color = null;
        }

        UpdateTaskCover(Task.id, formData);
    };

    const handleEditLabel = async (title, color, label_id, description) => {
        const response = await UpdateLabel(label_id, title, color, description);
       
        if (response.status === 200) {
            setSelectedTaskLabels((prevLabels) => {
                const initializedLabels = prevLabels || [];
                const indexToUpdate = initializedLabels.findIndex(label => label.id === label_id);

                if (indexToUpdate !== -1) {

                    const updatedLabels = [
                        ...initializedLabels.slice(0, indexToUpdate),
                        response.data,
                        ...initializedLabels.slice(indexToUpdate + 1)
                    ];
                    Task.label = updatedLabels;
                    return updatedLabels;
                }
                return initializedLabels;
            });

            setMyBoard((prevBoard) => {
                const updatedLabels = prevBoard.labels.map(label => label.id === label_id ? response.data : label);
                const updatedLists = prevBoard.lists.map(list => ({
                    ...list,
                    tasks: list.tasks.map(task => ({
                        ...task,
                        label: task.label.map(label => label.id === label_id ? response.data : label)
                    }))
                }));

                return {
                    ...prevBoard,
                    labels: updatedLabels,
                    lists: updatedLists
                };
            });


        }
        setLabelEditing(false);
    };


    const handleClose = () => {
        setOpen(false);
        handleSaveLabel();
        setLabelAdding(false);
        setLabelEditing(false);
        if (autoFocusLabel) {
            handleViewTaskModalClose()
        }
    };

    const handleSetLabelAddOpen = () => {
        setLabelColor(getRandomHex());
        setLabelTitle("");
        setLabelAdding(true);
    };

    const handleSetLabelAddClose = () => {
        handleAddLabel(labelTitle, labelColor);
        setLabelAdding(false);
    };

    const handleSetCancelEdit = () => {
        setLabelEditing(false);
    };



    const toggleLabelSelection = (label) => {
        setSelectedTaskLabels((prevLabels) => {
            prevLabels = prevLabels || [];
            const labelIndex = prevLabels.findIndex((item) => item.id === label.id);
            if (labelIndex !== -1) {
                const updatedLabels = prevLabels.filter((item) => item.id !== label.id);
                // Update Task.label
                Task.label = updatedLabels;
                return updatedLabels;
            } else {
                // Label doesn't exist, add it
                const updatedLabels = [...prevLabels, label];
                // Update Task.label
                Task.label = updatedLabels;
                return updatedLabels;
            }
        });
    };

    const handleSaveLabel = () => {
        SaveTaskLabels(Task.id, selectedTaskLabels);
        setOpen(false);
        setLabelTitle("");
        setLabelColor(getRandomHex());
    };

    const handleDeleteLabel = async (label_id) => {
        await DeleteLabel(myBoard.id, label_id);
        const updatedBoard = { ...myBoard };
        updatedBoard.labels = updatedBoard.labels.filter(
            (label) => label.id !== label_id
        );
        setMyBoard(updatedBoard);

        setSelectedTaskLabels((prevLabels) => {
            prevLabels = prevLabels || [];
            const labelIndex = prevLabels.findIndex((item) => item.id === label_id);
            if (labelIndex !== -1) {
                const updatedLabels = prevLabels.filter((item) => item.id !== label_id);
                Task.label = updatedLabels;
                return updatedLabels;
            }
        });
    };

    const handleEditClick = (label) => {
        setLabelEditingTitle(label.title)
        setLabelEditingColor(label.color)
        setLabelEditingDescription(label.description)
        setLabelEditingID(label.id)
        setLabelEditing(true);
    };

    const handleLabelTitleChange = (e) => {
        const value = e.target.value;
        setLabelTitle(value);

        if (value.toUpperCase() === "CRAZY") {
            setLabelColor("rainbow");
        }
    };

    const handleEditLabelTitleChange = (e) => {
        const value = e.target.value;
        setLabelEditingTitle(value);

        if (value.toUpperCase() === "CRAZY") {
            setLabelEditingColor("rainbow");
        } else {
            if (labelEditingColor === "rainbow") {
                setLabelEditingColor("#000000")
            } else {
                setLabelEditingColor(labelEditingColor)
            }
        }
    };

    const getRandomColor = () => {
        handleAddNewLabel("", getRandomHex());
    };

    const handleTaskDatesDialogOpen = () => setTaskDatesDialogOpen(true);
    const handleTaskDatesDialogClose = () => {
        setTaskDatesDialogOpen(false);
    };

    const handleTaskMembersDialogOpen = () => setTaskMembersDialogOpen(true);

    const handleTaskMembersDialogClose = () => {
        setTaskMembersDialogOpen(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredProjectWorkers = projectWorkers?.filter((worker) =>
        worker?.full_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const toggleMemberSelection = (worker) => {
        setSelectedTaskMembers((prevSelectedMembers) => {
            if (prevSelectedMembers.some((member) => member.id === worker.id)) {
                return prevSelectedMembers.filter((member) => member.id !== worker.id);
            } else {
                return [...prevSelectedMembers, worker];
            }
        });
    };

    const handleSaveMembers = async () => {
        try {
            // Call the updateTaskMembers function to update the Task.members
            await updateTaskMembers(Task.id, selectedTaskMembers);

            // Remove the backend URL from the image URLs
            const updatedTaskMembers = selectedTaskMembers.map((member) => ({
                ...member,
                image: member.image.replace(process.env.REACT_APP_BACKEND_URL, ""),
            }));
         
            const updatedBoard = {
                ...myBoard,
                lists: myBoard.lists.map((list) => ({
                    ...list,
                    tasks: list.tasks.map((task) => {
                        if (task.id === Task.id) {
                            return {
                                ...task,
                                members: updatedTaskMembers,
                            };
                        }
                        return task;
                    }),
                })),
            };

            setMyBoard(updatedBoard);
            setTaskMembersDialogOpen(false);
        } catch (error) {
            console.error("Error updating task members:", error);
            // Handle error if any
        } finally {
            setRefreshFlag(!refreshFlag);
        }
    };

    const handleSaveDates = async (dates) => {
        try {
            // Helper function to convert and set dates
            const convertAndSetDate = (dateKey) => {
                if (dates[dateKey]) {
                    return convertToUTC(dates[dateKey]);
                }
                return null;
            };

            // Convert selected dates to UTC format (Because server is UTC!)
            const convertedDates = {
                start_date: convertAndSetDate("start_date"),
                due_date: convertAndSetDate("due_date"),
                end_date: convertAndSetDate("end_date"),
            };

            const response = await UpdateTaskDates(Task.id, convertedDates);
            if (response.status === 200) {
                const updatedTask = response.data;

                setMyBoard(prevBoard => {
                    return {
                        ...prevBoard,
                        lists: prevBoard.lists.map(list => {
                            return {
                                ...list,
                                tasks: list.tasks.map(task => {
                                    if (task.id === Task.id) {
                                        return { ...task, ...updatedTask };
                                    }
                                    return task;
                                })
                            };
                        })
                    };
                });
            }
            setRefreshFlag(!refreshFlag);

            setTaskDatesDialogOpen(false);
        } catch (error) {
            console.error("Error updating task dates:", error);
        }
    };

    const handleDescription = async (json) => {
        handleViewTaskModalClose();
        try {
            Task.description = json;
            setDescription(json);
            await UpdateTaskDescription(Task.id, json);
            setRefreshFlag(!refreshFlag);
        } catch (error) {
            console.error("Error updating task description:", error);
        }
    };

    const handleCancel = () => {
        handleViewTaskModalClose();
    };



    // State to manage the number of visible log entries
    const [visibleLogs, setVisibleLogs] = useState(3);

    // Function to increase the number of visible log entries
    const handleViewMore = () => {
        setVisibleLogs((prevVisibleLogs) => prevVisibleLogs + 3);
    };



    useEffect(() => {
        const keyframesStyle = `
            @keyframes rainbow {
                0% { background-position: 0% 50%; }
                50% { background-position: 100% 50%; }
                100% { background-position: 0% 50%; }
            }
        `;

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = keyframesStyle;
        document.head.appendChild(styleSheet);

        return () => {
            document.head.removeChild(styleSheet);
        };
    }, []);



    const handleCloseModal = () => {
        setTaskActivityLog(null);
        handleViewTaskModalClose();
    };

    //Attachments here
    const [files, setFiles] = useState(null);

    const handleFileChange = (event) => {
        const uploadedFiles = event.target.files;
        if (uploadedFiles) {
            setFiles(uploadedFiles);
            HandleUploadAttachment(Task.id, myBoard.id, uploadedFiles).then((newAttachments) => {
        
                Task.attachments = [...Task.attachments, ...newAttachments];

                setRefreshFlag(!refreshFlag);
            })
                .catch((error) => {
                    console.error("Error uploading attachments:", error);
                });
        }
    };

    const [selectedPreviewImage, setSelectedPreviewImage] = useState(null);
    const [openImagePreviewModal, setOpenImagePreviewModal] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleOpenImageModal = (imageUrl) => {
        setSelectedPreviewImage(imageUrl);
        setOpenImagePreviewModal(true);
    };

    const handleCloseImageModal = () => {
        setOpenImagePreviewModal(false);
        setSelectedPreviewImage(null);
    };

    const handleDeleteAttachment = (attachment, event) => {
        event.stopPropagation();
        setSelectedAttachment(attachment);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedAttachment(null);
    };

    const handleConfirmDelete = async (deleteFromStorage) => {
        try {
            await DeleteTaskAttachment(Task.id, selectedAttachment.id, deleteFromStorage);
            //Use snackbar here later when mass updating snackbar
            //if (deleteFromStorage) {
            //    console.log(`Deleted attachment ${selectedAttachment.filename} from both task and board's storage.`);
            //} else {
            //    console.log(`Deleted attachment ${selectedAttachment.filename} from task only.`);
            //}

            Task.attachments = Task.attachments.filter((att) => att.id !== selectedAttachment.id);


            handleCloseDeleteDialog();
        } catch (error) {
            console.error("Error deleting attachment:", error);
        }
    };

    const getLabelUsageCount = (labelId) => {
        let count = 0;

        myBoard.lists.forEach(list => {
            list.tasks.forEach(task => {
                if (task.label && task.label.some(label => label.id === labelId)) {
                    count += 1;
                }
            });
        });

        return count;
    };

    return (
        <>
            <ArchiveTaskModal
                openArchiveTaskModal={openArchiveTaskModal}
                handleArchiveTaskModalClose={handleArchiveTaskModalClose}
                handleArchiveTask={handleCloseAndArchive}
                avoidMisfire={avoidMisfire}
            />

            <Dialog
                open={openViewTaskModal}
                maxWidth={"500px"}
                onClose={handleCloseModal}
                sx={{
                    "& .MuiPaper-root": {
                        background: "#252224",
                    },
                }}
            >
                {(Task.cover_color || Task.cover_image) && (
                    <Box
                        className="cover"
                        sx={{
                            borderRadius: '8px 8px 0 0',
                            height: Task.cover_image ? '200px' : '90px',
                            minHeight: Task.cover_image ? '200px' : '90px',
                            background: Task.cover_image
                                ? `url(${Task.cover_image}), ${Task.cover_color || '#FFFFFF'}`  // Combine image and color
                                : Task.cover_color || '#FFFFFF', // Use only color if no image
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />

                )}

                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >

                    <Box
                        onClick={() => handleTitleClick(Task.title)}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "565px",
                        }}
                    >
                        <div>
                            {editingTaskTitle ? (
                                <span
                                    style={{
                                        display: "inline-block",
                                        maxWidth: "565px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    <TextField
                                        sx={{ width: "565px" }}
                                        value={editTaskNewTitle}
                                        onChange={handleTitleChange}
                                        onBlur={handleTitleBlur}
                                        autoFocus
                                        InputProps={{
                                            style: {
                                                fontFamily: "Inter, sans-serif",
                                                fontWeight: 400,
                                                fontSize: "20px",
                                                lineHeight: 1.334,
                                                color: "#C0C0C0",
                                            },
                                        }}
                                    />
                                </span>
                            ) : (
                                <span
                                    style={{
                                        display: "inline-block",
                                        maxWidth: "565px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    <b>#{Task.frontend_order}</b> - {Task.title}
                                </span>
                            )}
                            <Typography sx={{ fontSize: "small", fontStyle: "italic" }}>
                                {t("modal.in_list")} {List.title}
                            </Typography>
                            <Typography
                                sx={{ fontSize: "small", margin: "2px", color: "red" }}
                            >
                                {taskTitleError}
                            </Typography>
                        </div>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ maxWidth: "1000px", minWidth: "1000px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="subtitle2" sx={{ marginBottom: "5px" }}>
                                        {t("modal.labels")}
                                    </Typography>

                                    <div style={{ display: "flex" }}>
                                        {Task?.label?.map((label, index) => {
                                            const maxLabelLength = Task.label.reduce(
                                                (maxLength, currentLabel) => Math.max(maxLength, currentLabel.title.length),
                                                0
                                            );

                                            const labelStyle = {
                                                backgroundColor: label.color !== "rainbow" ? label.color : "transparent",
                                                borderRadius: "15px",
                                                padding: "2px",
                                                marginRight: "5px",
                                                width: `${maxLabelLength}ch`,
                                                cursor: "pointer",
                                                minWidth: "max-content",
                                                display: "flex",
                                                justifyContent: "center",
                                                "--label-max-length": `${maxLabelLength}ch`,
                                                ...(label.color === "rainbow" && {
                                                    background: "linear-gradient(270deg, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8b00ff)",
                                                    backgroundSize: "400% 400%",
                                                    animation: "rainbow 1.5s ease infinite",
                                                }),
                                            };

                                            return (
                                                <div
                                                    key={index}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        updateToggleLabel();
                                                    }}
                                                    style={labelStyle}
                                                >

                                                    <Tooltip title={
                                                        <>
                                                            {label.description || "No description available."}
                                                            <br />
                                                            {`There are ${getLabelUsageCount(label.id)} tasks with this label.`}
                                                        </>
                                                    } placement="top">
                                                        <Typography
                                                            sx={{
                                                                fontSize: "12px",
                                                                fontWeight: "bold",
                                                                color: "white",
                                                                paddingRight: "3px",
                                                                paddingLeft: "3px",
                                                                textShadow: label.color === "rainbow" ? "none" : "1px 1px black",
                                                            }}
                                                        >
                                                            {label.title}
                                                        </Typography>
                                                    </Tooltip>
                                                </div>
                                            );
                                        })}
                                        <AddIcon
                                            sx={{ color: "#D6D4D4" }}
                                            onClick={handleAddLabel}
                                        />
                                    </div>
                                </div>

                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    sx={{
                                        "& .MuiPaper-root": {
                                            background: "#252224",
                                            display: "flex",
                                            textAlign: "-webkit-center",
                                        },
                                    }}
                                >
                                    {!labelAdding && !labelEditing && (
                                        <>
                                            <DialogTitle sx={{ width: "400px" }}>
                                                {t("modal.label_select")}
                                            </DialogTitle>
                                            <DialogContent>
                                                <Typography variant="subtitle2">{t("modal.labels")}</Typography>
                                                <div>
                                                    {myBoardSortedLabels?.map((label) => (
                                                        <div
                                                            key={label.id}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                marginBottom: "8px",
                                                            }}
                                                        >
                                                            <Checkbox
                                                                checked={selectedTaskLabels?.some(
                                                                    (item) => item.id === label.id
                                                                )}
                                                                onChange={() => toggleLabelSelection(label)}
                                                                color="primary"
                                                            />
                                                            <div
                                                                style={{
                                                                    width: "225px",
                                                                    height: "30px",
                                                                    backgroundColor: label.color !== "rainbow" ? label.color : "transparent",
                                                                    borderRadius: "10px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    padding: "0 10px",
                                                                    justifyContent: "space-between",
                                                                    cursor: "pointer",
                                                                    border: selectedTaskLabels?.some(
                                                                        (item) => item.id === label.id
                                                                    )
                                                                        ? "2px solid white"
                                                                        : "none",
                                                                    backgroundImage: label.color === "rainbow"
                                                                        ? "linear-gradient(270deg, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8b00ff)"
                                                                        : "none",
                                                                    backgroundSize: label.color === "rainbow" ? "400% 400%" : "none",
                                                                    animation: label.color === "rainbow" ? "rainbow 2.5s ease infinite" : "none",
                                                                }}
                                                            >

                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ color: "#fff" }}
                                                                >
                                                                    {label.title}
                                                                </Typography>

                                                                {selectedTaskLabels?.some(
                                                                    (item) => item.id === label.id
                                                                ) && <CheckIcon style={{ color: "white" }} />}
                                                            </div>
                                                            <IconButton
                                                                onClick={() => handleEditClick(label)}
                                                                size="small"
                                                                style={{ marginLeft: "8px" }}
                                                            >
                                                                <EditIcon style={{ color: "white" }} />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => handleDeleteLabel(label.id)}
                                                                size="small"
                                                                style={{ marginLeft: "8px" }}
                                                            >
                                                                <DeleteIcon style={{ color: "white" }} />
                                                            </IconButton>
                                                        </div>
                                                    ))}
                                                </div>

                                                <Button onClick={handleSetLabelAddOpen}>
                                                    {t("modal.label_add")}
                                                </Button>
                                                <Tooltip title={t("modal.label_random")}>
                                                    <CasinoIcon
                                                        sx={{ color: "white" }}
                                                        onClick={() => getRandomColor()}
                                                    />
                                                </Tooltip>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>{t("calendar.event_cancel")}</Button>
                                                <Button onClick={() => handleSaveLabel()}>{t("core.save")}</Button>
                                            </DialogActions>
                                        </>
                                    )}
                                    {labelAdding && (
                                        <>
                                            <DialogTitle sx={{ display: "Flex", justifyContent: "space-between" }}>
                                                {t("modal.label_add")}{" "}
                                                <Button onClick={handleSetLabelAddClose}>Return</Button>
                                            </DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    label={t("modal.label_title")}
                                                    type="text"
                                                    fullWidth
                                                    variant="standard"
                                                    value={labelTitle}
                                                    onChange={handleLabelTitleChange}
                                                    InputProps={{
                                                        style: {
                                                            fontFamily: "Inter, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: "20px",
                                                            lineHeight: 1.334,
                                                            color: "#C0C0C0",
                                                        },
                                                    }}
                                                />
                                                <TextField
                                                    margin="dense"
                                                    label="Label Description"
                                                    type="text"
                                                    fullWidth
                                                    variant="standard"
                                                    value={labelDescription}
                                                    onChange={(e) => setLabelDescription(e.target.value)}
                                                    InputProps={{
                                                        style: {
                                                            fontFamily: "Inter, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: "16px",
                                                            lineHeight: 1.334,
                                                            color: "#C0C0C0",
                                                        },
                                                    }}
                                                />
                                                <Typography
                                                    style={{
                                                        marginTop: "15px",
                                                        fontFamily: "Inter, sans-serif",
                                                        fontWeight: 400,
                                                        fontSize: "13px",
                                                        lineHeight: 1.334,
                                                        color: "#C0C0C0",
                                                    }}
                                                >
                                                    {t("modal.label_color")}
                                                </Typography>
                                                <input
                                                    type="color"
                                                    value={labelColor !== "rainbow" ? labelColor : "#000000"}
                                                    onChange={(e) => debouncedHandleColorSelection(e.target.value)}
                                                    style={{
                                                        marginTop: "15px",
                                                        marginBottom: "20px",
                                                        width: "100%",
                                                        height: "150px",
                                                        background: labelColor === "rainbow"
                                                            ? "linear-gradient(270deg, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8b00ff)"
                                                            : "none",
                                                        backgroundSize: "400% 400%",
                                                        animation: labelColor === "rainbow" ? "rainbow 4s ease infinite" : "none",
                                                    }}
                                                    disabled={labelColor === "rainbow"} // Disable if it's the rainbow color
                                                />
                                                {labelColor !== "rainbow" && (
                                                    <div
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "repeat(5, 1fr)",
                                                            gap: "5px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {defaultColors.map((color, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "30px",
                                                                    backgroundColor: color,
                                                                    cursor: "pointer",
                                                                    borderRadius: "10px",
                                                                }}
                                                                onClick={() => handleColorSelection(color)}
                                                            ></div>
                                                        ))}
                                                    </div>
                                                )}
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>{t("calendar.event_cancel")}</Button>
                                                <Button
                                                    onClick={() =>
                                                        handleAddNewLabel(labelTitle, labelColor, labelDescription)
                                                    }
                                                >
                                                    {t("modal.label_save")}
                                                </Button>
                                            </DialogActions>
                                        </>
                                    )}
                                    {labelEditing && (
                                        <>
                                            <DialogTitle sx={{ display: "Flex", justifyContent: "space-between" }}>
                                                Editing Label{" "}
                                                <Button onClick={handleSetCancelEdit}>Return</Button>
                                            </DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    label="Label Title"
                                                    type="text"
                                                    fullWidth
                                                    variant="standard"
                                                    value={labelEditingTitle}
                                                    onChange={handleEditLabelTitleChange}
                                                    InputProps={{
                                                        style: {
                                                            fontFamily: "Inter, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: "20px",
                                                            lineHeight: 1.334,
                                                            color: "#C0C0C0",
                                                        },
                                                    }}
                                                />
                                                <TextField
                                                    margin="dense"
                                                    label="Label Description"
                                                    type="text"
                                                    fullWidth
                                                    variant="standard"
                                                    value={labelEditingDescription}
                                                    onChange={(e) => setLabelEditingDescription(e.target.value)} // Handle description change
                                                    InputProps={{
                                                        style: {
                                                            fontFamily: "Inter, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: "16px",
                                                            lineHeight: 1.334,
                                                            color: "#C0C0C0",
                                                        },
                                                    }}
                                                />
                                                <Typography
                                                    style={{
                                                        marginTop: "15px",
                                                        fontFamily: "Inter, sans-serif",
                                                        fontWeight: 400,
                                                        fontSize: "13px",
                                                        lineHeight: 1.334,
                                                        color: "#C0C0C0",
                                                    }}
                                                >
                                                    Color Picker
                                                </Typography>
                                                <input
                                                    type="color"
                                                    value={labelEditingColor !== "rainbow" ? labelEditingColor : "#000000"}
                                                    onChange={(e) => debouncedHandleEditColorSelection(e.target.value)}
                                                    style={{
                                                        marginTop: "15px",
                                                        marginBottom: "20px",
                                                        width: "100%",
                                                        height: "150px",
                                                        background: labelEditingColor === "rainbow"
                                                            ? "linear-gradient(270deg, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8b00ff)"
                                                            : "none",
                                                        backgroundSize: "400% 400%",
                                                        animation: labelEditingColor === "rainbow" ? "rainbow 4s ease infinite" : "none",
                                                    }}
                                                    disabled={labelEditingColor === "rainbow"}
                                                />
                                                {labelEditingColor !== "rainbow" && (
                                                    <div
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateColumns: "repeat(5, 1fr)",
                                                            gap: "5px",
                                                            marginTop: "5px",
                                                        }}
                                                    >
                                                        {defaultColors.map((color, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "30px",
                                                                    backgroundColor: color,
                                                                    cursor: "pointer",
                                                                    borderRadius: "10px",
                                                                }}
                                                                onClick={() => handleEditColorSelection(color)}
                                                            ></div>
                                                        ))}
                                                    </div>
                                                )}
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>Cancel</Button>
                                                <Button
                                                    onClick={() =>
                                                        handleEditLabel(labelEditingTitle, labelEditingColor, labelEditingID, labelEditingDescription)
                                                    }
                                                >
                                                    Save Changes
                                                </Button>
                                            </DialogActions>
                                        </>
                                    )}
                                </Dialog>
                            </Box>
                            <Typography sx={{ marginBottom: "15px" }}>{t("modal.description")}</Typography>

                            <div
                                style={{
                                    minHeight: "200px",
                                    width: "95%",
                                    marginBottom: "15px",
                                    color: "white",
                                }}
                            >
                                <TextEditor
                                    handleDescription={handleDescription}
                                    handleCancel={handleCancel}
                                    value={description}
                                />
                            </div>

                            <ChecklistComponent
                                Task={Task}
                                checklists={checklists}
                                setChecklists={setChecklists}
                                editChecklistTitles={editChecklistTitles}
                                setEditChecklistTitles={setEditChecklistTitles}
                                newChecklistTitles={newChecklistTitles}
                                setNewChecklistTitles={setNewChecklistTitles}
                                setContextTaskMenuPosition={setContextTaskMenuPosition}
                                UpdateChecklistOrder={UpdateChecklistOrder}
                                UpdateChecklistItem={UpdateChecklistItem}
                                UpdateChecklistTitle={UpdateChecklistTitle}
                                DeleteChecklistItem={DeleteChecklistItem}
                                GetChecklists={GetChecklists}
                                DeleteChecklist={DeleteChecklist}
                                CreateChecklistItem={CreateChecklistItem}
                                avoidMisfire={avoidMisfire}
                                setRefreshFlag={setRefreshFlag}
                                refreshFlag={refreshFlag}
                            />

                            <Comments task_id={Task.id} />

                            <Typography sx={{ marginBottom: "10px" }}>
                                {t("modal.activity_log")}
                            </Typography>

                            <ul
                                style={{
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    paddingRight: "10px",
                                }}
                            >
                                {taskActivityLog?.length > 0 ? (
                                    taskActivityLog
                                        .slice(0, showAllActivity ? taskActivityLog.length : 2)
                                        .map((log, index) => {
                                            const getParsedMessage = () => {
                                                const changes = log.changes ? log.changes.split(", ") : [];

                                                if (log.log_message.includes("added member") || log.log_message.includes("removed member")) {
                                                    const addedMember = "added member";
                                                    const removedMember = "removed member";
                                                    let searchTerm;

                                                    if (log.log_message.includes(addedMember)) {
                                                        searchTerm = addedMember;
                                                    } else if (log.log_message.includes(removedMember)) {
                                                        searchTerm = removedMember;
                                                    }

                                                    const index = log.log_message.indexOf(searchTerm) + searchTerm.length;
                                                    if (index > -1) {
                                                        const beforeText = log.log_message.substring(0, index) + " "; // Space added for clarity
                                                        const afterText = log.log_message.substring(index).trim();
                                                        const nameEndIndex = afterText.indexOf(",") === -1 ? afterText.length : afterText.indexOf(",");
                                                        const name = afterText.substring(0, nameEndIndex);
                                                        const restOfMessage = afterText.substring(nameEndIndex);
                                                        return (
                                                            <span>
                                                                {beforeText}
                                                                <a href={`/workflow/profile/${log.referencing}`}>
                                                                    {name}
                                                                </a>
                                                                {restOfMessage}
                                                            </span>
                                                        );
                                                    }
                                                } else if (changes.length > 0 && log.type !== "label") {
                                                    const actionText = log.log_message.substring(log.log_message.indexOf("updated the"));


                                                    const changeDetail = changes.length > 0 && changes[0].split(" changed from ");
                                                    const [field, values] = changeDetail || ["", ""];
                                                    const [oldVal, newVal] = values ? values.split(" to ") : ["", ""];

                                                    const tooltipContent = (
                                                        <div>
                                                            <span>Old: {oldVal?.trim()}</span>
                                                            <br />
                                                            <span>New: {newVal?.trim()}</span>
                                                        </div>
                                                    );

                                                    return (
                                                        <>
                                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                                {log.log_message.replace(actionText, "").trim()}
                                                                {'\u00A0'}
                                                                <Tooltip title={tooltipContent} placement="top" arrow>
                                                                    <Typography
                                                                        sx={{ color: "#5e72e4", cursor: "pointer", '&:hover': { color: "#233DD2" } }}
                                                                    >
                                                                        {actionText}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </div>
                                                        </>
                                                    );



                                                } else {
                                                    return log.log_message;  // For cases where no conditions are met
                                                }
                                            };







                                            return (
                                                <li
                                                    key={index}
                                                    style={{
                                                        color: "white",
                                                        marginBottom: "10px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Avatar
                                                        src={log.user_image}
                                                        alt={log.user?.username}
                                                        sx={{ marginRight: "10px" }}
                                                    />
                                                    <div>
                                                        <Typography>{getParsedMessage()}</Typography>

                                                        <Typography
                                                            sx={{ color: "#67b5d9" }}
                                                            fontStyle={"italic"}
                                                            variant="subtitle2"
                                                        >
                                                            {formatDateToString(
                                                                convertToUTC(
                                                                    log.created_at
                                                                )
                                                            )}
                                                        </Typography>
                                                    </div>
                                                </li>
                                            );
                                        })
                                ) : (
                                    <li
                                        key="no-activity"
                                        style={{ color: "white", marginBottom: "10px" }}
                                    >
                                        <Typography variant="body2" color="textSecondary">
                                            <span style={{ color: "white" }}>
                                                {t("modal.no_activity")}
                                            </span>
                                        </Typography>
                                    </li>
                                )}
                            </ul>

                            {taskActivityLog?.length > 2 && (
                                <Button
                                    onClick={() => setShowAllActivity(!showAllActivity)}
                                    sx={{ color: "#67b5d9", marginTop: "10px" }}
                                >
                                    {showAllActivity ? t("modal.show_less_activity") : t("modal.show_all_activity")}
                                </Button>
                            )}



                        </Grid>
                        <Grid item xs={3} sx={{ borderLeft: "2px solid #575054" }}>
                            {" "}
                            {/* Actions box takes 20% of the width */}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignContent: "center",
                                    alignItems: "center",
                                    marginBottom: "15px",
                                }}
                            >
                                <Typography sx={{ marginBottom: "8px", color: "#ffffff" }}>
                                    {t("modal.actions")}
                                </Typography>

                                <Button
                                    onClick={() => setCoverModalOpen(true)}
                                    sx={{
                                        borderRadius: "15px",
                                        bgcolor: "#575054",
                                        width: "100%",
                                        color: "#ffffff",
                                        "&:hover": { bgcolor: "#463e3c" },
                                        marginBottom: "15px",
                                    }}
                                >
                                    Change Cover
                                </Button>

                                <Button
                                    onClick={() => handleTaskMembersDialogOpen()}
                                    sx={{
                                        borderRadius: "15px",
                                        bgcolor: "#575054",
                                        width: "100%",
                                        color: "#ffffff",
                                        "&:hover": { bgcolor: "#463e3c" },
                                        marginBottom: "15px",
                                    }}
                                >
                                    {t("modal.members")}
                                </Button>

                                <Dialog
                                    open={taskMembersDialogOpen}
                                    onClose={handleTaskMembersDialogClose}
                                    sx={{
                                        "& .MuiPaper-root": {
                                            background: "#252224",
                                            display: "flex",
                                            textAlign: "-webkit-center",
                                        },
                                    }}
                                >
                                    <DialogTitle sx={{ width: "400px" }}>
                                        {t("modal.select_members")}
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography variant="subtitle2">{t("project_workers")}</Typography>
                                        <TextField
                                            placeholder={t("search_workers")}
                                            onChange={handleSearchChange}
                                            variant="outlined"
                                            sx={{
                                                marginBottom: "16px",
                                                backgroundColor: "#fff",
                                                borderRadius: "5px",
                                            }}
                                            fullWidth
                                        />
                                        <div>
                                            {filteredProjectWorkers?.map((worker) => (
                                                <div
                                                    key={worker.id}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginBottom: "8px",
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={selectedTaskMembers?.some(
                                                            (member) => member.id === worker.id
                                                        )}
                                                        onChange={() => toggleMemberSelection(worker)}
                                                        color="primary"
                                                    />
                                                    <div
                                                        onClick={() => toggleMemberSelection(worker)}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "0 10px",
                                                            cursor: "pointer",
                                                            border: selectedTaskMembers?.some(
                                                                (member) => member.id === worker.id
                                                            )
                                                                ? "2px solid white"
                                                                : "none",
                                                            borderRadius: "5px",
                                                            backgroundColor: selectedTaskMembers?.some(
                                                                (member) => member.id === worker.id
                                                            )
                                                                ? "#444"
                                                                : "transparent",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Avatar
                                                            alt={worker.username}
                                                            src={
                                                                process.env.REACT_APP_BACKEND_URL + worker.image
                                                            }
                                                        />
                                                        <Typography
                                                            variant="body2"
                                                            style={{ color: "#fff", marginLeft: "15px" }}
                                                        >
                                                            {worker.full_name}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleTaskMembersDialogClose}>
                                            {t("calendar.event_cancel")}
                                        </Button>
                                        <Button onClick={() => handleSaveMembers()}>
                                            {t("core.save")}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Button
                                    onClick={handleAddChecklist}
                                    sx={{
                                        borderRadius: "15px",
                                        bgcolor: "#575054",
                                        width: "100%",
                                        color: "#ffffff",
                                        "&:hover": { bgcolor: "#463e3c" },
                                        marginBottom: "15px",
                                    }}
                                >
                                    {t("modal.checklist_add")}
                                </Button>

                                <Button
                                    onClick={() => handleTaskDatesDialogOpen()}
                                    sx={{
                                        borderRadius: "15px",
                                        bgcolor: "#575054",
                                        width: "100%",
                                        color: "#ffffff",
                                        "&:hover": { bgcolor: "#463e3c" },
                                        marginBottom: "15px",
                                    }}
                                >
                                    {t("core.dates")}
                                </Button>

                                <TaskDatesDialog
                                    open={taskDatesDialogOpen}
                                    onClose={handleTaskDatesDialogClose}
                                    task={Task}
                                    handleSaveDates={handleSaveDates}
                                />

                                {List.title !== "Doing" && (
                                    <Button
                                        onClick={() => handleMarkAsDoing(Task.id)}
                                        sx={{
                                            borderRadius: "15px",
                                            bgcolor: "#575054",
                                            width: "100%",
                                            color: "#ffffff",
                                            "&:hover": { bgcolor: "#463e3c" },
                                            marginBottom: "15px",
                                        }}
                                    >
                                        {t("project_page.mark_doing")}
                                    </Button>
                                )}
                                {List.title !== "Done" && (
                                    <Button
                                        onClick={() => handleMarkAsDone(Task.id)}
                                        sx={{
                                            borderRadius: "15px",
                                            bgcolor: "#575054",
                                            width: "100%",
                                            color: "#ffffff",
                                            "&:hover": { bgcolor: "#463e3c" },
                                            marginBottom: "15px",
                                        }}
                                    >
                                        {t("project_page.mark_done")}
                                    </Button>
                                )}
                                <Button
                                    onClick={() => handleArchiveTaskModalOpen()}
                                    sx={{
                                        borderRadius: "15px",
                                        bgcolor: "#575054",
                                        width: "100%",
                                        color: "#ffffff",
                                        "&:hover": { bgcolor: "#463e3c" },
                                        marginBottom: "15px",
                                    }}
                                >
                                    {t("modal.archive")}
                                </Button>


                                <Typography sx={{ marginBottom: "8px", color: "#ffffff" }}>
                                    Attachments
                                </Typography>

                                {/* Transparent button to upload new attachments */}
                                <Box>
                                    <input
                                        accept="*"
                                        id="upload-button-file"
                                        multiple
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="upload-button-file">
                                        <Button
                                            variant="text"
                                            component="span"
                                            sx={{
                                                color: '#a3989e',
                                                backgroundColor: 'transparent',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                },
                                                display: 'flex',
                                                alignItems: 'center',
                                                textTransform: 'none',
                                            }}
                                        >
                                            <UploadFileIcon sx={{ marginRight: '8px', color: '#ffffff' }} />
                                            Upload Attachments
                                        </Button>
                                    </label>
                                </Box>

                                {/* List existing attachments */}
                                <Box sx={{ marginTop: '16px', maxWidth: '230px' }}>
                                    {Task.attachments && Task.attachments.length > 0 && (
                                        Task.attachments.map((attachment, index) => {
                                            const fileUrl = `${process.env.REACT_APP_BACKEND_URL}${attachment.file}`;

                                            let AttachmentIcon;
                                            if (attachment.file_type === 'Image') {
                                                AttachmentIcon = <ImageIcon sx={{ marginRight: '8px', color: '#ffffff' }} />;
                                            } else if (attachment.file_type === 'Document') {
                                                AttachmentIcon = <InsertDriveFileIcon sx={{ marginRight: '8px', color: '#ffffff' }} />;
                                            } else if (attachment.file_type === 'Archive') {
                                                AttachmentIcon = <ArchiveIcon sx={{ marginRight: '8px', color: '#ffffff' }} />;
                                            } else {
                                                AttachmentIcon = <InsertDriveFileIcon sx={{ marginRight: '8px', color: '#ffffff' }} />;
                                            }

                                            const handlePreviewImage = () => {
                                                if (attachment.file_type === 'Image') {
                                                    handleOpenImageModal(fileUrl);
                                                } else {
                                                    window.open(fileUrl, '_blank');
                                                }
                                            };

                                            return (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginBottom: '8px',
                                                        color: '#ffffff',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                        padding: '8px',
                                                        borderRadius: '8px',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                                        },
                                                    }}
                                                    onClick={handlePreviewImage}
                                                >
                                                    {AttachmentIcon}
                                                    <Box sx={{ flex: 1 }}>
                                                        <Typography style={{ wordBreak: "break-all" }}>{attachment.filename}</Typography>
                                                        <Typography variant="body2" color="rgba(255, 255, 255, 0.6)">
                                                            {attachment.file_type}
                                                        </Typography>
                                                    </Box>
                                                    <IconButton
                                                        sx={{ marginLeft: '8px', color: 'red' }}
                                                        onClick={(event) => handleDeleteAttachment(attachment, event)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            );
                                        })
                                    )}

                                    {/* Image preview modal */}
                                    <Dialog open={openImagePreviewModal} onClose={handleCloseImageModal} >
                                        <DialogContent sx={{ padding: 0, position: 'relative' }}>
                                            <IconButton
                                                onClick={handleCloseImageModal}
                                                sx={{ position: 'absolute', top: 8, right: 8, zIndex: 2, color: 'white' }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            {selectedPreviewImage && (
                                                <img
                                                    src={selectedPreviewImage}
                                                    alt="Preview"
                                                    style={{ width: 'auto', height: 'auto', maxHeight: '90vh' }}
                                                />
                                            )}
                                        </DialogContent>
                                    </Dialog>

                                    {/* Delete confirmation dialog */}
                                    <Dialog
                                        open={openDeleteDialog}
                                        onClose={handleCloseDeleteDialog}
                                        sx={{
                                            "& .MuiPaper-root": {
                                                backgroundColor: '#252225', // Main background color
                                                color: '#a3989e', // Text color for the entire dialog
                                            },
                                        }}
                                    >
                                        <DialogTitle sx={{ color: '#a3989e' }}>Delete Attachment</DialogTitle>
                                        <DialogContent>
                                            <Typography>
                                                Do you want to delete the attachment "{selectedAttachment?.filename}" from both the task and the
                                                board's storage?
                                            </Typography>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={handleCloseDeleteDialog}
                                                sx={{
                                                    backgroundColor: '#3d393b',
                                                    color: '#a3989e',
                                                    '&:hover': {
                                                        backgroundColor: '#a3989e',
                                                        color: '#252225',
                                                    },
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => handleConfirmDelete(false)}
                                                sx={{
                                                    backgroundColor: '#3d393b',
                                                    color: '#a3989e',
                                                    '&:hover': {
                                                        backgroundColor: '#a3989e',
                                                        color: '#252225',
                                                    },
                                                }}
                                            >
                                                Delete from Task Only
                                            </Button>
                                            <Button
                                                onClick={() => handleConfirmDelete(true)}
                                                sx={{
                                                    backgroundColor: '#3d393b',
                                                    color: 'red', // Red for the delete button
                                                    '&:hover': {
                                                        backgroundColor: 'red',
                                                        color: '#252225',
                                                    },
                                                }}
                                            >
                                                Delete from Task and Board
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>

                                <CoverPickerModal
                                    open={openCover}
                                    setCoverModalOpen={setCoverModalOpen}
                                    saveNewCoverImage={saveNewCoverImage}
                                    cover_color={Task.cover_color}
                                    cover_image={Task.cover_image}
                                />

                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}
