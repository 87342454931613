import React, { useState } from 'react';
import { Popover, Box, Avatar, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserActivityLogModal from './UserActivityLogModal';
import {useTranslation} from "react-i18next"; // Ensure this import is correct

const UserNavbarPopover = ({ anchorEl, handleClose, user, myLoggedUser }) => {
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const id = open ? 'user-navbar-popover' : undefined;
  const navigate = useNavigate();
  const [isActivityLogOpen, setIsActivityLogOpen] = useState(false);

  const handleViewProfile = () => {
    navigate(`/workflow/profile/${user.id}`);
    handleClose();
  };

  const handleViewUserBoardActivity = () => {
    setIsActivityLogOpen(true);
  };

  const handleModalClose = () => {
    setIsActivityLogOpen(false);
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            bgcolor: '#2D2D2D', // Dark Grey background
            color: 'white', // Text color
          },
        }}
      >
        <Box
          sx={{
            width: 305,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <Avatar alt={user.full_name} src={process.env.REACT_APP_BACKEND_URL + user.image} sx={{ mr: 2 }} />
            <Box>
              <Typography variant="h6" component="h2" sx={{ color: 'white' }}>
                {user.full_name}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ color: '#A0A0A0' }}>
                @{user.username}
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            fullWidth
            sx={{ mb: 1, bgcolor: '#3A3A3A', color: 'white' }}
            onClick={handleViewProfile}
          >
              {t("core.view_profile")}
          </Button>
          {(myLoggedUser.is_admin || myLoggedUser.is_owner) && (
            <Button
              variant="contained"
              fullWidth
              sx={{ bgcolor: '#3A3A3A', color: 'white' }}
              onClick={handleViewUserBoardActivity}
            >
                {t("core.view_activity")}
            </Button>
          )}
        </Box>
      </Popover>

      {/* The modal that shows the user's activity log */}
      <UserActivityLogModal
        open={isActivityLogOpen}
        handleClose={handleModalClose}
        userId={user.id}
      />
    </>
  );
};

export default UserNavbarPopover;
