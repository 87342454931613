import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useAuthorization } from 'contexts/AuthContextProvider.jsx';
import { useService } from 'contexts/ServiceContextProvider';
import { useWorkspaces } from 'contexts/WorkspacesContextProvider';
import { Divider, TextField } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useTranslation, Trans} from "react-i18next";

const WorkspaceMembers = () => {
    const { t } = useTranslation();

    const { RemoveUserFromWorkspace, getWorkspaceWorkers, promoteWorkspaceWorkers, demoteWorkspaceWorkers, getLoggedUserPermissions } = useService();

    const workspaceContext = useWorkspaces();
    const myWorkspaces = workspaceContext.workspaces;
    const [userRows, setUserRows] = useState([]);
    const [adminRows, setAdminRows] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [adminSelected, setAdminSelected] = useState([]);
    const [userPage, setUserPage] = useState(0);
    const [userRowsPerPage, setUserRowsPerPage] = useState(5);
    const [adminPage, setAdminPage] = useState(0);
    const [adminRowsPerPage, setAdminRowsPerPage] = useState(5);
    const [workspaceOwner, setWorkspaceOwner] = useState();
    const [userSearch, setUserSearch] = useState('');
    const [adminSearch, setAdminSearch] = useState('');
    const [myUserPerms, setMyUserPerms] = useState(null);

    const { getLoggedUser } = useAuthorization();
    const loggedUserID = getLoggedUser();

    useEffect(() => {
        async function fetchData() {
            if (myWorkspaces[0]) {
                const userPerms = await getLoggedUserPermissions(myWorkspaces[0].id, loggedUserID);
      
                setMyUserPerms(userPerms);
            }
        }

        fetchData();
    }, [myWorkspaces, loggedUserID, getLoggedUserPermissions]);

    function createData(id, name, email) {
        return {
            id,
            name,
            email,
        };
    }

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleAdminSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = adminRows.map((n) => n.id);
            setAdminSelected(newSelected);
            return;
        }
        setAdminSelected([]);
    };

    const handleAdminClick = (event, id) => {
        const selectedIndex = adminSelected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(adminSelected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(adminSelected.slice(1));
        } else if (selectedIndex === adminSelected.length - 1) {
            newSelected = newSelected.concat(adminSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                adminSelected.slice(0, selectedIndex),
                adminSelected.slice(selectedIndex + 1),
            );
        }
        setAdminSelected(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = userRows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleUserChangePage = (event, newPage) => {
        setUserPage(newPage);
    };

    const handleUserChangeRowsPerPage = (event) => {
        setUserRowsPerPage(parseInt(event.target.value, 10));
        setUserPage(0);
    };

    const handleAdminChangePage = (event, newPage) => {
        setAdminPage(newPage);
    };

    const handleAdminChangeRowsPerPage = (event) => {
        setAdminRowsPerPage(parseInt(event.target.value, 10));
        setAdminPage(0);
    };

    const isUserSelected = (id) => selected.indexOf(id) !== -1;
    const isAdminSelected = (id) => adminSelected.indexOf(id) !== -1;

    const emptyUserRows =
        userPage > 0 ? Math.max(0, (1 + userPage) * userRowsPerPage - userRows.length) : 0;

    const filteredRows = userRows.filter(
        (row) =>
            (row.name && row.name.toLowerCase().includes(userSearch.toLowerCase())) ||
            (row.email && row.email.toLowerCase().includes(userSearch.toLowerCase()))
    );

    const filteredAdminRows = adminRows.filter(
        (row) =>
            (row.name && row.name.toLowerCase().includes(adminSearch.toLowerCase())) ||
            (row.email && row.email.toLowerCase().includes(adminSearch.toLowerCase()))
    );


    const emptyAdminRows =
        adminPage > 0 ? Math.max(0, (1 + adminPage) * adminRowsPerPage - adminRows.length) : 0;

  
    useEffect(() => {
        if (myWorkspaces[0]) {
            getWorkspaceWorkers(myWorkspaces[0].id).then(workspaceWorkers => {
                const filteredWorkers = workspaceWorkers.filter(worker => !worker.is_owner && !worker.is_admin);

                setUserRows(filteredWorkers.map(worker => createData(worker.id, worker.full_name, worker.email)));
                const adminWorkers = workspaceWorkers.filter(worker => worker.is_admin && !worker.is_owner);
                setAdminRows(adminWorkers.map(worker => createData(worker.id, worker.full_name, worker.email)));
                const owner = workspaceWorkers.filter(worker => worker.is_owner);
                setWorkspaceOwner(owner);
            }).catch(error => {
                console.error('Error fetching workers:', error);
            });
        }
    }, [myWorkspaces, getWorkspaceWorkers]);

    const handlePromoteClick = () => {
        // Filter out the selected users from the userRows
        const newUsers = userRows.filter((user) => !selected.includes(user.id));
        // Find the selected users
        const promotedUsers = userRows.filter((user) => selected.includes(user.id));
        // Add the selected users to the adminRows
        const newAdmins = [...adminRows, ...promotedUsers];

        // Update the state
        setUserRows(newUsers);
        setAdminRows(newAdmins);
        setSelected([]);

        promotedUsers.map((user) =>
            promoteWorkspaceWorkers(myWorkspaces[0].id, user.id)
        );
    };

    const handleDemoteClick = () => {
        // Filter out the selected admins from the adminRows
        const newAdmins = adminRows.filter((admin) => !adminSelected.includes(admin.id));
        // Find the selected admins
        const demotedAdmins = adminRows.filter((admin) => adminSelected.includes(admin.id));
        // Add the selected admins to the userRows
        const newUsers = [...userRows, ...demotedAdmins];
        // Update the state
        setAdminRows(newAdmins);
        setUserRows(newUsers);
        setAdminSelected([]);

        demotedAdmins.map((user) =>
            demoteWorkspaceWorkers(myWorkspaces[0].id, user.id)
        );
    };

    const handleRemoveClick = async () => {
        const remainingUsers = userRows.filter((user) => !selected.includes(user.id));

        const usersToRemove = userRows.filter((user) => selected.includes(user.id));

        setUserRows(remainingUsers);
        setSelected([]);

        try {
            for (const user of usersToRemove) {
                const response = await RemoveUserFromWorkspace(myWorkspaces[0].id, user.id);
                if (response.status === 200) {
                } else {
                    console.error(`Failed to remove user: ${user.name}`);
                }
            }
            //snackbar here for when they are removed successfully
        } catch (error) {
            console.error("Error removing workers:", error);
            //snackbar here for when they have an error removing them
        }

    };



    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#252224',
            color: '#FFF',
            maxWidth: 400,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const getNamesByIds = (ids, type) => {
        return ids.map(id => {
            if (type === "workers") {
                const user = userRows.find(user => user.id === id);
                return user ? user.name : 'NULL ERROR';
            } else if (type === "admins") {
                const user = adminRows.find(user => user.id === id);
                return user ? user.name : 'NULL ERROR';
            }
        }).join(', ');
    };



    return (
        <>
            {workspaceOwner && (
                <Box className="workspace-title-hub" sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Box className="workspace-title-img">
                        <img src={process.env.REACT_APP_BACKEND_URL + workspaceOwner[0].image} alt="Workspace Owner" style={{ width: 'auto', maxHeight: '100px' }} />
                    </Box>
                    <Box className="workspace-title" sx={{ textAlign: "center" }}>
                        <Typography variant="h3" style={{ color: "#C0C0C0", marginLeft: "10px" }}>
                            {workspaceOwner[0].full_name}
                        </Typography>
                        <Box className="workspace-website">
                            <Typography variant="subtitle" style={{ color: "#C0C0C0", fontWeight: "lighter", marginLeft: "10px" }}>
                                {workspaceOwner[0].email}
                            </Typography>
                        </Box>
                        <Box className="workspace-website">
                            <Typography variant="subtitle" style={{ color: "#C0C0C0", fontWeight: "lighter", marginLeft: "10px" }}>
                                {t('workspace_members.workspace_owner', { name: myWorkspaces[0].name })}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            <Divider sx={{ backgroundColor: "#A3989E", my: "50px" }} />

            <Box className="AdminTable" sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Box sx={{ width: "90%" }}>
                    <TextField
                        label={t('workspace_members.admin_search')}
                        variant="outlined"
                        fullWidth
                        value={adminSearch}
                        onChange={(e) => setAdminSearch(e.target.value)}
                        sx={{ mb: 2 }}
                        InputProps={{ style: { color: 'white' } }}
                    />
                    <Paper sx={{ mb: 2 }}>
                        <TableContainer>
                            <Table sx={{ minWidth: 550 }} aria-labelledby="adminTableTitle" size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3} style={{ backgroundColor: "#f5f5f9" }}>
                                            <Typography variant="h6" style={{ color: "#000000" }}>
                                                {t('workspace_members.admins')}
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">
                                                                <Trans i18nKey="workspace_members.admin_settings_workspaces">
                                                                    Admin Settings Within <b>Workspaces</b>:
                                                                </Trans>
                                                            </Typography>
                                                            <ul>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.admin_workspace_settings_1">
                                                                        Can Create/Read/Update/Delete Projects.
                                                                    </Trans>
                                                                </li>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.admin_workspace_settings_2">
                                                                        Access Workspace Settings.
                                                                    </Trans>
                                                                </li>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.admin_workspace_settings_3">
                                                                        Can view users and admins. <italic>(Cannot promote or demote.)</italic>
                                                                    </Trans>
                                                                </li>
                                                            </ul>
                                                            <Typography color="inherit">
                                                                <Trans i18nKey="workspace_members.admin_settings_projects">
                                                                    Admin Settings Within <b>Projects</b>:
                                                                </Trans>
                                                            </Typography>
                                                            <ul>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.admin_project_settings_1">
                                                                        Has full access to lists and tasks.
                                                                    </Trans>
                                                                </li>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.admin_project_settings_2">
                                                                        Can add users to projects.
                                                                    </Trans>
                                                                </li>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.admin_project_settings_3">
                                                                        Has full access to Project Details.
                                                                    </Trans>
                                                                </li>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.admin_project_settings_4">
                                                                        Can remove users from projects.
                                                                    </Trans>
                                                                </li>
                                                            </ul>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <InfoIcon fontSize="small" sx={{ color: "black", marginLeft: "10px" }} />
                                                </HtmlTooltip>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={adminSelected.length > 0 && adminSelected.length < filteredAdminRows.length}
                                                checked={filteredAdminRows.length > 0 && adminSelected.length === filteredAdminRows.length}
                                                onChange={handleAdminSelectAllClick}
                                                inputProps={{
                                                    'aria-label': 'select all admin',
                                                }}
                                            />
                                        </TableCell>
                                        {headCells.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align="left"
                                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={() => handleRequestSort(headCell.id)}
                                                >
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredAdminRows
                                        .slice(adminPage * adminRowsPerPage, adminPage * adminRowsPerPage + adminRowsPerPage)
                                        .map((row) => {
                                            const isItemSelected = isAdminSelected(row.id);
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    onClick={(event) => handleAdminClick(event, row.id)}
                                                    selected={isItemSelected}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox color="primary" checked={isItemSelected} />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell>{row.email}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyAdminRows > 0 && (
                                        <TableRow style={{ height: 33 * emptyAdminRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredAdminRows.length}
                            rowsPerPage={adminRowsPerPage}
                            page={adminPage}
                            onPageChange={handleAdminChangePage}
                            onRowsPerPageChange={handleAdminChangeRowsPerPage}
                            sx={{
                                '& .MuiTablePagination-selectLabel': {
                                    alignSelf: 'flex-end',
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    alignSelf: 'flex-end',
                                },
                            }}
                        />
                    </Paper>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center", my: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", my: 2 }}>

                    {(selected.length > 0 && (myUserPerms?.is_owner || myUserPerms?.is_admin)) && (
                        <Tooltip title={`Promote: ${getNamesByIds(selected, "workers")}`} placement="top">
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<ArrowUpwardIcon />}
                                    onClick={handlePromoteClick}
                                    style={{ width: 177 }}
                                >
                                    {t('workspace_members.promote')}
                                </Button>
                            </span>
                        </Tooltip>
                    )}

                    {(selected.length === 0 || (!myUserPerms?.is_owner && !myUserPerms?.is_admin)) && (
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<ArrowUpwardIcon />}
                                onClick={handlePromoteClick}
                                style={{ width: 177 }}
                                disabled={true}
                            >
                                {t('workspace_members.promote')}
                            </Button>
                        </span>
                    )}

                    {adminSelected.length > 0 && myUserPerms?.is_owner && (
                        <Tooltip title={`Demote: ${getNamesByIds(adminSelected, "admins")}`} placement="top">
                            <span>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<ArrowDownwardIcon />}
                                    onClick={handleDemoteClick}
                                    style={{ width: 177 }}
                                >
                                    {t('workspace_members.demote')}
                                </Button>
                            </span>
                        </Tooltip>
                    )}

                    {(adminSelected.length === 0 || (!myUserPerms?.is_admin && !myUserPerms?.is_owner) || (myUserPerms?.is_admin)) && (
                        <span>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<ArrowDownwardIcon />}
                                onClick={handleDemoteClick}
                                style={{ width: 177 }}
                                disabled={true}
                            >
                                {t('workspace_members.demote')}
                            </Button>
                        </span>
                    )}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", my: 2, ml: 5 }}>
                    <Tooltip title="Remove Selected users from Workspace" placement="top">
                        <span>
                            <Button
                                variant="contained"
                                color="error"
                                startIcon={<CloseIcon />}
                                onClick={handleRemoveClick}
                                style={{ width: 177, height: 72 }}
                                disabled={selected.length === 0 || !(myUserPerms?.is_owner || myUserPerms?.is_admin)}
                            >
                                Remove From Workspace
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
            </Box>

            <Box className="WorkersTable" sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Box sx={{ width: "90%" }}>
                    <TextField
                        label={t('workspace_members.user_search')}
                        variant="outlined"
                        fullWidth
                        value={userSearch}
                        onChange={(e) => setUserSearch(e.target.value)}
                        sx={{ mb: 2 }}
                        InputProps={{ style: { color: 'white' } }}
                    />
                    <Paper sx={{ mb: 2 }}>
                        <TableContainer>
                            <Table sx={{ minWidth: 550 }} aria-labelledby="workersTableTitle" size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3} style={{ backgroundColor: "#f5f5f9" }}>
                                            <Typography variant="h6" style={{ color: "#000000" }}>
                                                Workspace Users
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography color="inherit">
                                                                <Trans i18nKey="workspace_members.user_settings_workspaces">
                                                                    User Settings Within <b>Workspaces</b>:
                                                                </Trans>
                                                            </Typography>
                                                            <ul>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.user_workspace_settings_1">
                                                                        Can see Public Projects.
                                                                    </Trans>
                                                                </li>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.user_workspace_settings_2">
                                                                        Invite new workers via email.
                                                                    </Trans>
                                                                </li>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.user_workspace_settings_3">
                                                                        Create New Projects.
                                                                    </Trans>
                                                                </li>
                                                            </ul>
                                                            <Typography color="inherit">
                                                                <Trans i18nKey="workspace_members.user_settings_projects">
                                                                    User Settings Within <b>Projects</b>:
                                                                </Trans>
                                                            </Typography>
                                                            <ul>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.user_project_settings_1">
                                                                        Has full access to lists and tasks.
                                                                    </Trans>
                                                                </li>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.user_project_settings_2">
                                                                        Has limited access to Project Details.
                                                                    </Trans>
                                                                </li>
                                                                <li>
                                                                    <Trans i18nKey="workspace_members.user_project_settings_3">
                                                                        Can add users to projects.
                                                                    </Trans>
                                                                </li>
                                                            </ul>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <InfoIcon fontSize="small" sx={{ color: "black", marginLeft: "10px" }} />
                                                </HtmlTooltip>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={selected.length > 0 && selected.length < filteredRows.length}
                                                checked={filteredRows.length > 0 && selected.length === filteredRows.length}
                                                onChange={handleSelectAllClick}
                                                inputProps={{
                                                    'aria-label': 'select all workers',
                                                }}
                                            />
                                        </TableCell>
                                        {headCells.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align="left"
                                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={() => handleRequestSort(headCell.id)}
                                                >
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredRows
                                        .slice(userPage * userRowsPerPage, userPage * userRowsPerPage + userRowsPerPage)
                                        .map((row) => {
                                            const isItemSelected = isUserSelected(row.id);
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    selected={isItemSelected}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox color="primary" checked={isItemSelected} />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell>{row.email}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyUserRows > 0 && (
                                        <TableRow style={{ height: 33 * emptyUserRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredRows.length}
                            rowsPerPage={userRowsPerPage}
                            page={userPage}
                            onPageChange={handleUserChangePage}
                            onRowsPerPageChange={handleUserChangeRowsPerPage}
                            sx={{
                                '& .MuiTablePagination-selectLabel': {
                                    alignSelf: 'flex-end',
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    alignSelf: 'flex-end',
                                },
                            }}
                        />
                    </Paper>
                </Box>
            </Box>
        </>
    );
};

export default WorkspaceMembers;
