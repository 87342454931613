import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Bar, Line } from 'react-chartjs-2';

const ProjectDashboard = () => {
  // Sample data for the graphs
  

  return (
    <>
      shoo go away  this is not done
    </>
  );
};

export default ProjectDashboard;
