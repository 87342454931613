import React, { useState } from 'react';
import { AvatarGroup, Avatar } from '@mui/material';
import UserNavbarPopover from 'components/Core/UserNavbarPopover';
import UserOnlineStatus from 'components/Modals/UserOnlineChecker';

const CommonAvatarGroup = ({ users, handleAvatarGroupClick, myLoggedUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleAvatarClick = (event, user) => {
    if (user) {
      setAnchorEl(event.currentTarget);
      setSelectedUser(user);
    }
  };

  const handleMoreClick = (event) => {
    event.stopPropagation();
    handleAvatarGroupClick(event);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  return (
    <>
      <AvatarGroup
        max={5}
        sx={{
          '& .MuiAvatar-root': {
            cursor: 'pointer',
            border: '2px solid #000000',
            '&:hover': {
              borderColor: '#FFD700',
            },
          },
        }}
      >
        {users.slice(0, 4).map((user) => (
          <div key={user.id} style={{ display: 'flex', alignItems: 'center', marginRight: '-10px' }}>
            <UserOnlineStatus
              lastOnline={user.last_online}
              workingHours={user.working_hours}
              userTimezone={user.timezone}
              userTimezoneOffset={user.timezone_offset}
              showBadgeOnly={true}
              loggedInUserTimezone = {myLoggedUser.timezone}
              loggedInUserTimezoneOffset = {myLoggedUser.timezone_offset}
            >
              <Avatar
                alt={user.full_name}
                src={process.env.REACT_APP_BACKEND_URL + user.image}
                onClick={(event) => handleAvatarClick(event, user)}
              />
            </UserOnlineStatus>
          </div>
        ))}
        {users.length > 4 && (
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '-10px' }}>
            <Avatar
              onClick={handleMoreClick}
              sx={{
                cursor: 'pointer',
                border: '2px solid #000000',
                '&:hover': {
                  borderColor: '#FFD700',
                },
              }}
            >
              +{users.length - 4}
            </Avatar>
          </div>
        )}
      </AvatarGroup>
      {selectedUser && (
        <UserNavbarPopover
          anchorEl={anchorEl}
          handleClose={handleClosePopover}
          user={selectedUser}
          myLoggedUser={myLoggedUser}
        />
      )}
    </>
  );
};

export default CommonAvatarGroup;