import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import {useTranslation} from "react-i18next";

export default function NewResourceModal({ openResourceModal, handleResourceModalClose, handleCreateResource, avoidMisfire }) {
    const { t } = useTranslation();

    const [title, setTitle] = useState("");
    const [hyperlink, setHyperlink] = useState("");



    const handleSaveSecret = () => {
        if (title.trim() === "" || hyperlink.trim() === "") {
            // If Title or Secret is empty, prevent saving
            return;
        }
        // Call handleCreateSecret function if Title and Secret are not empty
        handleCreateResource(title, hyperlink);
    };


    return (
        <Dialog open={openResourceModal} onClose={handleResourceModalClose} sx={{
            '& .MuiPaper-root': {
                background: '#252224'
            }
        }}>
            <DialogTitle>{t("modal.resource_title")}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label={t("calendar.event_title")}
                    type="text"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    InputLabelProps={{
                        style: { color: '#FFF' }
                    }}
                    InputProps={{
                        style: { color: 'white' }
                    }}
                />
            </DialogContent>
            <DialogTitle>{t("modal.resource_hyperlink")}</DialogTitle>
            <DialogContent>
                <TextField                    
                    margin="dense"
                    id="title"
                    label={t("calendar.event_title")}
                    type="text"
                    fullWidth
                    value={hyperlink}
                    onChange={(e) => setHyperlink(e.target.value)}
                    InputLabelProps={{
                        style: { color: '#FFF' }
                    }}
                    InputProps={{
                        style: { color: 'white' }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleResourceModalClose} color="primary">
                    {t("calendar.event_cancel")}
                </Button>
                <Button onClick={handleSaveSecret} disabled={avoidMisfire || title.trim() === "" || hyperlink.trim() === ""} color="primary">
                    {t("modal.resource_save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}